import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiCommunicationService } from '../api-communication.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: any;
  password: any;
  user_api: any;
  api_token: any;

  constructor(public api: ApiCommunicationService, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.email = '';
    this.password = '';
    this.api_token = localStorage.getItem('api_token');
    if(this.api_token != null){
      this.router.navigate(['/orders']);
    }
    document.body.classList.remove('bg-img');
  }

  onKeydown(event) {
    if (event.keyCode === 13 || event.keyCode === 'Enter') {
      this.login();
    }
  }

  login(){
    // this.presentLoading();
    if(this.email == null || this.password == null || this.email == '' || this.password == ''){
      this.showError('Prosimy uzupełnić wszystkie pola.');
      // this.loader.dismiss();
    }else{
      this.user_api = this.api.loginUser(this.email, this.password);
      this.user_api.subscribe(data => {
        // this.loader.dismiss();
        console.log(data);
        if(data.status == false){
          this.showError(data.message);
        }else if(data.status == true){
          if(data.data.perm == 0 || data.data.perm == 1 || data.data.perm == 2 || data.data.perm == 3){
            localStorage.setItem('api_token', data.data.api_token);
            localStorage.setItem('user_email', data.data.email);
            localStorage.setItem('user_name', data.data.name);
            localStorage.setItem('user_surname', data.data.surname);
            localStorage.setItem('user_perm', data.data.perm);
            localStorage.setItem('avatar', this.api.api_url+"../storage/app/avatars/"+data.data.avatar);
            if(data.data.perm == 3){
              this.router.navigate(['/warehouse']);
            }else{
              this.router.navigate(['/orders']);
            }
          }else{
            this.showError('To konto nie posiada uprawnień do logowania w tym systemie.');
          }
        }
      }, err => {
        // this.loader.dismiss();
        this.showError('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem IT.');
      });
    }
  }

  showError(message){
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }

}
