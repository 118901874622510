import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { domain } from 'process';

@Injectable({
  providedIn: 'root'
})
export class ApiCommunicationService {
  api_url = 'https://new.trackpack.eu/api/public/';
  //api_url = 'http://localhost:8000/';

  constructor(private http: HttpClient) {
    // console.log(document.location.host);
    if (!document.location.host.includes("trackpack.eu")) {
      // this.api_url = 'https://localhost/trackpack-backend-11032022/public/';
    }
   }

  // LOGOWANIE
  loginUser(email:string, password:string){
    return this.http.post(this.api_url+'login',
    {
      email: email,
      password: password
    },
    {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'json'
    });
  }

  // SPRAWDŹ TOKEN
  checkToken(api_token){
    return this.http.get(this.api_url+'check_token',
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  getOrders(api_token, index, limit, filters, column, direction, countries, scooter_models, company, keyword, is_active, drivers?, payment?, refund?, is_assembled?, inspection?, lunex_invoice?, lunex_invoice_payment_status?, filter_date_from?, filter_date_to?, hasRoute?){
    return this.http.post(this.api_url+'get_orders', {
      index: index,
      limit: limit,
      filters: filters,
      column: column,
      direction: direction,
      scooter_models: scooter_models,
      countries: countries,
      order_from: company,
      keyword: keyword,
      is_active: is_active,
      drivers: drivers,
      payment: payment,
      refund: refund,
      is_assembled: is_assembled,
      inspection_filter: inspection,
      lunex_invoice: lunex_invoice,
      lunex_invoice_payment_status: lunex_invoice_payment_status,
      lunex_invoice_date_from: filter_date_from,
      lunex_invoice_date_to: filter_date_to,
      has_route: hasRoute
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  add_order(api_token, order_date, order_from, linn_number, source, channel_reference, client, delivery_address_line_1, delivery_address_line_2, delivery_postcode, delivery_city, delivery_country, client_phone, client_email, scooter_model, type, reason, warehouse_location, collect_from, price, currency, cash, rent, test){
    return this.http.post(this.api_url+'add_order', {
      api_token: api_token,
      order_date: order_date,
      order_from: order_from,
      linn_id: linn_number,
      source: source,
      channel_reference: channel_reference,
      client: client,
      delivery_address_line_1: delivery_address_line_1,
      delivery_address_line_2: delivery_address_line_2,
      delivery_postcode: delivery_postcode,
      delivery_city: delivery_city,
      delivery_country: delivery_country,
      client_phone: client_phone,
      client_email: client_email,
      scooter_model: scooter_model,
      type: type,
      reason: reason,
      warehouse_location: warehouse_location,
      collect_from: collect_from,
      price: price,
      currency: currency,
      cash: cash,
      rent: rent,
      test: test
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  getOrderInfo(api_token, tracking_id){
    return this.http.get(this.api_url+'get_single_order?linn_id='+tracking_id,
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  getOrderLinn(api_token: string, qr_code:string){
    return this.http.get(this.api_url+'get_single_order?qr_code='+qr_code,
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  getGeolocalization(searchkey, street, postcode, city, country){
    return this.http.get('https://geocode.search.hereapi.com/v1/geocode?q='+street+', '+city+', '+postcode+', '+country+'&apiKey=mhr2673oGBCx608FShYnvhwIUmAQR_H9izimsk2NO_M',
    {

    });
  }

  getRoutesGeolocalization(searchkey){
    return this.http.get('https://geocode.search.hereapi.com/v1/geocode?q='+searchkey+'&apiKey=mhr2673oGBCx608FShYnvhwIUmAQR_H9izimsk2NO_M',
    {

    });
  }

  getMapTimes(origin, destination){
    return this.http.get('https://router.hereapi.com/v8/routes?transportMode=car&origin='+origin+'&destination='+destination+'&return=summary&apiKey=mhr2673oGBCx608FShYnvhwIUmAQR_H9izimsk2NO_M',
    {

    });
  }

  getOrderCountries(api_token){
    return this.http.get(this.api_url+'get_order_countries',
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  getScooterModels(api_token){
    return this.http.get(this.api_url+'get_scooter_models',
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  in_progress_step(api_token, linn_id, send_email){
    return this.http.post(this.api_url+'in_progress_step', {
      linn_id: linn_id,
      send_email: send_email
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  ready_to_send_step(api_token, linn_id, driver, loading_date, date_from, date_to, send_email, loading_place?, unit_shipping_cost?){
    return this.http.post(this.api_url+'ready_to_send_step', {
      linn_id: linn_id,
      driver_id: driver,
      loading_date: loading_date,
      expected_delivery_date_from: date_from,
      expected_delivery_date_to: date_to,
      send_email: send_email,
      unit_shipping_cost: unit_shipping_cost,
      loading_place: loading_place
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  update_ready_to_send_step(api_token, linn_id, driver, loading_date, date_from, date_to, send_email, unit_shipping_cost?, loading_place?){
    return this.http.post(this.api_url+'update_step', {
          step: 2,
          linn_id: linn_id,
          driver_id: driver,
          loading_date: loading_date,
          expected_delivery_date_from: date_from,
          expected_delivery_date_to: date_to,
          send_email: send_email,
          unit_shipping_cost: unit_shipping_cost,
          loading_place: loading_place
        },
        {
          headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
          responseType: 'json'
        });
  }

  get_drivers(api_token, trackpack_status){
    return this.http.get(this.api_url+'get_drivers?status='+trackpack_status,
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  archive_driver(api_token, driver_id){
    return this.http.post(this.api_url+'delete_driver',
    {
      id: driver_id
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  activate_driver(api_token, driver_id){
    return this.http.post(this.api_url+'activate_driver',
    {
      id: driver_id
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  add_driver(api_token, name, surname, email, phone, avatar, perm){
    return this.http.post(this.api_url+'add_driver',
    {
      name: name,
      surname: surname,
      email: email,
      phone: phone,
      avatar: avatar,
      perm: perm
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  update_order_details(api_token, id, linn_id, order_date, order_from, collect_from, source, channel_reference, price, currency, scooter, type, warehouse, public_comment, ecommerce_comment, warehouse_comment, linn_note, test, cash, rent, important, this_risk_of_receiving_negative_feedback){
    return this.http.post(this.api_url+'update_order', {
      id: id,
      linn_id: linn_id,
      order_date: order_date,
      order_from: order_from,
      collect_from: collect_from,
      source: source,
      channel_reference: channel_reference,
      price: price,
      currency: currency,
      scooter_model: scooter,
      type: type,
      warehouse_location: warehouse,
      public_comment: public_comment,
      ecommerce_comment: ecommerce_comment,
      warehouse_comment: warehouse_comment,
      linn_note: linn_note,
      test: test,
      cash: cash,
      rent: rent,
      important: important,
      this_risk_of_receiving_negative_feedback: this_risk_of_receiving_negative_feedback
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }


  update_order_address(api_token, id, client, delivery_address_line_1, delivery_address_line_2, delivery_postcode, delivery_city, delivery_country, client_email, client_phone, linn_id, public_comment, ecommerce_comment, warehouse_comment){
    return this.http.post(this.api_url+'update_order', {
      id: id,
      client: client,
      delivery_address_line_1: delivery_address_line_1,
      delivery_address_line_2: delivery_address_line_2,
      delivery_postcode: delivery_postcode,
      delivery_city: delivery_city,
      delivery_country: delivery_country,
      client_email: client_email,
      client_phone: client_phone,
      linn_id: linn_id,
      public_comment: public_comment,
      ecommerce_comment: ecommerce_comment,
      warehouse_comment: warehouse_comment
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  get_stats(api_token){
    return this.http.get(this.api_url+'get_statistics',
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  update_driver(api_token, id, name, surname, email, phone, password, confirm_password, avatar_base64, perm){
    return this.http.post(this.api_url+'update_driver', {
      id: id,
      name: name,
      surname: surname,
      email: email,
      phone: phone,
      password: password,
      confirm_password: confirm_password,
      avatar: avatar_base64,
      perm: perm
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  get_permissions_type(api_token) {
    return this.http.get(this.api_url+'get_permissions_type', {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  order_exist(linn_id){
    return this.http.post(this.api_url+'order_exist',
    {
      linn_id: linn_id
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': 'none' },
      responseType: 'json'
    });
  }

  change_print_status(api_token, linn_id, doc_type){
    return this.http.post(this.api_url+'change_print_status',
    {
      linn_id: linn_id,
      status_type: doc_type
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  realize_zk(api_token, linn_id){
    return this.http.post(this.api_url+'realize_zk',
    {
      linn_id: linn_id
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  change_order_activity_status(api_token, linn_id, is_active, send_email){
    return this.http.post(this.api_url+'change_order_activity_status',
    {
      linn_id: linn_id,
      is_active: is_active,
      send_email: send_email
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  send_step(api_token: string, qr_code: string, responsible_comment: string, send_email: any){
    return this.http.post(this.api_url+'send_step/'+qr_code,
    {
      responsible_comment: responsible_comment,
      send_email: send_email
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  delivered_step(api_token: string, signature: any, first_photo: any, second_photo: any, qr_code: string, send_email: any){
    return this.http.post(this.api_url+'delivered_step/'+qr_code,
    {
      signature: signature,
      first_photo: first_photo,
      second_photo: second_photo,
      qr_code: qr_code,
      send_email: send_email
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  getCollectPoints(api_token){
    return this.http.get(this.api_url+'get_collect_points',
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  undo_status(api_token, status, reason, linn_id){
    return this.http.post(this.api_url+'undo_step',
    {
      step: status,
      reason: reason,
      linn_id: linn_id
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  update_collection_point(api_token, linn_id, collect_point, type){
    return this.http.post(this.api_url+'update_collection_point',
    {
      linn_id: linn_id,
      collect_point: collect_point,
      type: type
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  get_defects_for_notification(api_token){
    return this.http.get(this.api_url+'get_defects_for_notification',
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  test(api_token, id, type){
    return this.http.post(this.api_url+'get_defects_for_notification',
    {
      id: id,
      type: type
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }


  add_order_service(api_token, linn_id, reason, type, status?){
    return this.http.post(this.api_url+'add_order_service',
    {
      linn_id: linn_id,
      type: type,
      reason: reason,
      inspection_status: status
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  getOrderServices(api_token, linn_id){
    return this.http.post(this.api_url+'get_single_order_services',
    {
      linn_id: linn_id
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  add_order_message(api_token, linn_id, description){
    return this.http.post(this.api_url+'add_order_message',
    {
      linn_id: linn_id,
      description: description
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  update_order_service_status(api_token, linn_id){
    return this.http.post(this.api_url+'update_order_service_status',
    {
      linn_id: linn_id,
      signature: ''
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  add_attempt(api_token, description, signature, photo, qr_code){
    return this.http.post(this.api_url+'add_attempt/'+qr_code,
    {
      description: description,
      driver_signature: signature,
      attempt_photo: photo
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  update_trackpack_date(api_token, linn_id, type, date){
    return this.http.post(this.api_url+'change_step_date',
    {
      linn_id: linn_id,
      type: type,
      date: date
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  submitAssembled(api_token, linn_id, vin, responsible, status){
    return this.http.post(this.api_url+'change_assembling_status',
    {
      linn_id: linn_id,
      vin: vin,
      responsible: responsible,
      status: status
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  send_custom_mail(api_token, id, linn_id, email, topic, message, order_from, source){
    return this.http.post(this.api_url+'send_custom_mail',
    {
      id: id,
      linn_id: linn_id,
      client_mail: email,
      topic: topic,
      message: message,
      order_from: order_from,
      source: source
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  paid(api_token, linn_id, note){
    return this.http.post(this.api_url+'change_payment_status',
    {
      linn_id: linn_id,
      payment_comment: note
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  change_invoice_payment_status(api_token, linn_id, lunex_invoice_date, lunex_note){
    return this.http.post(this.api_url+'change_invoice_payment_status',
    {
      linn_id: linn_id,
      invoice_payment_comment: lunex_note,
      invoice_payment_date: lunex_invoice_date,
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  refund(api_token, linn_id, note){
    return this.http.post(this.api_url+'change_refund_status',
    {
      linn_id: linn_id,
      payment_comment: note
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  /*getCountryCode(country){
    return this.http.get('https://restcountries.eu/rest/v2/name/'+country,
    {
    });
  }*/

  add_route(api_token, loading_date, driver_id, description){
    return this.http.post(this.api_url+'add_route',
    {
      loading_date: loading_date,
      driver_id: driver_id,
      description: description
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  edit_route(api_token, route_id, loading_date, driver_id, description){
    return this.http.post(this.api_url+'edit_route',
    {
      id: route_id,
      loading_date: loading_date,
      driver_id: driver_id,
      description: description
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  get_routes(api_token, is_archived){
    return this.http.get(this.api_url+'get_routes?is_archived='+is_archived,
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  set_order_route(api_token, current_index, order_id, route_id){
    return this.http.post(this.api_url+'set_order_route',
    {
      order_id: order_id,
      route_id: route_id,
      position: current_index
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  setRoutePointAverageTimeAndDistance(api_token, id, length, duration, date){
    return this.http.post(this.api_url+'set_route_point_average_time_and_distance',
    {
      order_id: id,
      route_point_distance: length,
      route_point_timespan: duration,
      route_point_estimate_time: date
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  setRouteExpectedDate(api_token, date, item_id){
    return this.http.post(this.api_url+'set_route_expected_date',
    {
      order_id: item_id,
      route_expected_date: date
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  addPoint(api_token, postcode, city, country, route_id){
    return this.http.post(this.api_url+'add_route_point',
    {
      delivery_postcode: postcode,
      delivery_city: city,
      delivery_country: country,
      route_id: route_id
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  deleteRoute(api_token, id){
    return this.http.post(this.api_url+'delete_route',
    {
      route_id: id
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }

  moveRouteToArchive(api_token, id){
    return this.http.post(this.api_url+'move_route_to_archive',
    {
      route_id: id
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token },
      responseType: 'json'
    });
  }
}
