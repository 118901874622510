import { Component, OnInit, Inject } from '@angular/core';
import { ApiCommunicationService } from '../api-communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { $ } from 'protractor';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit {

  api_token: any;
  perm: any;
  drivers_api: any;
  drivers: any;
  form_opened: any = 0;
  permissions_api: any;

  new_driver: any = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    perm: 2
  };

  permissions_type: any = {};

  av_files: File[] = [];
  avatar_base64: any = '';

  constructor(public api: ApiCommunicationService, public dialog: MatDialog) { }

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.perm = localStorage.getItem('user_perm');
    this.getDrivers();
    this.getPermissionsType();
  }

  getPermissionsType() {
    this.permissions_api = this.api.get_permissions_type(this.api_token);
    this.permissions_api.subscribe(data => {
      this.permissions_type = data.data;
    });
  }

  getDrivers(){
    let trackpack_status = null;
    if(this.perm == 3){
      trackpack_status = 2;
    }else{
      trackpack_status = 3;
    }
    this.drivers_api = this.api.get_drivers(this.api_token, trackpack_status);
    this.drivers_api.subscribe(data => {
      this.drivers = [];
      for(let id in data.data){
        console.log(data.data[id]);
        if(data.data[id].avatar!=null) {
          data.data[id].avatar = this.api.api_url+"../storage/app/avatars/"+data.data[id].avatar;
        }
        this.drivers.push(data.data[id]);
      }
      console.log(data);
    }, err => {
      console.log(err);
    });
  }

  archiveDriver(driver_id){
    this.drivers_api = this.api.archive_driver(this.api_token, driver_id);
    this.drivers_api.subscribe(data => {
      this.getDrivers();
    }, err => {
      console.log(err);
    });
  }

  activateDriver(driver_id){
    this.drivers_api = this.api.activate_driver(this.api_token, driver_id);
    this.drivers_api.subscribe(data => {
      this.getDrivers();
    }, err => {
      console.log(err);
    });
  }

  addNewDriver(){
    this.drivers_api = this.api.add_driver(this.api_token, this.new_driver.name, this.new_driver.surname, this.new_driver.email, this.new_driver.phone, this.avatar_base64, this.new_driver.perm);
    this.drivers_api.subscribe(data => {
      console.log(data);
      if(data.status == true){
        this.new_driver.name = '';
        this.new_driver.surname = '';
        this.new_driver.email = '';
        this.new_driver.phone = '';
        this.new_driver.perm = 2;
        this.getDrivers();
        this.closeForm();
        this.onRemoveAvatar(undefined);
      }
    }, err => {
      console.log(err);
    });
  }

  fileToBase64 = (file:File):Promise<string> => {
    return new Promise<string> ((resolve,reject)=> {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result.toString());
         reader.onerror = error => reject(error);
    })
  }

  openForm(){
    this.form_opened = 1;
  }

  closeForm(){
    this.form_opened = 0;
  }

  editDriver(id, name, surname, phone, email, avatar, perm) {
    const dialogRef =  this.dialog.open(EditDriver, {
      data: {
        permissions_type: this.permissions_type,
        id: id,
        name: name,
        surname: surname,
        phone: phone,
        email: email,
        avatar: avatar,
        perm: perm
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDrivers();
    });
  }



  checkDate(date){
    let from  = moment().format('YYYY-MM-DD H:mm:ss');
    let to = date;
    let diff = moment(from, "YYYY-MM-DD H:mm:ss").diff(moment(to,"YYYY-MM-DD H:mm:ss"), 'days');
    if(diff > 0) {
      return 'true';
    }else{
      return 'false';
    }
  }

  onSelectAvatar(event) {
    console.log(event);
    if (this.av_files.length > 0) {
      this.onRemoveAvatar(undefined);
    }
    this.av_files.push(...event.addedFiles);
    this.fileToBase64(this.av_files[0]).then(result=>{
            this.avatar_base64=result; // To remove data url part
    });         
    console.log(this.av_files);
  }
  
  onRemoveAvatar(event) {
    if (this.av_files.length > 0) {
      this.av_files.splice(0, 1);
      this.avatar_base64 = '';
    }
  }

}


@Component({
  selector: 'edit-driver',
  template: `
    <h1 mat-dialog-title>Edit driver:</h1>
      <div class="edit-user-form" mat-dialog-content>
        <ngx-avatar *ngIf="!edit_avatar" name="{{edit.name}} {{edit.surname}}" class="avatar avatar-center" [src]="edit.avatar" size="90"></ngx-avatar>
        <div *ngIf="!edit_avatar" (click)="editAvatar()" class="edit-button"><mat-icon aria-hidden="false" aria-label="Change avatar">autorenew</mat-icon></div>
        <div *ngIf="edit_avatar" class="avatar-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectAvatar($event)">
          <ngx-dropzone-label>Drop avatar here</ngx-dropzone-label>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of av_files" [file]="f" [removable]="true" (removed)="onRemoveAvatar(f)"></ngx-dropzone-image-preview>
        </div>
        <mat-form-field style="margin-right: 5px;">
          <mat-label>Name</mat-label>
          <input [(ngModel)]="edit.name" matInput>
        </mat-form-field><br />
        <mat-form-field style="margin-right: 5px;">
          <mat-label>Surname</mat-label>
          <input [(ngModel)]="edit.surname" matInput>
        </mat-form-field><br />
        <mat-form-field style="margin-right: 5px;">
          <mat-label>Email</mat-label>
          <input [(ngModel)]="edit.email" matInput>
        </mat-form-field><br /> 
        <mat-form-field style="margin-right: 5px;">
          <mat-label>Phone</mat-label>
          <input [(ngModel)]="edit.phone" matInput>
        </mat-form-field><br />
        <mat-form-field style="margin-right: 5px;">
          <mat-label>New password</mat-label>
          <input [(ngModel)]="edit.password" type="password" matInput>
        </mat-form-field><br />
        <mat-form-field style="margin-right: 5px;">
          <mat-label>Confirm new password</mat-label>
          <input [(ngModel)]="edit.confirm_password" type="password" matInput>
        </mat-form-field>
        <div style="color: red; font-size: 14px" *ngIf="password_info == 1">
          The passwords do not match
        </div><br />
        <mat-form-field *ngIf="perm==0"  style="margin-right: 5px;">
          <mat-label>User type</mat-label>
          <mat-select [(value)]="edit.perm" [(ngModel)]="edit.perm">
            <mat-option *ngFor="let type of permissions_type" [value]="type.id">{{type.permission_name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div mat-dialog-actions>
        <button mat-button cdkFocusInitial (click)="dialogClose()">Cancel</button>
        <button mat-raised-button color="primary" (click)="save()">Save</button>
      </div>
  `,
  styleUrls: ['./drivers.component.scss']
})

export class EditDriver {

  api_token: any;
  drivers_api: any;
  drivers: any;
  password_info: any;
  perm: any = 2;

  av_files: File[] = [];
  avatar_base64: any = '';
  edit_avatar: boolean = false;
  permissions_type: any = {};

  id: any;

  edit: any = {
    name: '',
    surname: '',
    phone: '',
    password: '',
    confirm_password: '',
    avatar_base64: '',
    perm: "2"
  };

  constructor(public dialogRef: MatDialogRef<EditDriver>, @Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar, public api: ApiCommunicationService) {
    this.api_token = localStorage.getItem('api_token');
    this.permissions_type = this.data.permissions_type;
    this.id = this.data.id;
    this.edit.name = this.data.name;
    this.edit.surname = this.data.surname;
    this.edit.phone = this.data.phone;
    this.edit.email = this.data.email;
    this.edit.avatar = this.data.avatar;
    this.perm = localStorage.getItem('user_perm');
    if (this.perm == 0) {
      this.edit.perm = this.data.perm; //Konwersja INT na STRING
    }

  }

  dialogClose(){
    this.dialogRef.close();
  }

  save(){
    if(this.edit.password == null || this.edit.password == this.edit.confirm_password){
      this.password_info = 0;
      this.drivers_api = this.api.update_driver(this.api_token, this.id, this.edit.name, this.edit.surname, this.edit.email, this.edit.phone, this.edit.password, this.edit.confirm_password, this.edit.avatar_base64, this.edit.perm);
      this.drivers_api.subscribe(data => {
        console.log(data);
        if(data.status == true){
          this.dialogRef.close();
        }
      }, err => {
        console.log(err);
      });
    }else{
      this.password_info = 1;
    }
  }


  fileToBase64 = (file:File):Promise<string> => {
    return new Promise<string> ((resolve,reject)=> {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result.toString());
         reader.onerror = error => reject(error);
    })
  }

  editAvatar() {
    this.edit_avatar = true;
  }

  onSelectAvatar(event) {
    console.log(event);
    if (this.av_files.length > 0) {
      this.rmAvatar();
    }
    this.av_files.push(...event.addedFiles);
    this.fileToBase64(this.av_files[0]).then(result=>{
            this.edit.avatar_base64=result; // To remove data url part
    });         
    console.log(this.av_files);
  }
  
  onRemoveAvatar(event) {
    this.rmAvatar();
    this.edit_avatar=false;
  }

  rmAvatar(){
    if (this.av_files.length > 0) {
      this.av_files.splice(0, 1);
      this.edit.avatar_base64=''; 
    }
  }

}
