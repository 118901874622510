import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ApiCommunicationService } from '../api-communication.service';


declare var H: any;

@Component({
  selector: 'app-mapdialog',
  templateUrl: './mapdialog.component.html',
  styleUrls: ['./mapdialog.component.scss']
})
export class MapdialogComponent implements OnInit {

  @ViewChild("map", { static: true }) public mapElement: ElementRef;

  public lat: any = '50.808072';
  public lng: any = '19.114494';

  public width: any = '1000px';
  public height: any = '600px';

  private platform: any;
  private map: any;

  private _appId: string = 'Z83pTwvQpuqEMnUovK48';
  private _appCode: string = 'mhr2673oGBCx608FShYnvhwIUmAQR_H9izimsk2NO_M';
  ui: any;

  geo_api: any;
  orders_api: any;
  orders: any;

  api_token: any;

  constructor(private _bottomSheet: MatBottomSheet, public api: ApiCommunicationService) { }

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.platform = new H.service.Platform({
      "app_id": this._appId,
      "app_code": this._appCode,
      useHTTPS: true
    });
  }

  ngAfterViewInit() {
    let pixelRatio = window.devicePixelRatio || 1;
    let defaultLayers = this.platform.createDefaultLayers({
      tileSize: pixelRatio === 1 ? 256 : 512,
      ppi: pixelRatio === 1 ? undefined : 320
    });

    this.map = new H.Map(this.mapElement.nativeElement,
      defaultLayers.normal.map, { pixelRatio: pixelRatio });

    var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
    var ui = H.ui.UI.createDefault(this.map, defaultLayers);

    this.map.setCenter({ lat: this.lat, lng: this.lng });
    this.map.setZoom(4);
    this.getOrders();
  }

  getOrders(){
    console.log("GET ORDERS");
    this.orders_api = this.api.getOrders(this.api_token, 0, 100, ["0", "1"], 'order_date', 'desc', null, null, null, null, 1);
    this.orders_api.subscribe(data => {
      this.orders = data.data;
      console.log(data);
      for (let order of data.data) {
        console.log(order);
        this.getGeolocalization(order.delivery_address_line_1, order.delivery_postcode, order.delivery_city, order.delivery_country);
      }
      console.log(this.orders);
    }, err => {
      console.log(err);
    });
  }

  getGeolocalization(street, postcode, city, country){
    this.geo_api = this.api.getGeolocalization(postcode+', '+city, street, postcode, city, country);
    // this.geo_api = this.api.getGeolocalization(postcode);
    this.geo_api.subscribe(data => {
      let lat = data['items'][0]['position']['lat'];
      let lng = data['items'][0]['position']['lng'];
      if(lat != null && lng != null){
        this.dropMarker({"lat": lat, "lng": lng}, {"postcode": postcode, "description": "Test"});
      }
    }, err => {
      console.log(err);
    });
  }

  private dropMarker(coordinates: any, data: any) {
    let marker = new H.map.Marker(coordinates);
    marker.setData("<p>" + data.postcode + "<br>" + data.description + "</p>");
    marker.addEventListener('tap', event => {
      // let bubble = new H.ui.InfoBubble(event.target.getPosition(), {
      //   content: event.target.getData()
      // });
      // this.ui.addBubble(bubble);
      this._bottomSheet.open(BottomSheetOverviewExampleSheet, {
        data: data
      });
      console.log(event);
      console.log(data);
    }, false);
    this.map.addObject(marker);
  }

}

@Component({
  selector: 'bottom-sheet-overview-example-sheet',
  template: `
    <h3>{{data.postcode}}</h3>
    <mat-nav-list>
      <a href="https://docs.google.com/" mat-list-item (click)="openLink($event)">
        <span mat-line>Dodaj do trasy</span>
        <span mat-line>Przenieś to zamówienie do zaplanowanej trasy</span>
      </a>
      <a href="https://keep.google.com/" mat-list-item (click)="openLink($event)">
        <span mat-line>Szczegóły</span>
        <span mat-line>Zobacz wszystkie informacje na temat tego zamówienia</span>
      </a>
    </mat-nav-list>
  `,
})
export class BottomSheetOverviewExampleSheet {
  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewExampleSheet>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {}

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  ngAfterViewInit(){
    console.log(this.data);
  }
}
