import { Component, ChangeDetectorRef } from '@angular/core';
import { Location } from "@angular/common";
import { Router, Routes, RouterModule } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiCommunicationService } from './api-communication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'trackpack';
  api_token: any;
  notify_api: any;
  notify: any;
  perm: any;
  login_status: any;
  current_route: any = null;
  name: any;
  surname: any;
  user_api: any;
  stats_loaded: any = 0;
  avatar: any = "";

  constructor(private router: Router, location: Location, public api: ApiCommunicationService, private changeDetectorRef: ChangeDetectorRef) {
    this.api_token = localStorage.getItem('api_token');
    this.perm = localStorage.getItem('user_perm');
    router.events.subscribe(val => {
      this.getNotify();
      this.api_token = localStorage.getItem('api_token');
      this.name = localStorage.getItem('user_name');
      this.surname = localStorage.getItem('user_surname');
      this.avatar = localStorage.getItem('avatar');
      this.perm = localStorage.getItem('user_perm');
      let route = location.path().split("?", 2)[0];
      if(this.current_route != route){
        this.current_route = route;
        console.log(route);
        this.redirect_to_login();
      }
    });
    this.verifyUser();
    this.getNotify();
  }

  redirect_to_login(){
    if(this.api_token == null){
      this.login_status = 0;
      if(this.current_route != '/tracking' && this.current_route != '/track_order' && this.current_route != '/login' && this.current_route != '/details'){
        console.log(this.current_route);
        this.router.navigate(['/login']);
      }
    }else{
      this.login_status = 1;
    }
  }

  verifyUser(){
    this.api_token = localStorage.getItem('api_token');
    this.user_api = this.api.checkToken(this.api_token);
    this.user_api.subscribe(data => {
      if(data.status == false){
        this.logout();
      }
    }, err => {
    });
  }

  getNotify(){
    this.notify_api = this.api.get_defects_for_notification(this.api_token);
    this.notify_api.subscribe(data => {
      this.notify=data;
      this.stats_loaded = 1;
      this.changeDetectorRef.detectChanges();
      //console.log(data);
    }, err => {
      console.log(err);
    });
  }

  logout(){
    localStorage.clear();
    this.login_status = 0;
    this.router.navigate(['/login']);
  }
}
