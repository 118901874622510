import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ApiCommunicationService } from '../api-communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ThrowStmt } from '@angular/compiler';
import { unwatchFile } from 'fs';

declare var H: any;

export interface PeriodicElement {
  date: string;
  responsible: string;
  event: string;
  description: string;
  hidden: any;
}

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})

export class TrackingComponent implements OnInit {

  displayedColumns: string[] = ['date', 'responsible', 'event'];
  dataSource: any;
  tracking_id: any;
  update_api: any;
  services: any;
  order_api: any;
  order: any;
  loading: any = 0;
  ui: any;
  color_class: any;
  progress_bar_class: any;
  geo_api: any;
  show_report: any = 0;

  api_token: any;

  qr_code: any;
  linn_id: any;

  @ViewChild("map", { static: true }) public mapElement: ElementRef;

  public lat: any = '50.82471';
  public lng: any = '19.094158';

  public width: any = '1000px';
  public height: any = '600px';

  private platform: any;
  private map: any;

  private _appId: string = 'Z83pTwvQpuqEMnUovK48';
  private _appCode: string = 'mhr2673oGBCx608FShYnvhwIUmAQR_H9izimsk2NO_M';

  constructor(private route: ActivatedRoute, private snackBar: MatSnackBar, public dialog: MatDialog, public api: ApiCommunicationService) {


    // this.platform = new H.service.Platform({
    //     "app_id": "dSoxRmY8ln82qJVHVuQk",
    //     "app_code": "MH3fwlEgq87O55VMrBhJI09RVarQ0RtMr0f_-_e5ECQ",
    //     useHTTPS: true
    // });


  }

  ngOnInit() {
    this.api_token = 'NO_TOKEN';
    this.route.queryParams.subscribe(params => {
      this.qr_code = params['qr_code'];
      if(this.qr_code != null){
        this.linnIdFromQr(this.qr_code);
      }else{
        this.linn_id = params['linn_id'];
        this.getOrderInfo(this.linn_id, 1);
        console.log('ORDER Services'+this.linn_id);
        this.getOrderServices();
      }
    });
    this.platform = new H.service.Platform({
      "app_id": this._appId,
      "app_code": this._appCode,
      useHTTPS: true
    });
  }

  linnIdFromQr(qr_code){
    this.order_api = this.api.getOrderLinn(this.api_token, qr_code);
    this.order_api.subscribe(data => {
      if(data.status == true){
        this.getOrderInfo(data.data.linn_id, 1);
      }
    }, err => {

    });
  }

  getOrderInfo(tracking_id, map_status){
    const ELEMENT_DATA: PeriodicElement[] = [];
    this.order_api = this.api.getOrderInfo(this.api_token, tracking_id);
    this.order_api.subscribe(data => {
      this.order = data.data;
      console.log(data.data);
      if (this.order.driver!=null&&this.order.driver!=undefined) {
        if (this.order.driver.avatar!=null && this.order.driver.avatar!=undefined && this.order.driver.avatar !='') {
          this.order.driver.avatar = this.api.api_url+"../storage/app/avatars/"+this.order.driver.avatar;
        }
      }

      let scooter_color = data.data.scooter_model.color;
      let color_class = '';
      if(scooter_color == 'Black'){color_class = 'scooter_black';}
      else if(scooter_color == 'Silver'){color_class = 'scooter_silver';}
      else if(scooter_color == 'White'){color_class = 'scooter_white';}
      else if(scooter_color == 'Red'){color_class = 'scooter_red';}
      else if(scooter_color == 'Blue'){color_class = 'scooter_blue';}
      else if(scooter_color == 'Gray'){color_class = 'scooter_gray';}
        else{color_class = 'scooter_color_unknow';}
      this.color_class = color_class;

      // ORDER STATUS PROGRESS BAR start
      // status 0 percents:
      //   5%, 10%, 15%, 20%
      // status 1 percents:
      //   30%, 35%, 40%
      // status 2 percents:
      //   50%, 55%, 60%
      // status 3 percents:
      //   70%, 75%, 80%, 85%, 90%, 95%
      // status 4 percents:
      //   100%

        let from  = moment().format('DD/MM/YYYY H:i:s');
        let to = this.order.order_date.date+' '+this.order.order_date.hour;
        let ms = moment(from,"DD/MM/YYYY H:i:s").diff(moment(to,"DD/MM/YYYY H:i:s"));
        let diff_h = ms/1000/60/60;
        console.log(diff_h);
        if(this.order.trackpack_status == 0){ //Ordered (24h / 1d)
          if(diff_h < 6)
            this.progress_bar_class = 'progress_0_5';
          else if(diff_h < 12)
            this.progress_bar_class = 'progress_0_10';
          else if(diff_h < 18)
            this.progress_bar_class = 'progress_0_15';
          else
            this.progress_bar_class = 'progress_0_20';
        }else if(this.order.trackpack_status == 1){ //In progress (120h / 5d)

          //W KOLEJNYCH STATUSACH OBLICZYĆ RÓŻNICĘ MIĘDZY DATĄ ZMIANY POPRZEDNIEGO STATUSU, A OBECNĄ CHWILĄ

          this.progress_bar_class = 'progress_1_30';
          // this.progress_bar_class = 'progress_1_35';
          // this.progress_bar_class = 'progress_1_40';
        }else if(this.order.trackpack_status == 2){ //Ready to send (24h / 1d)
          // this.progress_bar_class = 'progress_2_50';
          this.progress_bar_class = 'progress_2_55';
          // this.progress_bar_class = 'progress_2_60';
        }else if(this.order.trackpack_status == 3){ //Sent (96h / 4d)
          // this.progress_bar_class = 'progress_3_70';
          // this.progress_bar_class = 'progress_3_75';
          // this.progress_bar_class = 'progress_3_80';
          this.progress_bar_class = 'progress_3_85';
          // this.progress_bar_class = 'progress_3_90';
          // this.progress_bar_class = 'progress_3_95';
        }else if(this.order.trackpack_status == 4){ //Delivered
          this.progress_bar_class = 'progress_4_100';
        }
      // ORDER STATUS PROGRESS BAR end

      //HISTORY
      if (this.order.parent_linn_id==undefined) {
        ELEMENT_DATA.push(
          {date: this.order.order_date, responsible: this.order.client, event: 'Ordered', description: '', hidden: 0}
        );
      } else {
        ELEMENT_DATA.push(
          {date: this.order.order_date, responsible: this.order.client, event: 'Collected', description: '', hidden: 0}
        );
      }
 

      console.log(this.order.order_date);

      for(let el of this.order.history_events){
        if(el.hidden == 0){
          ELEMENT_DATA.push(
            {date: el.date, responsible: el.responsible_user.name+' '+el.responsible_user.surname, event: el.event, description: el.description, hidden: el.hidden}
          );
        }
      }

      this.dataSource = ELEMENT_DATA;

      if(map_status == 1){
        this.getGeolocalization(this.order.delivery_address_line_1, this.order.delivery_postcode, this.order.delivery_city, this.order.delivery_country);
      }

      this.loading=1;
    }, err => {
      this.loading=1;
      console.log(err);
    });
  }

  getOrderServices(){
  console.log('GET ORDER Services');
    this.update_api = this.api.getOrderServices('', this.linn_id);
    this.update_api.subscribe(data => {
      console.log(data);
      if(data.status == true){
        this.services = data;
      }
    }, err => {
      console.log(err);
    });
  }

  getGeolocalization(street, postcode, city, country){
    console.log(street);
    console.log(postcode+' postcode | city '+city+' | ctr: '+country);
    console.log("POBIERANIE LOKALIZACJI");
    this.geo_api = this.api.getGeolocalization(postcode+', '+city, street, postcode, city, country);
    // this.geo_api = this.api.getGeolocalization(postcode);
    this.geo_api.subscribe(data => {
      let lat = data['items'][0]['position']['lat'];
      let lng = data['items'][0]['position']['lng'];
      if(lat != null && lng != null){
        this.lat = lat;
        this.lng = lng;

        console.log('LAT: '+lat+'; LNG: '+lng);

        let pixelRatio = window.devicePixelRatio || 1;
        let defaultLayers = this.platform.createDefaultLayers({
          tileSize: pixelRatio === 1 ? 256 : 512,
          ppi: pixelRatio === 1 ? undefined : 320
        });

        this.map = new H.Map(this.mapElement.nativeElement,
          defaultLayers.normal.map, { pixelRatio: pixelRatio });

        var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
        var ui = H.ui.UI.createDefault(this.map, defaultLayers);

        this.map.setCenter({ lat: this.lat, lng: this.lng });
        this.map.setZoom(5);

        this.dropMarker({"lat": this.lat, "lng": this.lng}, {"title": "Testowy Marker", "vicinity": "Test"});
      }
      console.log(data);
    }, err => {
      console.log(err);
    });
  }

  private dropMarker(coordinates: any, data: any) {
    console.log(coordinates);
    let marker = new H.map.Marker(coordinates);
    marker.setData("<p>" + data.title + "<br>" + data.vicinity + "</p>");
    marker.addEventListener('tap', event => {
      let bubble = new H.ui.InfoBubble(event.target.getPosition(), {
        content: event.target.getData()
      });
      this.ui.addBubble(bubble);
    }, false);
    this.map.addObject(marker);
  }

  copyValue(value){
    this.snackBar.open('Copied to clipboard', 'OK', {
      duration: 2000,
    });
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  showReport(status){
    this.show_report = status;
  }

}
