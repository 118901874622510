import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { ApiCommunicationService } from '../api-communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { DocumentsComponent } from '../documents/documents.component';
import { BottomSheetCollectPoint } from '../bottom-sheet-collect-point/bottom-sheet-collect-point.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
// import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

export interface PeriodicElement {
  order_date: string;
  cancelled_date: string;
  client: string;
  client_phone: string;
  client_email: string;
  linn_id: string;
  parent_linn_id: string;
  delivery_postcode: string;
  delivery_country: string;
  delivery_address_line_1: any;
  delivery_address_line_2: any;
  delivery_city: any;
  scooter_model: string;
  color_class: string;
  order_from: string;
  order_from_details: any;
  source: number;
  warehouse_location: any;
  collect_from: any;
  collect_point: any;
  price: string;
  currency: string;
  type: any;
  warehouse_comment: any;
  is_active: any;
  cash: any;
  rent: any;
  test: any;
  qr_code: any;
  order_status: any;
  documents_status: any;
  cmr_status: any;
  linn_note: any;
  is_warning: any;
  is_assembled: any;
  vin: any;
  responsible_for_assemble: any;
  driver: any;
  payment_comment: any;
  months_from_inspection: any;
  invoice_payment_date: any;
  invoice_payment_comment: any;
  important: any;
  risk_negative: any;
  b2b: any;
}

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})

export class OrdersComponent implements OnInit {
  displayedColumns: string[] = ['order_date', 'linn_id', 'client', 'delivery_postcode', 'delivery_country', 'scooter_model', 'action'];
  dataSource: any;
  orderCount: any;
  loading: any = 0;
  orders_api: any;
  order_api: any;
  orders: any = [];

  filter_status: any = ["0", "1"];
  filter_country: any = [];
  filter_model: any = [];
  filter_company: any = ["1", "2", "3", "4"];
  filter_driver: any = [];
  filter_payment: any;
  filter_refund: any;
  filter_inspection: any;

  filter_date_from: any;
  filter_date_from_val: any;
  filter_date_to: any;
  filter_date_to_val: any;

  lunex_invoice_payment_status: any;
  lunex_invocies: any;

  page_index: any = 0;
  page_limit: any = 10;

  form_opened: any = 0;

  filter_column: any = 'order_date';
  filter_direction: any = 'desc';

  filter_keyword: any;

  darkmode: any = false;

  countries: any;
  models: any;

  api_token: any;
  perm: any;

  new_order: any = {
    order_date: '',
    order_from: '',
    linn_number: '',
    source: '',
    channel_reference: '',
    client: '',
    delivery_address_line_1: '',
    delivery_address_line_2: '',
    delivery_postcode: '',
    delivery_city: '',
    delivery_country: '',
    client_phone: '',
    client_email: '',
    scooter_model: '',
    type: '',
    reason: '',
    warehouse_location: '',
    collect_from: '',
    price: '',
    currency: '',
    cash: 0,
    rent: 0,
    test: 0,
    payment_method: "0",
    months_from_inspection: ''
  };

  is_active: any = 1;
  drivers: any;
  selectedIndex: any = 0;
  is_assembled: any;
  create_excel: any = 0;
  tab_index: any = 0;

  amazon_sources: any = [4,5,6,7,8,33,34,35,9,12,13,14,15,32,36,37,21,22,23,24,25,27,28,39];
  ebay_sources: any = [1,2,3,17,11,20];
  web_sources: any = [16,18,19,30,38];
  other_sources: any = [29,31,10];

  constructor(public api: ApiCommunicationService, private snackBar: MatSnackBar, public dialog: MatDialog, private _bottomSheet: MatBottomSheet) { }

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.perm = localStorage.getItem('user_perm');
    this.getScooterModels();
    this.getDrivers();

    let filter_status = localStorage.getItem('filter_status');
    if(filter_status != null){
      this.filter_status = JSON.parse(filter_status);
    }
    let filter_company = localStorage.getItem('filter_company');
    if(filter_company != null){
      this.filter_company = JSON.parse(filter_company);
    }
    let page_limit = localStorage.getItem('page_limit');
    if(page_limit != null){
      this.page_limit = page_limit;
    }

    let page_index = localStorage.getItem('page_index');
    if(page_index != null){
      this.page_index = page_index;
    }

    document.body.classList.remove('bg-img');
  }

  dateChange(event){
    // console.log(event);
    this.filter_date_from_val = moment(this.filter_date_from).format('YYYY-MM-DD');
    // console.log(this.filter_date_from_val);
    this.filter_date_to_val = moment(this.filter_date_to).format('YYYY-MM-DD');
    // console.log(this.filter_date_to_val);
    this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
  }

  addNewOrder(){
    let linn_id = ''+this.new_order.linn_number+'';
    if(this.new_order.order_from == null || this.new_order.order_from == ''){
      this.snackBar.open('Proszę uzupełnić pole "Order from"', 'OK', {
        duration: 2000,
      });
    }else if(this.new_order.linn_number == null || this.new_order.linn_number == ''){
      this.snackBar.open('Proszę uzupełnić pole "Linn number"', 'OK', {
        duration: 2000,
      });
    }else if(this.new_order.type == null || this.new_order.type == ''){
      this.snackBar.open('Proszę uzupełnić pole "Type"', 'OK', {
        duration: 2000,
      });
    }else if(this.new_order.collect_from == null || this.new_order.collect_from == ''){
      this.snackBar.open('Proszę uzupełnić pole "Collect from"', 'OK', {
        duration: 2000,
      });
    }else{
      let order_date = moment(this.new_order.order_date).format('YYYY-MM-DD');
      if(this.new_order.payment_method == 1){
        this.new_order.cash = 1;
      }else if(this.new_order.payment_method == 2){
        this.new_order.test = 1;
      }else if(this.new_order.payment_method == 3){
        this.new_order.rent = 1;
      }
      this.order_api = this.api.add_order(this.api_token, order_date, this.new_order.order_from, this.new_order.linn_number, this.new_order.source, this.new_order.channel_reference, this.new_order.client, this.new_order.delivery_address_line_1, this.new_order.delivery_address_line_2, this.new_order.delivery_postcode, this.new_order.delivery_city, this.new_order.delivery_country, this.new_order.client_phone, this.new_order.client_email, this.new_order.scooter_model, this.new_order.type, this.new_order.reason, this.new_order.warehouse_location, this.new_order.collect_from, this.new_order.price, this.new_order.currency, this.new_order.cash, this.new_order.rent, this.new_order.test);
      this.order_api.subscribe(data => {
        // console.log(data);
        if(data.status == true){
          this.form_opened = 0;
          this.new_order.order_date = '';
          this.new_order.order_from = '';
          this.new_order.linn_number = '';
          this.new_order.source = '';
          this.new_order.channel_reference = '';
          this.new_order.client = '';
          this.new_order.delivery_address_line_1 = '';
          this.new_order.delivery_address_line_2 = '';
          this.new_order.delivery_postcode = '';
          this.new_order.delivery_city = '';
          this.new_order.delivery_country = '';
          this.new_order.client_phone = '';
          this.new_order.client_email = '';
          this.new_order.scooter_model = '';
          this.new_order.reason = '';
          this.new_order.type = '';
          this.new_order.collect_from = '';
          this.new_order.price = '';
          this.new_order.currency = '';
          this.new_order.cash = 0;
          this.new_order.rent = 0;
          this.new_order.test = 0;
          this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
        }else{
          this.snackBar.open(data.message, 'OK', {
            duration: 2000,
          });
        }
      }, err => {
        this.snackBar.open(err.message, 'OK', {
          duration: 2000,
        });
      });
    }
  }

  change_is_active(){
    this.is_active = !this.is_active;
    this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
    // console.log(this.is_active);
  }

  showAllModels(all_btn){
    if(all_btn == 1){
      this.filter_model = [];
    }else{
      this.filter_model = [];
      for(let model of this.models){
        this.filter_model.push(model.model);
      }
    }
    localStorage.setItem('filter_model', JSON.stringify(this.filter_model));
    this.getOrders(0, this.page_limit, this.filter_column, this.filter_direction);
  }

  showAllCountries(all_btn){
    if(all_btn == 1){
      this.filter_country = [];
    }else if(all_btn == 3){
      this.filter_country = [];
      for(let country of this.countries){
        if(country != 'United Kingdom'){
          this.filter_country.push(country);
        }
      }
    }else{
      this.filter_country = [];
      for(let country of this.countries){
        this.filter_country.push(country);
      }
    }
    localStorage.setItem('filter_country', JSON.stringify(this.filter_country));
    this.getOrders(0, this.page_limit, this.filter_column, this.filter_direction);
  }

  onKeydown(event) {
    if (event.keyCode === 13 || event.keyCode === 'Enter') {
      this.getOrders(0, this.page_limit, this.filter_column, this.filter_direction);
    }
  }

  onTabChanged(event){
    console.log(event.index);
    this.showAllCountries(1);
    this.tab_index = event.index;
    //Orders
    if(event.index == 0){
      this.showAllCountries(2);
      let filter_status = localStorage.getItem('filter_status');
      if(filter_status != null){
        this.filter_status = JSON.parse(filter_status);
      }else{
        this.filter_status = ["0", "1"];
      }
      this.filter_driver = [];
      this.filter_payment = null;
      this.filter_refund = null;
      this.filter_inspection = null;
      this.lunex_invoice_payment_status = null;
      this.filter_company = ["1", "2", "3", "4"];
      this.lunex_invocies = null;
      localStorage.setItem('set_tab', '0');
      setTimeout(()=>{
        this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
      }, 500);
    //Services
    }else if(event.index == 1){
      this.showAllCountries(2);
      let filter_status_services = localStorage.getItem('filter_status_services');
      if(filter_status_services != null){
        this.filter_status = JSON.parse(filter_status_services);
      }else{
        this.filter_status = ["5"];
      }
      this.filter_driver = [];
      this.filter_payment = null;
      this.filter_refund = null;
      this.filter_inspection = null;
      this.lunex_invoice_payment_status = null;
      this.filter_company = ["1", "2", "3", "4"];
      this.lunex_invocies = null;
      localStorage.setItem('set_tab', '1');
      setTimeout(()=>{
        this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
      }, 1000);
    }
    //Cash payments
    else if(event.index == 2){
      this.showAllCountries(2);
      this.filter_status = ["0", "1", "2", "3", "4"];
      this.filter_driver = [];
      this.filter_payment = "0";
      this.filter_refund = null;
      this.filter_inspection = null;
      this.lunex_invoice_payment_status = null;
      this.filter_company = ["1", "2", "3", "4"];
      this.lunex_invocies = null;
      localStorage.setItem('set_tab', '2');
      setTimeout(()=>{
        this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
      }, 500);
    }
    // Refunds
    else if(event.index == 3){
      this.showAllCountries(2);
      this.filter_status = ["6"];
      this.filter_driver = [];
      this.filter_payment = null;
      this.filter_refund = "0";
      this.filter_inspection = null;
      this.lunex_invoice_payment_status = null;
      this.filter_company = ["1", "2", "3", "4"];
      this.lunex_invocies = null;
      localStorage.setItem('set_tab', '3');
      setTimeout(()=>{
        this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
      }, 500);
    }
    //Awaiting-inspection
    else if(event.index == 4){
      this.showAllCountries(2);
      this.filter_status = ["4"];
      this.filter_driver = [];
      this.filter_payment = null;
      this.filter_refund = null;
      this.filter_inspection = 'all';
      this.filter_company = ["1", "2", "3", "4"];
      this.lunex_invoice_payment_status = null;
      this.lunex_invocies = null;
      localStorage.setItem('set_tab', '4');
      setTimeout(()=>{
        this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
      }, 500);
    }
    //Lunex invoices
    else if(event.index == 5){
      this.showAllCountries(3);
      this.filter_status = ["4"];
      this.filter_driver = [];
      this.filter_payment = null;
      this.filter_refund = null;
      this.filter_inspection = null;
      this.filter_company = ["1"];
      this.lunex_invoice_payment_status = "0";
      this.lunex_invocies = true;
      localStorage.setItem('set_tab', '5');
      setTimeout(()=>{
        this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
      }, 500);
    }
  }

  getDrivers(){
    this.orders_api = this.api.get_drivers(this.api_token, 3);
    this.orders_api.subscribe(data => {
      this.drivers = data.data;
      console.log(data);
    }, err => {
      console.log(err);
    });
  }

  clearDates(){
    this.filter_date_from = null;
    this.filter_date_from_val = null;
    this.filter_date_to = null;
    this.filter_date_to_val = null;

    this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
  }

  getOrders(index, limit, column, direction){
    const ELEMENT_DATA: PeriodicElement[] = [];
    this.loading=0;
    console.log(this.filter_inspection);
    this.orders_api = this.api.getOrders(this.api_token, index, limit, this.filter_status, column, direction, this.filter_country, this.filter_model, this.filter_company, this.filter_keyword, this.is_active, this.filter_driver, this.filter_payment, this.filter_refund, this.is_assembled, this.filter_inspection, this.lunex_invocies, this.lunex_invoice_payment_status, this.filter_date_from_val, this.filter_date_to_val);
    this.orders_api.subscribe(data => {
      this.orders = data.data;
      console.log(data);
      for (let order of data.data) {
        if(order.parent_linn_id!=undefined&&this.tab_index==0) {
          continue;
        }
        let scooter_color = order.scooter_model.color;
        let color_class = '';
        if(scooter_color == 'Black'){color_class = 'scooter_black';}
        else if(scooter_color == 'Silver'){color_class = 'scooter_silver';}
        else if(scooter_color == 'White'){color_class = 'scooter_white';}
        else if(scooter_color == 'Red'){color_class = 'scooter_red';}
        else if(scooter_color == 'Blue'){color_class = 'scooter_blue';}
        else if(scooter_color == 'Gray'){color_class = 'scooter_gray';}
        else{color_class = 'scooter_color_unknow';}

        let source_number = null;
        if(order.source != null){
          source_number = order.source.number;
        }else{
          source_number = null;
        }

        ELEMENT_DATA.push({order_date: order.order_date,
                          cancelled_date: order.cancelled_date,
                          client: order.client,
                          client_phone: order.client_phone,
                          client_email: order.client_email,
                          linn_id: order.linn_id,
                          parent_linn_id: order.parent_linn_id,
                          delivery_postcode: order.delivery_postcode,
                          delivery_country: order.delivery_country,
                          scooter_model: order.scooter_model,
                          color_class: color_class,
                          order_from: order.order_from,
                          order_from_details: order.order_from_details,
                          source: source_number,
                          warehouse_location: order.warehouse_location,
                          collect_point: order.collect_point,
                          delivery_address_line_1: order.delivery_address_line_1,
                          delivery_address_line_2: order.delivery_address_line_2,
                          delivery_city: order.delivery_city,
                          price: order.price,
                          currency: order.currency,
                          type: order.type,
                          warehouse_comment: order.warehouse_comment,
                          is_active: order.is_active,
                          cash: order.cash,
                          rent: order.rent,
                          test: order.test,
                          qr_code: order.qr_code,
                          collect_from: order.collect_from,
                          order_status: order.order_status,
                          documents_status: order.documents_status,
                          cmr_status: order.cmr_status,
                          linn_note: order.linn_note,
                          is_warning: order.is_warning,
                          is_assembled: order.is_assembled,
                          vin: order.vin,
                          responsible_for_assemble: order.responsible_for_assemble,
                          driver: order.driver,
                          payment_comment: order.payment_comment,
                          months_from_inspection: order.months_from_inspection,
                          invoice_payment_date:
                          order.invoice_payment_date,
                          invoice_payment_comment: order.invoice_payment_comment,
                          important: order.important,
                          risk_negative: order.this_risk_of_receiving_negative_feedback,
                          b2b: order.b2b
        });
      }

      this.dataSource = ELEMENT_DATA;
      console.log("dataSource: ");
      console.log(this.dataSource);
      this.orderCount = data.count;
      this.loading=1;
    }, err => {
      this.loading=1;
      console.log(err);
    });
  }

  getScooterModels(){
    this.orders_api = this.api.getScooterModels(this.api_token);
    this.orders_api.subscribe(data => {
      this.models = data.data;
      let filter_model = localStorage.getItem('filter_model');
      if(filter_model != null){
        this.filter_model = JSON.parse(filter_model);
      }else{
        for(let model of this.models){
          this.filter_model.push(model.model);
        }
      }
      this.getOrderCountries();
    }, err => {
      console.log(err);
    });
  }

  getOrderCountries(){
    this.orders_api = this.api.getOrderCountries(this.api_token);
    this.orders_api.subscribe(data => {
      this.countries = data.data;
      //IF FILTER SAVING
      let filter_country = localStorage.getItem('filter_country');
      console.log(filter_country);
      if(filter_country != null){
        this.filter_country = JSON.parse(filter_country);
      }else{
        for(let country of this.countries){
          this.filter_country.push(country);
        }
      }
      let set_tab = localStorage.getItem('set_tab');
      if(set_tab != null){
        if(set_tab == '0'){
          this.selectedIndex = 0;
          let filter_status = localStorage.getItem('filter_status');
          if(filter_status != null){
            this.filter_status = JSON.parse(filter_status);
          }else{
            this.filter_status = ["0", "1"];
          }
          this.filter_driver = [];
          this.filter_payment = null;
          this.filter_refund = null;
          this.filter_inspection = null;
          this.lunex_invoice_payment_status = null;
          this.lunex_invocies = null;
          this.filter_company = ["1", "2", "3", "4"];
        }else if(set_tab == '1'){
          this.selectedIndex = 1;
          this.filter_status = ["5"];
          this.filter_driver = [];
          this.filter_payment = null;
          this.filter_refund = null;
          this.filter_inspection = null;
          this.lunex_invoice_payment_status = null;
          this.lunex_invocies = null;
          this.filter_company = ["1", "2", "3"];
        }else if(set_tab == '2'){
          this.selectedIndex = 2;
          this.filter_status = ["0", "1", "2", "3", "4"];
          this.filter_driver = [];
          this.filter_payment = "0";
          this.filter_refund = null;
          this.filter_inspection = null;
          this.lunex_invoice_payment_status = null;
          this.lunex_invocies = null;
          this.filter_company = ["1", "2", "3", "4"];
        }else if(set_tab == '3'){
          this.selectedIndex = 3;
          this.filter_status = ["5"];
          this.filter_driver = [];
          this.filter_payment = null;
          this.filter_refund = "0";
          this.filter_inspection = null;
          this.lunex_invoice_payment_status = null;
          this.lunex_invocies = null;
          this.filter_company = ["1", "2", "3", "4"];
        }else if(set_tab == '4'){
          this.selectedIndex = 4;
          this.filter_status = ["4"];
          this.filter_driver = [];
          this.filter_payment = null;
          this.filter_refund = null;
          this.filter_inspection = '4';
          this.lunex_invoice_payment_status = null;
          this.lunex_invocies = null;
          this.filter_company = ["1", "2", "3", "4"];
        }else if(set_tab == '5'){
          this.filter_status = ["4"];
          this.selectedIndex = 5;
          this.filter_driver = [];
          this.filter_payment = 0;
          this.filter_refund = null;
          this.filter_inspection = null;
          this.filter_company = ["1"];
          this.lunex_invoice_payment_status = "0";
          this.lunex_invocies = true;
          this.filter_company = ["1"];
          console.log(this.filter_company);
        }
      }
      this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
    }, err => {
      console.log(err);
    });
  }

  getServerData(event){
    console.log(event);
    this.page_index = event.pageIndex;
    this.page_limit = event.pageSize;
    localStorage.setItem('page_index', JSON.stringify(event.pageIndex));
    localStorage.setItem('page_limit', JSON.stringify(event.pageSize));
    this.getOrders(event.pageIndex, event.pageSize, this.filter_column, this.filter_direction);
  }

  filterStatus(event, set_cookies){
    console.log(event);
    this.filter_status = event.value;
    if(set_cookies == true){
      localStorage.setItem('filter_status', JSON.stringify(event.value));
    }else if(set_cookies == false){
      localStorage.setItem('filter_status_services', JSON.stringify(event.value));
    }
    this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
  }

  filterCountry(event){
    console.log(event);
    this.filter_country = event.value;
    console.log(event.value);
    localStorage.setItem('filter_country', JSON.stringify(event.value));
    this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
  }

  filterModel(event){
    console.log(event);
    this.filter_model = event.value;
    localStorage.setItem('filter_model', JSON.stringify(event.value));
    this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
  }

  filterCompany(event){
    console.log(event.value);
    this.filter_company = event.value;
    localStorage.setItem('filter_company', JSON.stringify(event.value));
    this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
  }

  filterDriver(event){
    console.log(event.value);
    this.filter_driver = event.value;
    localStorage.setItem('filter_driver', JSON.stringify(event.value));
    this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
  }

  filterPayment(event){
    console.log(event.value);
    this.filter_payment = event.value;
    localStorage.setItem('filter_payment', JSON.stringify(event.value));
    this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
  }

  filterLunexInvoicePaymentStatus(event){
    console.log(event.value);
    this.lunex_invoice_payment_status = event.value;
    localStorage.setItem('lunex_invoice_payment_status', JSON.stringify(event.value));
    this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
  }

  filterRefund(event){
    console.log(event.value);
    this.filter_refund = event.value;
    localStorage.setItem('filter_refund', JSON.stringify(event.value));
    this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
  }

  filterInspection(event){
    console.log(event.value);
    this.filter_inspection = event.value;
    console.log(this.filter_inspection);
    localStorage.setItem('filter_inspection', JSON.stringify(event.value));
    this.getOrders(this.page_index, this.page_limit, this.filter_column, this.filter_direction);
  }

  openForm(){
    this.form_opened = 1;
  }

  closeForm(){
    this.form_opened = 0;
  }

  copyValue(value){
    this.snackBar.open('Copied to clipboard', 'OK', {
      duration: 2000,
    });
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  darkMode(){
    this.darkmode = !this.darkmode;
    if(this.darkmode == true){
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('dark_mode');
    }else{
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('dark_mode');
    }
  }

  printDocument(type, order){
    this._bottomSheet.open(BottomSheetCollectPoint, {
      data: {
        type: type,
        order: order
      }
    });
  }

  inspectionStatus(linn_id, status){
    const dialogRef =  this.dialog.open(BottomSheetInspectionStatus, {
      data: {
        linn_id: linn_id,
        status: status
      }
    });
  }

  paid(linn_id){
    const dialogRef =  this.dialog.open(BottomSheetConfirmPaid, {
      data: {
        linn_id: linn_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getOrders(0, 200, 'order_date', 'asc');
    });
  }

  lunex_invoice_paid(linn_id){
    const dialogRef =  this.dialog.open(BottomSheetConfirmPaidLunexInvoice, {
      data: {
        linn_id: linn_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getOrders(0, 200, 'order_date', 'asc');
    });
  }

  refund(linn_id){
    const dialogRef =  this.dialog.open(BottomSheetConfirmRefund, {
      data: {
        linn_id: linn_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getOrders(0, 200, 'order_date', 'asc');
    });
  }


  exportExcel(active_orders){
    if (active_orders==1) {
      this.create_excel = 1;
    } else {
      this.create_excel = 2;
    }
    this.orders_api = this.api.getOrders(this.api_token,
                                         0,
                                         100000,
                                         this.filter_status,
                                         this.filter_column,
                                         this.filter_direction,
                                         this.filter_country,
                                         this.filter_model,
                                         this.filter_company,
                                         this.filter_keyword,
                                         active_orders,
                                         this.filter_driver,
                                         this.filter_payment,
                                         this.filter_refund,
                                         this.is_assembled,
                                         this.filter_inspection,
                                         this.lunex_invocies,
                                         this.lunex_invoice_payment_status,
                                         this.filter_date_from_val,
                                         this.filter_date_to_val);
    this.orders_api.subscribe(data => {
      console.log('EXPORTER');
      console.log(data);
      console.log('EXPORTER');
      let orders_data = [];
      for (let order of data.data) {
        let payment_method = 'Credit Card';
        if(order.cash == 1){
          payment_method = 'CASH ON DELIVERY';
        }else if(order.rent == 1){
          payment_method = 'RENTAL';
        }else if(order.test == 1){
          payment_method = 'TEST DRIVE';
        }
        let set_tab = localStorage.getItem('set_tab');
        // if (active_orders == 1) {
        //   if(set_tab == "5"){
        //     orders_data.push({order_date: order.order_date, client: order.client, client_phone: order.client_phone, linn_id: order.linn_id, parent_linn_id: order.parent_linn_id, delivery_postcode: order.delivery_postcode, delivery_country: order.delivery_country, scooter_model: order.scooter_model.name+' '+order.scooter_model.color, order_from: order.order_from, source: order.source.number, payment_method: payment_method, linn_note: order.linn_note, price: order.price, currency: order.currency, payment_date: order.invoice_payment_date, payment_comment: order.invoice_payment_comment, collect_point: order.collect_point.name});
        //   }else{
        //     orders_data.push({order_date: order.order_date, client: order.client, client_phone: order.client_phone, linn_id: order.linn_id, parent_linn_id: order.parent_linn_id, delivery_postcode: order.delivery_postcode, delivery_country: order.delivery_country, scooter_model: order.scooter_model.name+' '+order.scooter_model.color, order_from: order.order_from, source: order.source.number, payment_method: payment_method, linn_note: order.linn_note, collect_point: order.collect_point.name});
        //   }
        // } else {
        //   if(set_tab == "5"){
        //     orders_data.push({order_date: order.order_date, cancelled_date: order.cancelled_date, client: order.client, client_phone: order.client_phone, linn_id: order.linn_id, parent_linn_id: order.parent_linn_id, delivery_postcode: order.delivery_postcode, delivery_country: order.delivery_country, scooter_model: order.scooter_model.name+' '+order.scooter_model.color, order_from: order.order_from, source: order.source.number, payment_method: payment_method, linn_note: order.linn_note, price: order.price, currency: order.currency, payment_date: order.invoice_payment_date, payment_comment: order.invoice_payment_comment, collect_point: order.collect_point.name});
        //   }else{
        //     orders_data.push({order_date: order.order_date, cancelled_date: order.cancelled_date, client: order.client, client_phone: order.client_phone, linn_id: order.linn_id, parent_linn_id: order.parent_linn_id, delivery_postcode: order.delivery_postcode, delivery_country: order.delivery_country, scooter_model: order.scooter_model.name+' '+order.scooter_model.color, order_from: order.order_from, source: order.source.number, payment_method: payment_method, linn_note: order.linn_note, collect_point: order.collect_point.name});
        //   }
        // }

        if (active_orders == 1) {
          if(set_tab == "5"){
            orders_data.push({'Data zamówienia': order.order_date, 'Rok': this.getY(order.order_date), 'Miesiąc': this.getMo(order.order_date), 'Kwartał': this.getQu(order.order_date), 'Data dostawy': order.trackpack_status == 4 ? order.delivery_date  : 'Niedostarczono', 'Linn ID': order.linn_id, 'Nadrzędny Linn ID': order.parent_linn_id, 'Klient': order.client, 'Telefon': order.client_phone, 'Email': order.client_email, 'Kod pocztowy zamówienia': order.delivery_postcode, 'Miasto:': order.delivery_city, 'Adres dostawy (linia 1)': order.delivery_address_line_1, 'Adres dostawy (linia 2)': order.delivery_address_line_1, 'Kraj zamówienia': order.delivery_country, 'Nazwa modelu': order.scooter_model ? order.scooter_model.name : '', 'Nazwa koloru': order.scooter_model.color, 'Model skutera (pełna nazwa)': order.scooter_model ? order.scooter_model.name : ''+' '+order.scooter_model.color, 'Zamówione z:': order.order_from, 'Kod źródła': order.source.number, 'Nazwa źródła': order.source.string, 'Grupa źródła': this.getSourceGroup(order.source.number), 'Metoda płatności': payment_method, 'Cena': order.price, 'Waluta': order.currency, 'Data płatności': order.invoice_payment_date, 'Miejsce załadunku': order.collect_point ? order.collect_point.name : ''});
          }else{
            orders_data.push({'Data zamówienia': order.order_date, 'Rok': this.getY(order.order_date), 'Miesiąc': this.getMo(order.order_date), 'Kwartał': this.getQu(order.order_date), 'Data dostawy': order.trackpack_status == 4 ? order.delivery_date  : 'Niedostarczono', 'Linn ID': order.linn_id, 'Nadrzędny Linn ID': order.parent_linn_id, 'Klient': order.client, 'Telefon': order.client_phone, 'Email': order.client_email, 'Kod pocztowy zamówienia': order.delivery_postcode, 'Miasto:': order.delivery_city, 'Adres dostawy (linia 1)': order.delivery_address_line_1, 'Adres dostawy (linia 2)': order.delivery_address_line_1, 'Kraj zamówienia': order.delivery_country, 'Nazwa modelu': order.scooter_model ? order.scooter_model.name : '', 'Nazwa koloru': order.scooter_model.color, 'Model skutera (pełna nazwa)': order.scooter_model ? order.scooter_model.name : ''+' '+order.scooter_model.color, 'Zamówione z:': order.order_from, 'Kod źródła': order.source.number, 'Nazwa źródła': order.source.string, 'Grupa źródła': this.getSourceGroup(order.source.number), 'Metoda płatności': payment_method, collect_point: order.collect_point ? order.collect_point.name : '', 'Cena': order.price, 'Waluta': order.currency});
          }
        } else {
          if(set_tab == "5"){
            orders_data.push({'Data zamówienia': order.order_date, 'Rok': this.getY(order.order_date), 'Miesiąc': this.getMo(order.order_date), 'Kwartał': this.getQu(order.order_date), 'Data anulowania': order.cancelled_date, 'Linn ID': order.linn_id, 'Nadrzędny Linn ID': order.parent_linn_id, 'Klient': order.client, 'Telefon': order.client_phone, 'Email': order.client_email, 'Kod pocztowy zamówienia': order.delivery_postcode, 'Miasto:': order.delivery_city, 'Adres dostawy (linia 1)': order.delivery_address_line_1, 'Adres dostawy (linia 2)': order.delivery_address_line_1, 'Kraj zamówienia': order.delivery_country, 'Nazwa modelu': order.scooter_model ? order.scooter_model.name : '', 'Nazwa koloru': order.scooter_model.color, 'Model skutera (pełna nazwa)': order.scooter_model ? order.scooter_model.name : ''+' '+order.scooter_model.color, 'Zamówione z:': order.order_from, 'Kod źródła': order.source.number, 'Nazwa źródła': order.source.string, 'Grupa źródła': this.getSourceGroup(order.source.number), 'Metoda płatności': payment_method, 'Cena': order.price, 'Waluta': order.currency, 'Data płatności': order.invoice_payment_date, 'Miejsce załadunku': order.collect_point ? order.collect_point.name : ''});
          }else{
            orders_data.push({'Data zamówienia': order.order_date, 'Rok': this.getY(order.order_date), 'Miesiąc': this.getMo(order.order_date), 'Kwartał': this.getQu(order.order_date), 'Data anulowania': order.cancelled_date, 'Linn ID': order.linn_id, 'Nadrzędny Linn ID': order.parent_linn_id, 'Klient': order.client, 'Telefon': order.client_phone, 'Email': order.client_email, 'Kod pocztowy zamówienia': order.delivery_postcode, 'Miasto:': order.delivery_city, 'Adres dostawy (linia 1)': order.delivery_address_line_1, 'Adres dostawy (linia 2)': order.delivery_address_line_1, 'Kraj zamówienia': order.delivery_country, 'Nazwa modelu': order.scooter_model ? order.scooter_model.name : '', 'Nazwa koloru': order.scooter_model.color, 'Model skutera (pełna nazwa)': order.scooter_model ? order.scooter_model.name : ''+' '+order.scooter_model.color, 'Zamówione z:': order.order_from, 'Kod źródła': order.source.number, 'Nazwa źródła': order.source.string, 'Grupa źródła': this.getSourceGroup(order.source.number), 'Metoda płatności': payment_method, 'Miejsce załadunku': order.collect_point ? order.collect_point.name : '', 'Cena': order.price, 'Waluta': order.currency});
          }
        }

      }
      this.create_excel = 0;
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(orders_data);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      if (active_orders == 1) {
        XLSX.writeFile(wb, 'ScoreSheet.xlsx');
      } else {
        XLSX.writeFile(wb, 'CancelledScoreSheet.xlsx');
      }
    }, err => {
      console.log(err);
    });
  }

  getQu(date){
    let momentDate = moment(date, 'DD/MM/YYYY');
    let month = moment(momentDate).format('MM');
    let year = moment(momentDate).format('YY');
    let quater = 0;
    if(month == '01' || month == '02' || month == '03'){
      quater = 1;
    }else if(month == '04' || month == '05' || month == '06'){
      quater = 2;
    }else if(month == '07' || month == '08' || month == '09'){
      quater = 3;
    }else if(month == '10' || month == '11' || month == '12'){
      quater = 4;
    }
    let format = 'Q'+quater+'.'+year;
    return format;
  }

  getMo(date){
    let momentDate = moment(date, 'DD/MM/YYYY');
    let format = moment(momentDate).format('MM.YY');
    return format;
  }

  getY(date){
    let momentDate = moment(date, 'DD/MM/YYYY');
    let format = moment(momentDate).format('YYYY');
    return format;
  }

  getSourceGroup(source){
    let source_group = '';
    if(source == 1 || source == 2 || source == 3 || source == 17){
      source_group = 'EBAY Lunex';
    }else if(source == 11){
      source_group = 'EBAY Lauftech';
    }else if(source == 20){
      source_group = 'EBAY Tricovelo';
    }else if(source == 4 || source == 5 || source == 6 || source == 7 || source == 8){
      source_group = 'AMAZON Lunex';
    }else if(source == 9 || source == 12 || source == 13 || source == 14 || source == 15 || source == 32 || source == 39){
      source_group = 'AMAZON Lauftech';
    }else if(source == 21 || source == 22 || source == 23 || source == 24 || source == 25 || source == 26 || source == 27 || source == 28){
      source_group = 'AMAZON Tricovelo';
    }else if(source == 29){
      source_group = 'CDISCOUNT Lauftech';
    }else if(source == 31){
      source_group = 'CDISCOUNT Velobike';
    }else if(source == 16){
      source_group = 'Velobike Online Store';
    }else if(source == 18){
      source_group = 'Lauftech Online Store';
    }else if(source == 19 || source == 30){
      source_group = 'WWW Veleco (B2B / B2C)';
    }else if(source == 10){
      source_group = 'Directly & Other';
    }
    return source_group;
  }

  exportExcelWarehouse(){
    this.create_excel = 3;
    let models = [];
    for(let model of this.models){
      models.push(model.model);
    }
    this.orders_api = this.api.getOrders(this.api_token, 0, 10000, ["0", "1", "2", "3", "4"], this.filter_column, this.filter_direction, this.countries, models, ["1", "2", "3"], '', 1);
    this.orders_api.subscribe(data => {
      console.log(data);
      let orders_data = [];
      for (let order of data.data) {
        if(order.is_assembled){
          let status = order.trackpack_status;
          let status_var = "---";
          if(status == 0){
            status_var = "0/4 - Ordered";
          }else if(status == 1){
            status_var = "1/4 - In progress";
          }else if(status == 2){
            status_var = "2/4 - Ready to send";
          }else if(status == 3){
            status_var = "3/4 - Sent";
          }else if(status == 4){
            status_var = "4/4 - Delivered";
          }
          orders_data.push({assembled_date: order.assembled_date, linn_id: order.linn_id, parent_linn_id: order.parent_linn_id, scooter_model: order.scooter_model.name+' '+order.scooter_model.color, vin: order.vin, responsible: order.responsible_for_assemble, driver: order.driver, payment_comment: order.payment_comment, status: status_var});
        }
      }
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(orders_data);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'ScoreSheet.xlsx');
      this.create_excel = 0;
    }, err => {
      console.log(err);
    });
  }

  getParentLinnID(child_linn_id,ele){
    console.log(ele);
    console.log(child_linn_id);
    let last_characters = child_linn_id.substr(child_linn_id.length-3);
    console.log(last_characters);
    if(last_characters.charAt(0)!='c') {
      last_characters=child_linn_id.substr(child_linn_id.length-2);
      console.log(last_characters);
    }
    let parent_id =  child_linn_id.replace(last_characters,'');
    console.log(child_linn_id);
    console.log(parent_id);
    return parent_id;
  }
}

// @Component({
//   selector: 'bottom-sheet-collect_point',
//   template: `
//     <h2>Select collection point:</h2>
//     <div *ngIf="loading_collections == 0">
//       <mat-spinner diameter="30"></mat-spinner>
//     </div>
//     <mat-nav-list *ngIf="loading_collections==1">
//       <div *ngFor="let point of points">
//         <mat-list-item (click)="printDocument(type, order, [point.id], [point])">
//           <span mat-line>{{point.city}}</span>
//           <span mat-line>{{point.name}}</span>
//         </mat-list-item>
//       </div>
//     </mat-nav-list>
//   `,
// })
// export class BottomSheetCollectPoint {
//   type: any;
//   order: any;
//   orders_api: any;
//   points_api: any;
//   points: any;
//   loading_collections: any;
//   api_token: any;

//   constructor(public api: ApiCommunicationService, private _bottomSheetRef: MatBottomSheetRef<BottomSheetCollectPoint>, public dialog: MatDialog, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private changeDetectorRef: ChangeDetectorRef) {
//     this.type = data.type;
//     this.order = data.order;
//   }

//   ngOnInit() {
//     this.api_token = localStorage.getItem('api_token');
//     this.loading_collections = 0;
//     this.getCollectionPoints();
//   }

//   getCollectionPoints(){
//     this.points_api = this.api.getCollectPoints(this.api_token);
//     this.points_api.subscribe(data => {
//       this.points = data.data;
//       console.log(this.points);
//       this.loading_collections = 1;
//       console.log(this.loading_collections);
//       this.changeDetectorRef.detectChanges();
//     }, err => {
//       console.log(err);
//     });
//   }

//   printDocument(type, order, collection, point){
//     //Order type:
//     // 1 - Transport order
//     // 2 - Dropshipping

//     //Collection:
//     // 1 - Lunex Technology LTD.
//     // 2 - VELECO Sp. z o.o.
//     // 3 - TRICOVELOS S.L.

//     console.log('ORDER:');
//     console.log(order);
//     let order_details = order;
//     console.log("No edit:");
//     console.log(order_details);
//     order_details.collect_point = point[0];
//     order_details.collect_from = collection;
//     if (collection == 3) {
//       if (order_details.order_from == 'Tricovelo') {
//         order_details.type = {number:1, string: "Transpor order"};
//       } else {
//         order_details.type = {number: 2, string: "Dropshipping"};
//       }
//     } else {
//       if(collection == 1){
//         order_details.type = {number: 1, string: "Transport order"};
//       }else{
//         order_details.type = {number: 2, string: "Dropshipping"};
//       }
//     }
//     this.updateOrder(collection, order_details.type.number[0]); //<- 0 bo to pierwszy element w tablicy number czyli 1 albo 2
//     console.log("Edit:");
//     console.log(order_details);
//     console.log(point);
//     this._bottomSheetRef.dismiss();
//     this.dialog.open(DocumentsComponent, {
//       data: {
//         type: type,
//         order: order_details
//       }
//     });
//   }

//   updateOrder(collect_point, type){
//     this.orders_api = this.api.update_collection_point(this.api_token, this.order.linn_id, collect_point, type);
//     this.orders_api.subscribe(data => {
//       console.log('updated');
//     }, err => {
//       console.log(err);
//     });
//   }
// }

@Component({
  selector: 'bottom-sheet-confirm_paid',
  template: `
    <h2>Confirm:</h2>
    <div mat-dialog-content>
      <mat-spinner diameter="30" *ngIf="loading == 0" style="display: block; margin: 0 auto;"></mat-spinner>
      <div *ngIf="loading == 1">
        Are you sure you want to change the status of the order to paid?
        <div style="margin-top: 20px;">
          <mat-form-field style="width: 100%;">
            <mat-label>Note</mat-label>
            <textarea matInput [(ngModel)]="note" style="height: 100px;"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div mat-dialog-actions *ngIf="loading == 1">
      <button mat-button cdkFocusInitial (click)="dialogClose()">Cancel</button>
      <button mat-raised-button color="primary" (click)="confirm()">Confirm</button>
    </div>
  `,
})
export class BottomSheetConfirmPaid {
  linn_id: any;
  api_token: any;
  orders_api: any;
  loading: any = 1;
  textarea: any;
  note: any;

  constructor(public dialogRef: MatDialogRef<BottomSheetConfirmPaid>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data, private snackBar: MatSnackBar){
    this.linn_id = data.linn_id;
  }

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.loading = 1;
  }

  confirm(){
    this.loading = 0;
    this.orders_api = this.api.paid(this.api_token, this.linn_id, this.note);
    this.orders_api.subscribe(data => {
      console.log('updated');
      this.loading = 1;
      this.dialogClose();
    }, err => {
      console.log(err);
      this.loading = 1;
    });
  }

  dialogClose(){
    this.dialogRef.close();
  }
}

@Component({
  selector: 'bottom-sheet-confirm_paid',
  template: `
    <h2>Confirm:</h2>
    <div mat-dialog-content>
      <mat-spinner diameter="30" *ngIf="loading == 0" style="display: block; margin: 0 auto;"></mat-spinner>
      <div *ngIf="loading == 1">
        Are you sure you want to change the status of the order to paid? LUNEX INVOICE
        <div style="margin-top: 20px;">
          <mat-form-field appearance="fill">
            <mat-label>Choose a date</mat-label>
            <input matInput [(ngModel)]="lunex_invoice_date" [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field style="width: 100%;">
            <mat-label>Note</mat-label>
            <textarea matInput [(ngModel)]="lunex_note" style="height: 100px;"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div mat-dialog-actions *ngIf="loading == 1">
      <button mat-button cdkFocusInitial (click)="dialogClose()">Cancel</button>
      <button mat-raised-button color="primary" (click)="confirm()">Confirm</button>
    </div>
  `,
})
export class BottomSheetConfirmPaidLunexInvoice {
  linn_id: any;
  api_token: any;
  orders_api: any;
  loading: any = 1;
  textarea: any;
  lunex_invoice_date: any;
  lunex_note: any;

  constructor(public dialogRef: MatDialogRef<BottomSheetConfirmPaid>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data, private snackBar: MatSnackBar){
    this.linn_id = data.linn_id;
  }

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.loading = 1;
  }

  confirm(){
    this.loading = 0;
    let date = moment(this.lunex_invoice_date).format('YYYY-MM-DD');
    this.orders_api = this.api.change_invoice_payment_status(this.api_token, this.linn_id, date, this.lunex_note);
    this.orders_api.subscribe(data => {
      console.log('updated');
      this.loading = 1;
      this.dialogClose();
    }, err => {
      console.log(err);
      this.loading = 1;
    });
  }

  dialogClose(){
    this.dialogRef.close();
  }
}

@Component({
  selector: 'bottom-sheet-inspection-status',
  template: `
    <h2 *ngIf="status == 'create'">Confirm service:</h2>
    <h2 *ngIf="status == 'delay'">Cancel service:</h2>
    <div mat-dialog-content>
      <mat-spinner diameter="30" *ngIf="loading == 0" style="display: block; margin: 0 auto;"></mat-spinner>
      <div *ngIf="loading == 1">
        <span *ngIf="status == 'create'">Are you sure you want to confirm service?</span>
        <span *ngIf="status == 'delay'">Are you sure you want to cancel service?</span>
        <div style="margin-top: 20px;">
          <mat-form-field style="width: 100%;">
            <mat-label>Note</mat-label>
            <textarea matInput [(ngModel)]="note" style="height: 100px;"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div mat-dialog-actions *ngIf="loading == 1">
      <button mat-button cdkFocusInitial (click)="dialogClose()">Cancel</button>
      <button mat-raised-button color="primary" (click)="confirm()">Confirm</button>
    </div>
  `,
})
export class BottomSheetInspectionStatus {
  linn_id: any;
  api_token: any;
  orders_api: any;
  loading: any = 1;
  textarea: any;
  note: any;
  status: any;

  constructor(public dialogRef: MatDialogRef<BottomSheetInspectionStatus>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data, private snackBar: MatSnackBar){
    this.linn_id = data.linn_id;
    this.status = data.status;
  }

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.loading = 1;
  }

  confirm(){
    this.loading = 0;
    this.orders_api = this.api.add_order_service(this.api_token, this.linn_id, this.note, '6', this.status);
    this.orders_api.subscribe(data => {
      console.log(data);
      console.log('updated');
      this.loading = 1;
      this.dialogClose();
    }, err => {
      console.log(err);
      this.loading = 1;
    });
  }

  dialogClose(){
    this.dialogRef.close();
  }
}


@Component({
  selector: 'bottom-sheet-confirm_refubd',
  template: `
    <h2>Confirm:</h2>
    <div mat-dialog-content>
      <mat-spinner diameter="30" *ngIf="loading == 0" style="display: block; margin: 0 auto;"></mat-spinner>
      <div *ngIf="loading == 1">
        Are you sure you want to change the status of the order to refunded?
        <div style="margin-top: 20px;">
          <mat-form-field style="width: 100%;">
            <mat-label>Note</mat-label>
            <textarea matInput [(ngModel)]="note" style="height: 100px;"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div mat-dialog-actions *ngIf="loading == 1">
      <button mat-button cdkFocusInitial (click)="dialogClose()">Cancel</button>
      <button mat-raised-button color="primary" (click)="confirm()">Confirm</button>
    </div>
  `,
})
export class BottomSheetConfirmRefund {
  linn_id: any;
  api_token: any;
  orders_api: any;
  loading: any = 1;
  textarea: any;
  note: any;

  constructor(public dialogRef: MatDialogRef<BottomSheetConfirmRefund>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data, private snackBar: MatSnackBar){
    this.linn_id = data.linn_id;
  }

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.loading = 1;
  }

  confirm(){
    this.loading = 0;
    this.orders_api = this.api.refund(this.api_token, this.linn_id, this.note);
    this.orders_api.subscribe(data => {
      console.log('updated');
      this.loading = 1;
      this.dialogClose();
    }, err => {
      console.log(err);
      this.loading = 1;
    });
  }

  dialogClose(){
    this.dialogRef.close();
  }
}
