import { Component, OnInit, ViewChild, ElementRef, Inject, ChangeDetectorRef } from '@angular/core';
import { ApiCommunicationService } from '../api-communication.service';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MapdialogComponent } from '../mapdialog/mapdialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { DocumentsComponent } from '../documents/documents.component';
import { CdkDragDrop, CdkDragExit, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Location } from "@angular/common";
import * as moment from 'moment';
import { BottomSheetCollectPoint } from '../bottom-sheet-collect-point/bottom-sheet-collect-point.component';
import { unwatchFile } from 'fs';

declare var H: any;

@Component({
  selector: 'app-organize',
  templateUrl: './organize.component.html',
  styleUrls: ['./organize.component.scss']
})
export class OrganizeComponent implements OnInit {

  @ViewChild("map", {static: false}) private mapElement: any;

  public lat: any = '50.808072';
  public lng: any = '19.114494';
  public width: any = '1000px';
  public height: any = '600px';
  private platform: any;
  private map: any;
  private _appId: string = 'Z83pTwvQpuqEMnUovK48';
  private _appCode: string = 'mhr2673oGBCx608FShYnvhwIUmAQR_H9izimsk2NO_M';
  ui: any;
  markers_count: any = 0;

  loading: any;
  orders_api: any;
  orders: any;
  routes_api: any;
  routes: any;
  geo_api: any;
  user_name: any;

  orders_map_url: any = 'Neiße-Malxetal, 03159, Germany/Guben, 03172, Germany/Berlin, 14165, Germany/Hamburg, 22111, Germany/Berlikum, 9041AR, Netherlands/Hamm, 59077, Germany/Wachstedt, 37359, Germany/';

  todo = [];

  done = [];

  done2 = [];

  done3 = [];

  boxes = [{
    name: 'box1',
    items: []
  }];

  send_email: any = true;

  route_orders = [];
  routes_loader: any = 0;

  order_filter: any = "0";
  api_token: any;
  avatar: any = '';

  route_details_id: any = null;

  selectedMarker: any = null;
  selectedMarkerOrderExist: any = false;

  mapInit: any = 0;
  mapElasticUrl: any;

  getTimePoints: any = [];
  getTimePointsLoading: any = [];
  routeTimes: any = [];

  dateTmp: any = [];
  lastIssetOrderRouteDate: any = [];


  models: any = [];
  countries: any = [];

  filter_model: any = [];
  filter_country: any = [];
  filter_company: any = ["1", "2", "3", "4"];
  filter_status: any = ["0", "1", "2", "3"];
  filter_keyword: any;

  show_archive: any = 0;
  is_services: number = 0;

  amazon_sources: any = [4,5,6,7,8,33,34,35,9,12,13,14,15,32,36,37,21,22,23,24,25,27,28,39];
  ebay_sources: any = [1,2,3,17,11,20];
  web_sources: any = [16,18,19,30,38];
  other_sourcs: any = [29,31,10];

  archive(show){
    this.show_archive = show;
    if(this.show_archive == 1){
      this.getRoutes(true);
    }else{
      this.getRoutes();
    }
  }

  archiveRoute(id){
    this.orders_api = this.api.moveRouteToArchive(this.api_token, id);
    this.orders_api.subscribe(data => {
      this.getRoutes();
    }, err => {
      console.log(err);
    });
  }

  deleteRoute(id){
    this.orders_api = this.api.deleteRoute(this.api_token, id);
    this.orders_api.subscribe(data => {
      if(this.show_archive == 0){
        this.getRoutes();
      }else{
        this.getRoutes(true);
      }
    }, err => {
      console.log(err);
    });
  }

  drop(event: CdkDragDrop<string[]>, orders, i) {
    //console.log(this.getTimePointsLoading['route_'+i]);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }

    let current_index = event.currentIndex;
    let order_id = event.item.element.nativeElement.id.split('_')[1];
    let route_id = event.container.id.split('_')[1];

    if(route_id == 'null'){
      route_id = null;
    }

    if(orders != null){
      console.log('ORDERS NOT NULL');
      this.getTimes(orders, i);
    }else{
      console.log('ORDERS NULL');
    }

    this.saveChanges(current_index, order_id, route_id);
  }

  exit(event: CdkDragExit<string[]>, orders, i) {
    this.getTimes(orders, i);
  }

  constructor(public api: ApiCommunicationService, public dialog: MatDialog, private snackBar: MatSnackBar, location: Location) {
    // console.log("ORGANIZE ROUTE:");
    let route = location.path().split("?", 2)[0];
    if (route == '/services') {
      this.is_services = 1;
    }
    // console.log(route);
  }

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.user_name = localStorage.getItem('user_name');
    this.avatar = localStorage.getItem('avatar');
    this.getRoutes();
    this.platform = new H.service.Platform({
      "app_id": this._appId,
      "app_code": this._appCode,
      "useHTTPS": true
    });
    this.getScooterModels();
  }

  ngAfterViewInit() {

  }

  getOrders(index, limit, column, direction, tab){
    let hasRoute = null;
    let countries = [];
    let model = [];
    let company = [];
    if(tab == 'map'){
      hasRoute = null;
      countries = this.countries;
      model = [];
      for(let mdl of this.models){
        model.push(mdl.model);
      }
      company = ["1", "2", "3", "4"];
    }else{
      this.todo = [];
      this.loading=0;

      hasRoute = 'no';
      countries = this.filter_country;
      model = this.filter_model;
      company = this.filter_company;
    }

    this.orders_api = this.api.getOrders(this.api_token, index, limit, this.filter_status, column, direction, countries, model, company, this.filter_keyword, 1, null, null, null, null, null, null, null, null, null, hasRoute);
    this.orders_api.subscribe(data => {
      let i = 1;
      for (let order of data.data) {
        if (order.parent_linn_id != undefined) {
          continue;
        }
        if(tab == 'map'){
          setTimeout(()=>{
            this.getGeolocalization(order);
          }, i*200);
        }else if(tab == 'list'){
          this.getCountryCode(order);
        }
        this.dateTmp[order.id] = '';
        i++;
      }
      this.loading=1;
    }, err => {
      this.loading=1;
      console.log(err);
    });
  }

  getScooterModels(){
    this.orders_api = this.api.getScooterModels(this.api_token);
    this.orders_api.subscribe(data => {
      this.models = data.data;
      for(let model of this.models){
        this.filter_model.push(model.model);
      }
      this.getOrderCountries();
    }, err => {
      console.log(err);
    });
  }

  getOrderCountries(){
    this.orders_api = this.api.getOrderCountries(this.api_token);
    this.orders_api.subscribe(data => {
      this.countries = data.data;
      for(let country of this.countries){
        this.filter_country.push(country);
      }
      this.getOrders(0, 1000, 'order_date', 'desc', 'list');
    }, err => {
      console.log(err);
    });
  }

  filterCountry(event){
    this.filter_country = event.value;
    this.getOrders(0, 1000, 'order_date', 'desc', 'list');
  }

  filterModel(event){
    this.filter_model = event.value;
    this.getOrders(0, 1000, 'order_date', 'desc', 'list');
  }

  filterCompany(event){
    this.filter_company = event.value;
    this.getOrders(0, 1000, 'order_date', 'desc', 'list');
  }

  filterStatus(event){
    this.filter_status = event.value;
    this.getOrders(0, 1000, 'order_date', 'desc', 'list');
  }

  onKeydown(event) {
    if (event.keyCode === 13 || event.keyCode === 'Enter') {
      this.getOrders(0, 1000, 'order_date', 'desc', 'list');
    }
  }

  showAllModels(all_btn){
    if(all_btn == 1){
      this.filter_model = [];
    }else{
      this.filter_model = [];
      for(let model of this.models){
        this.filter_model.push(model.model);
      }
    }
    this.getOrders(0, 1000, 'order_date', 'desc', 'list');
  }

  showAllCountries(all_btn){
    if(all_btn == 1){
      this.filter_country = [];
    }else if(all_btn == 3){
      this.filter_country = [];
      for(let country of this.countries){
        if(country != 'United Kingdom'){
          this.filter_country.push(country);
        }
      }
    }else{
      this.filter_country = [];
      for(let country of this.countries){
        this.filter_country.push(country);
      }
    }
    this.getOrders(0, 1000, 'order_date', 'desc', 'list');
  }

  saveChanges(current_index, order_id, route_id){
    this.orders_api = this.api.set_order_route(this.api_token, current_index, order_id, route_id);
    this.orders_api.subscribe(data => {
    }, err => {
      console.log(err);
    });
  }

  onTabChanged($event) {
    let clickedIndex = $event.index;
    if(clickedIndex == 1 && this.mapInit == 0){

      this.getOrders(0, 1000, 'order_date', 'desc', 'map');

      this.mapInit = 1;
      let pixelRatio = window.devicePixelRatio || 1;
      let defaultLayers = this.platform.createDefaultLayers({
        tileSize: pixelRatio === 1 ? 256 : 512,
        ppi: pixelRatio === 1 ? undefined : 320
      });

      this.map = new H.Map(this.mapElement.nativeElement,
        defaultLayers.normal.map, { pixelRatio: pixelRatio });

      var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
      var ui = H.ui.UI.createDefault(this.map, defaultLayers);

      this.map.setCenter({ lat: this.lat, lng: this.lng });
      this.map.setZoom(4);

    }
  }

  getGeolocalization(order){
    if(order.trackpack_status == 0 || order.trackpack_status == 1){
      this.geo_api = this.api.getRoutesGeolocalization(order.delivery_city+', '+order.delivery_postcode+', '+order.delivery_country);
      // this.geo_api = this.api.getGeolocalization(postcode);
      this.geo_api.subscribe(data => {
        this.markers_count++;
        let lat = data['items'][0]['position']['lat'];
        let lng = data['items'][0]['position']['lng'];
        if(lat != null && lng != null){
          this.dropMarker({"lat": lat, "lng": lng}, {"postcode": order.delivery_postcode, "description": "Test", "status": order.trackpack_status, "model": order.model, "linn_id": order.linn_id});
        }
      }, err => {
        console.log(err);
      });
    }
  }

  getTimes(orders, i){
    this.getTimePoints[i] = [];
    this.getTimePointsLoading['route_'+i] = 0;
    let index = 0;
    for(let order of orders){
      this.getTimeApi(index, order, orders.length, i);
      index++;
    }
  }

  getTimeApi(index, order, length, i){
    this.geo_api = this.api.getGeolocalization(order.postcode+', '+order.city, order.street, order.postcode, order.city, order.country);
    this.geo_api.subscribe(data => {
      let lat = null;
      let lng = null;
      let cords = null;

      if(data['items'].length > 0){
        lat = data['items'][0]['position']['lat'];
        lng = data['items'][0]['position']['lng'];
        cords = lat+','+lng;
        //console.log('CORD '+index+' for '+i);
        //console.log(cords);

        if(lat != null && lng != null){
          this.getTimePoints[i][index] = {
            cords: cords,
            id: order.id
          };
        }else{
          this.getTimePoints[i][index] = {
            cords: null,
            id: null
          };
        }

      }else{
        this.getTimePoints[i][index] = {
          cords: null,
          id: null
        };
      }

      if((index+1) == length){
        setTimeout(()=>{
          this.getTimesInit(i);
          //console.log('MAP CORDS OBJ');
          //console.log(this.getTimePoints[i]);

          let map_cords = '';
          let cords_index = 1;
          for(let cord of this.getTimePoints[i]){
            //console.log(cords_index+'/'+this.getTimePoints[i].length);
            //console.log(map_cords);
            if(cords_index == this.getTimePoints[i].length){
              map_cords += cord.cords;
              this.mapElasticUrl = 'https://image.maps.ls.hereapi.com/mia/1.6/route?apiKey=MTqimHVzvW7xEWYD9waMgI5qPHcqgzF9n0v8GaTA2eg&r='+map_cords+'&m='+map_cords+'&lc=3f51b5&sc=3f51b5&lw=7&mlbl=0&mthm=1&w=600';
              //console.log(this.mapElasticUrl);
            }else{
              map_cords += cord.cords+',';
            }
            cords_index++;
          }

        },500);
      }
    }, err => {
      console.log(err);
      this.getTimePoints[i][index] = {
        cords: null,
        id: null
      };
      if((index+1) == length){
        setTimeout(()=>{
          this.getTimesInit(i);
        },500);
      }
    });
  }

  getTimesInit(i){
    this.routeTimes[i] = [];
    this.setTime(i, 0);
  }

  setRoutePointAverageTimeAndDistance(id, length, duration, date){
    this.orders_api = this.api.setRoutePointAverageTimeAndDistance(this.api_token, id, length, duration, date);
    this.orders_api.subscribe(data => {

    }, err => {
      console.log(err);
    });
  }

  setTime(i, index){
    let id = this.getTimePoints[i][index]['id'];
    if(index > 0){
      let origin = this.getTimePoints[i][index - 1]['cords'];
      let destination = this.getTimePoints[i][index]['cords'];

      if(origin != null && destination != null){

        this.geo_api = this.api.getMapTimes(origin, destination);
        this.geo_api.subscribe(data => {

        let length = 0;
        let duration = 0;

        for(let section of data.routes[0]['sections']){
          length += section['summary']['length'] / 1000;
          duration += section['summary']['duration'];
        }

        let route_date = this.dateTmp[id]['_d'];
        if(route_date != null){
          this.lastIssetOrderRouteDate[i] = moment(route_date).format('DD-MM-YYYY HH:mm');
        }else if(this.dateTmp[id] != null && this.dateTmp[id] != ''){
          let test: any = moment(this.dateTmp[id], 'YYYY-MM-DD HH:mm').toDate();
          this.lastIssetOrderRouteDate[i] = moment(test).format('DD-MM-YYYY HH:mm');
        }else{
          let test: any = moment(this.lastIssetOrderRouteDate[i], 'DD-MM-YYYY HH:mm').toDate();
          this.lastIssetOrderRouteDate[i] = moment(test).add(duration, 'seconds').add(1, 'hour').format('DD-MM-YYYY HH:mm');
        }

        this.routeTimes[i][index] = {
          length: length.toFixed(0),
          duration: this.secondsToHms(duration),
          date: this.lastIssetOrderRouteDate[i]
        };

        // this.route_orders[i]['orders'][index-1]['route_point_distance'] = length.toFixed(0);
        // this.route_orders[i]['orders'][index-1]['route_point_estimate_time'] = this.lastIssetOrderRouteDate[i];
        // this.route_orders[i]['orders'][index-1]['route_point_timespan'] = this.secondsToHms(duration);

        console.log('test:');
        console.log(this.route_orders);
        console.log(i);
        console.log(index);

        this.setRoutePointAverageTimeAndDistance(id, length.toFixed(0), this.secondsToHms(duration), this.lastIssetOrderRouteDate[i]);


        // console.log('ROUTE TIMES');
        // console.log(this.routeTimes);

        if((index+1) < this.getTimePoints[i].length){
          this.setTime(i, index+1);
        }

        //console.log((index+1)+' / '+this.getTimePoints[i].length);

        if((index+1) == this.getTimePoints[i].length){
          this.getTimePointsLoading['route_'+i] = 1;
          this.saveNewDriverAndDate(i);
        }

        }, err => {
          console.log(err);

          if((index+1) < this.getTimePoints[i].length){
            this.setTime(i, index+1);
          }

          if((index+1) == this.getTimePoints[i].length){
            this.getTimePointsLoading['route_'+i] = 1;
            this.saveNewDriverAndDate(i);
          }

        });

      }else{
        this.routeTimes[i][index] = {
          length: null,
          duration: null,
          date: null
        };

        // this.route_orders[i]['orders'][index-1]['route_point_distance'] = null;
        // this.route_orders[i]['orders'][index-1]['route_point_estimate_time'] = null;
        // this.route_orders[i]['orders'][index-1]['route_point_timespan'] = null;

        if((index+1) < this.getTimePoints[i].length){
          this.setTime(i, index+1);
        }

        if((index+1) == this.getTimePoints[i].length){
          this.getTimePointsLoading['route_'+i] = 1;
          this.saveNewDriverAndDate(i);
        }

      }
    }else{
      let route_date = this.dateTmp[id]['_d'];
      if(route_date != null){
        this.lastIssetOrderRouteDate[i] = moment(route_date).format('DD-MM-YYYY HH:mm');
      }else if(this.dateTmp[id] != null && this.dateTmp[id] != ''){
        let test: any = moment(this.dateTmp[id], 'YYYY-MM-DD HH:mm').toDate();
        this.lastIssetOrderRouteDate[i] = moment(test).format('DD-MM-YYYY HH:mm');
      }else{
        this.lastIssetOrderRouteDate[i] = moment().format('DD-MM-YYYY HH:mm');
      }

      this.routeTimes[i][index] = {
        length: 0,
        duration: 0,
        date: this.lastIssetOrderRouteDate[i]
      };

      // this.route_orders[i]['orders'][index-1]['route_point_distance'] = 0;
      // this.route_orders[i]['orders'][index-1]['route_point_estimate_time'] = this.lastIssetOrderRouteDate[i];
      // this.route_orders[i]['orders'][index-1]['route_point_timespan'] = 0;

      if((index+1) < this.getTimePoints[i].length){
        this.setTime(i, index+1);
      }

      if((index+1) == this.getTimePoints[i].length){
        this.getTimePointsLoading['route_'+i] = 1;
        this.saveNewDriverAndDate(i);
      }

      this.setRoutePointAverageTimeAndDistance(id, '0', '0', this.lastIssetOrderRouteDate[i]);
    }
  }

  saveNewDriverAndDate(i){
    console.log('SAVE NEW DRIVER AND DATE');
    let loading_date: any = moment(this.route_orders[i].loading_date, 'DD/MM/YYYY').toDate();
    loading_date = moment(loading_date).format('YYYY-MM-DD');
    let driver = this.route_orders[i].driver_id;
    let index = 0;
    for (let order of this.route_orders[i].orders){
      let send_email;
      let date_from: any = moment(this.routeTimes[i][index].date, 'DD-MM-YYYY HH:mm').toDate();
      date_from = moment(date_from).format('YYYY-MM-DD'); //c
      let date_from_second: any = moment(date_from).format('DD/MM/YYYY');
      let date_to: any = moment(this.routeTimes[i][index].date, 'DD-MM-YYYY HH:mm').toDate();
      date_to = moment(date_to).add(2, 'days').format('YYYY-MM-DD'); //d
      let date_to_second: any = moment(date_to).add(2, 'days').format('DD/MM/YYYY');
      if(order.status == 2 || order.status == 3){
        let expected_date_from: any = moment(order.expected_delivery_date_from, 'DD/MM/YYYY').toDate();
        expected_date_from = moment(expected_date_from).format('YYYY-MM-DD'); //a
        let expected_date_to: any = moment(order.expected_delivery_date_to, 'DD/MM/YYYY').toDate();
        expected_date_to = moment(expected_date_to).format('YYYY-MM-DD'); //b

        // this.route_orders[i].orders[index]['expected_delivery_date_from'] = date_from_second;
        // this.route_orders[i].orders[index]['expected_delivery_date_to'] = date_to_second;

        // ( c > a && c < b ) - NO MAIL

        let diff_1 = moment(date_from, 'YYYY-MM-DD HH:mm').diff(moment(expected_date_from, 'YYYY-MM-DD HH:mm'), 'days');
        let diff_2 = moment(date_from, 'YYYY-MM-DD HH:mm').diff(moment(expected_date_to, 'YYYY-MM-DD HH:mm'), 'days');

        if(this.send_email == true){
          if(diff_1 >= 0 && diff_2 <= 0){
            send_email = false;
          }else{
            send_email = true;
          }
        }else{
          send_email = false;
        }

          this.orders_api = this.api.update_ready_to_send_step(this.api_token, order.linn_id, driver, loading_date, date_from, date_to, send_email);
          this.orders_api.subscribe(data => {

          }, err => {
            console.log(err);
          });
      }
      index++;
    }
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay;
  }

  dateChange(event, item_id, orders, i){

    let date: any = moment(event).format('YYYY-MM-DD HH:mm');
    this.geo_api = this.api.setRouteExpectedDate(this.api_token, date, item_id);
    orders.find(order => order.id === item_id).route_point_estimate_time = moment(event).format('DD-MM-YYYY HH:mm');
    this.geo_api.subscribe(data => {
      this.getTimes(orders, i);
    }, err => {
      console.log(err);
    });
  }

  clearDate(id, orders, i){
    this.dateTmp[id] = '';
    this.geo_api = this.api.setRouteExpectedDate(this.api_token, null, id);
    this.geo_api.subscribe(data => {
      this.getTimes(orders, i);
    }, err => {
      console.log(err);
    });
  }

  private dropMarker(coordinates: any, data: any) {
    if(data.status == 0){
      var svgMarkup = '<svg height="28" viewBox="0 0 64 64" width="28" xmlns="http://www.w3.org/2000/svg"><g id="_04_Location" data-name="04 Location"><path d="m51.82 14.955c-8.366-15.924-31.334-15.955-39.72 0a22.274 22.274 0 0 0 1.025 22.767l15.126 23.278h7.422l15.127-23.278a22.274 22.274 0 0 0 1.02-22.767zm-19.858 18.295a8 8 0 1 1 8.085-8 8.043 8.043 0 0 1 -8.085 8z" fill="#FEC8C8"/><path d="m53.621 14.024a24.077 24.077 0 0 0 -21.664-13.024 24.071 24.071 0 0 0 -21.657 13.024 24.821 24.821 0 0 0 -2.766 9.226h4.04a20.783 20.783 0 0 1 2.274-7.365 20.126 20.126 0 0 1 18.109-10.885 20.132 20.132 0 0 1 18.118 10.885 20.276 20.276 0 0 1 -.932 20.748l-14.553 22.367h-5.257l-14.553-22.368a20.424 20.424 0 0 1 -3.256-9.382h-4.024a24.407 24.407 0 0 0 3.922 11.562l15.736 24.188h9.607l15.735-24.188a24.22 24.22 0 0 0 1.121-24.788z"/><path d="m37.692 27.25a6 6 0 1 1 0-4h4.151a10 10 0 1 0 0 4z"/></g></svg>';
    }else if(data.status == 2){
      var svgMarkup = '<svg height="28" viewBox="0 0 64 64" width="28" xmlns="http://www.w3.org/2000/svg"><g id="_04_Location" data-name="04 Location"><path d="m51.82 14.955c-8.366-15.924-31.334-15.955-39.72 0a22.274 22.274 0 0 0 1.025 22.767l15.126 23.278h7.422l15.127-23.278a22.274 22.274 0 0 0 1.02-22.767zm-19.858 18.295a8 8 0 1 1 8.085-8 8.043 8.043 0 0 1 -8.085 8z" fill="#c1e7ff"/><path d="m53.621 14.024a24.077 24.077 0 0 0 -21.664-13.024 24.071 24.071 0 0 0 -21.657 13.024 24.821 24.821 0 0 0 -2.766 9.226h4.04a20.783 20.783 0 0 1 2.274-7.365 20.126 20.126 0 0 1 18.109-10.885 20.132 20.132 0 0 1 18.118 10.885 20.276 20.276 0 0 1 -.932 20.748l-14.553 22.367h-5.257l-14.553-22.368a20.424 20.424 0 0 1 -3.256-9.382h-4.024a24.407 24.407 0 0 0 3.922 11.562l15.736 24.188h9.607l15.735-24.188a24.22 24.22 0 0 0 1.121-24.788z"/><path d="m37.692 27.25a6 6 0 1 1 0-4h4.151a10 10 0 1 0 0 4z"/></g></svg>';
    }else if(data.status == 3){
      var svgMarkup = '<svg height="28" viewBox="0 0 64 64" width="28" xmlns="http://www.w3.org/2000/svg"><g id="_04_Location" data-name="04 Location"><path d="m51.82 14.955c-8.366-15.924-31.334-15.955-39.72 0a22.274 22.274 0 0 0 1.025 22.767l15.126 23.278h7.422l15.127-23.278a22.274 22.274 0 0 0 1.02-22.767zm-19.858 18.295a8 8 0 1 1 8.085-8 8.043 8.043 0 0 1 -8.085 8z" fill="#ffe6a1"/><path d="m53.621 14.024a24.077 24.077 0 0 0 -21.664-13.024 24.071 24.071 0 0 0 -21.657 13.024 24.821 24.821 0 0 0 -2.766 9.226h4.04a20.783 20.783 0 0 1 2.274-7.365 20.126 20.126 0 0 1 18.109-10.885 20.132 20.132 0 0 1 18.118 10.885 20.276 20.276 0 0 1 -.932 20.748l-14.553 22.367h-5.257l-14.553-22.368a20.424 20.424 0 0 1 -3.256-9.382h-4.024a24.407 24.407 0 0 0 3.922 11.562l15.736 24.188h9.607l15.735-24.188a24.22 24.22 0 0 0 1.121-24.788z"/><path d="m37.692 27.25a6 6 0 1 1 0-4h4.151a10 10 0 1 0 0 4z"/></g></svg>';
    }else{
      var svgMarkup = '<svg height="28" viewBox="0 0 64 64" width="28" xmlns="http://www.w3.org/2000/svg"><g id="_04_Location" data-name="04 Location"><path d="m51.82 14.955c-8.366-15.924-31.334-15.955-39.72 0a22.274 22.274 0 0 0 1.025 22.767l15.126 23.278h7.422l15.127-23.278a22.274 22.274 0 0 0 1.02-22.767zm-19.858 18.295a8 8 0 1 1 8.085-8 8.043 8.043 0 0 1 -8.085 8z" fill="#e4e4e4"/><path d="m53.621 14.024a24.077 24.077 0 0 0 -21.664-13.024 24.071 24.071 0 0 0 -21.657 13.024 24.821 24.821 0 0 0 -2.766 9.226h4.04a20.783 20.783 0 0 1 2.274-7.365 20.126 20.126 0 0 1 18.109-10.885 20.132 20.132 0 0 1 18.118 10.885 20.276 20.276 0 0 1 -.932 20.748l-14.553 22.367h-5.257l-14.553-22.368a20.424 20.424 0 0 1 -3.256-9.382h-4.024a24.407 24.407 0 0 0 3.922 11.562l15.736 24.188h9.607l15.735-24.188a24.22 24.22 0 0 0 1.121-24.788z"/><path d="m37.692 27.25a6 6 0 1 1 0-4h4.151a10 10 0 1 0 0 4z"/></g></svg>';
    }
    // Create an icon, an object holding the latitude and longitude, and a marker:
    var icon = new H.map.Icon(svgMarkup);

    let marker = new H.map.Marker(coordinates, {icon: icon});
    marker.setData("<p>" + data.postcode + "<br>" + data.description + "</p>");
    marker.addEventListener('tap', event => {
      // let bubble = new H.ui.InfoBubble(event.target.getPosition(), {
      //   content: event.target.getData()
      // });
      // this.ui.addBubble(bubble);

      // this._bottomSheet.open(BottomSheetOverviewExampleSheet, {
      //   data: data
      // });

      this.selectedMarker = {
        postcode: data.postcode,
        status: data.status,
        model: data.model,
        linn_id: data.linn_id
      };

      let selectedMarkerOrderExist = this.linnExists(data.linn_id);
      if(selectedMarkerOrderExist == true){
        this.selectedMarkerOrderExist = true;
      }else{
        this.selectedMarkerOrderExist =  data.linn_id;
      }

    }, false);
    this.map.addObject(marker);
  }

  linnExists(linn_id) {
    return this.todo.some(function(el) {
      return el.linn_id === linn_id;
    });
  }

  clearSelectedMarker(){
    this.selectedMarker = null;
  }

  getCountryCode(order){
    //this.todo = [];
    let scooter_color = order.scooter_model.color;
    let color_class = '';
         if(scooter_color == 'Black'){color_class = 'scooter_black';}
    else if(scooter_color == 'Silver'){color_class = 'scooter_silver';}
    else if(scooter_color == 'White'){color_class = 'scooter_white';}
    else if(scooter_color == 'Red'){color_class = 'scooter_red';}
    else if(scooter_color == 'Blue'){color_class = 'scooter_blue';}
    else if(scooter_color == 'Gray'){color_class = 'scooter_gray';}
    else{color_class = 'scooter_color_unknow';}

    //console.log("cd: "+order.id);
    this.todo.push({
      id: order.id,
      important: order.important,
      risc: order.this_risk_of_receiving_negative_feedback,
      date: order.order_date,
      order_from: order.order_from,
      client: order.client,
      linn_id: order.linn_id,
      parent_linn_id: order.parent_linn_id,
      street: order.delivery_address_line_1,
      postcode: order.delivery_postcode,
      city: order.delivery_city,
      country: order.delivery_country,
      model: order.scooter_model,
      color: color_class,
      status: order.trackpack_status,
      flag: 'undefined',
      route_point_distance: order.route_point_distance,
      route_point_timespan: order.route_point_timespan,
      route_point_estimate_time: order.route_point_estimate_time,
      source: order.source,
      expected_delivery_date_from: order.expected_delivery_date_from,
      expected_delivery_date_to: order.expected_delivery_date_to,
      all_order_info: order
    });
  }

   printItem(item) {
     console.log("Print item: ");
     console.log(item);
   }
  // filter(event){
  //   console.log(event);
  //   let value = event.value;
  //   var filter_column = 'order_date';
  //   var filter_direction = 'desc';
  //   if(value == "0"){
  //     filter_column = 'order_date';
  //     filter_direction = 'desc';
  //   }else if(value == "1"){
  //     filter_column = 'order_date';
  //     filter_direction = 'asc';
  //   }else if(value == "2"){
  //     filter_column = 'delivery_postcode';
  //     filter_direction = 'asc';
  //   }else if(value == "3"){
  //     filter_column = 'scooter_model';
  //     filter_direction = 'asc';
  //   }
  //   console.log(filter_column);
  //
  //   this.getOrders(0, 1000, filter_column, filter_direction);
  // }

  openDialog() {
    this.dialog.open(MapdialogComponent);
  }

  newRoute(){
    const dialogRef = this.dialog.open(DialogAddRoute, {
      data: {

      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.refresh == 1){
        this.getRoutes();
      }
    });
  }

  editRoute(route){
    const dialogRef = this.dialog.open(DialogEditRoute, {
      data: {
        route: route
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.refresh == 1){
        this.getRoutes();
      }
    });
  }

  changeStatuses(orders, driver, loading_date){
    console.log(orders);
    const dialogRef = this.dialog.open(DialogChangeStatuses, {
      data: {
        orders: orders,
        driver: driver,
        loading_date: loading_date,
        send_email: this.send_email
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.refresh == 1){
        this.getRoutes();
      }
    });
  }

  generateOrder(orders, driver, loading_date){
    const dialogRef = this.dialog.open(DialogGenerateOrder, {
      data: {
        orders: orders,
        driver: driver,
        loading_date: loading_date,
        send_email: this.send_email
      },
      width: '1520px'
    });
  }

  addPoint(route_id){
    const dialogRef = this.dialog.open(DialogAddPoint, {
      data: {
        route_id: route_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.refresh == 1){
        this.getRoutes();
      }
    });
  }
  //
  // deleteRoute(i){
  //   this.boxes.splice(i,1);
  // }
  //
  mapGenerate(items){
    let map_string = '';
    for(let item of items){
      map_string += item.city+', '+ item.postcode+', '+item.country+'/';
      //console.log('MAP-STRING: '+map_string);
    }
    this.orders_map_url = map_string;
    window.open('https://www.google.pl/maps/dir/'+map_string, "_blank");
    //console.log(items);
  }

  realizeZK(items){
    var realize_index = 1;
    for(let item of items){
      this.orders_api = this.api.realize_zk(this.api_token, item.linn_id);
      this.orders_api.subscribe(data => {
        if(realize_index == items.length){
          this.snackBar.open('Zmieniono flagę realize_zk na wartość 1 dla zamówień w wybranej trasie', 'OK', {
            duration: 2000,
          });
          this.getRoutes();
        }
        realize_index++;
      }, err => {
        realize_index++;
      });
    }
  }

  routeDetails(id){
    this.route_details_id = id;
  }

  getRoutes(archived?){

    let archive_status = 0;
    if(archived == true){
      archive_status = 1;
    }

    this.route_orders = [];
    this.routes_loader = 0;
    this.routes_api = this.api.get_routes(this.api_token, archive_status);
    this.routes_api.subscribe(data => {
      //console.log(data.data);
      for (let route of data.data) {
        let orders = [];
        let i = 0;
        //console.log('ROUTE');

        for (let order of route.orders) {
          let scooter_color = order.scooter_model.color;
          let color_class = '';
               if(scooter_color == 'Black'){color_class = 'scooter_black';}
          else if(scooter_color == 'Silver'){color_class = 'scooter_silver';}
          else if(scooter_color == 'White'){color_class = 'scooter_white';}
          else if(scooter_color == 'Red'){color_class = 'scooter_red';}
          else if(scooter_color == 'Blue'){color_class = 'scooter_blue';}
          else if(scooter_color == 'Gray'){color_class = 'scooter_gray';}
          else{color_class = 'scooter_color_unknow';}
          orders[order.route_position] = {
            id: order.id,
            important: order.important,
            date: order.order_date,
            risc: order.this_risk_of_receiving_negative_feedback,
            sdate: order.order_date,
            client: order.client,
            linn_id: order.linn_id,
            parent_linn_id: order.parent_linn_id,
            street: order.delivery_address_line_1,
            postcode: order.delivery_postcode,
            city: order.delivery_city,
            country: order.delivery_country,
            model: order.scooter_model,
            color: color_class,
            status: order.trackpack_status,
            flag: 'undefined',
            route_point_distance: order.route_point_distance,
            route_point_timespan: order.route_point_timespan,
            route_point_estimate_time: order.route_point_estimate_time,
            route_position: order.route_position,
            route_expected_date: order.route_expected_date,
            source: order.source,
            expected_delivery_date_from: order.expected_delivery_date_from,
            expected_delivery_date_to: order.expected_delivery_date_to,
            delivery_address_line_1: order.delivery_address_line_1,
            delivery_address_line_2: order.delivery_address_line_2,
            client_phone: order.client_phone,
            client_email: order.client_email,
            order_from: order.order_from,
            b2b: order.b2b,
            all_order_info: order
          };


          i++;

          if(order.route_expected_date != null){
            this.dateTmp[order.id] = order.route_expected_date;
          }else{
            this.dateTmp[order.id] = '';
          }
        }

        this.route_orders.push({
          id: route.id,
          driver: route.driver.name+' '+route.driver.surname,
          driver_id: route.driver.id,
          description: route.description,
          loading_date: route.loading_date,
          avatar: this.api.api_url+"../storage/app/avatars/"+route.driver.avatar,
          orders: orders
        });
      }


      this.routes_loader = 1;

    }, err => {
      console.log(err);
      this.routes_loader = 1;
    });
  }
}



@Component({
  selector: 'dialog-add-route',
  template: `
    <h1 mat-dialog-title style="color: #000761;">Add new route</h1>
    <div mat-dialog-content>

      <div>
        <mat-form-field appearance="fill" style="width: 300px;" required>
          <mat-label>Select driver</mat-label>
          <mat-select [(ngModel)]="driver_id">
            <mat-option *ngFor="let driver of drivers" [value]="driver.id">
              {{driver.name}} {{driver.surname}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="fill" style="width: 300px;" required>
          <mat-label>Loading date</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="loading_date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="fill" style="width: 300px;">
         <mat-label>Description</mat-label>
         <textarea matInput  [(ngModel)]="description"></textarea>
       </mat-form-field>
      </div>

    </div>
    <div mat-dialog-actions>
      <button mat-button cdkFocusInitial (click)="dialogClose(0)">Cancel</button>
      <button mat-raised-button color="primary" (click)="addRoute()">Add new route</button>
    </div>
  `,
})
export class DialogAddRoute {

  constructor(public dialogRef: MatDialogRef<DialogAddRoute>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data){}

  api_token: any;
  drivers_api: any;
  drivers: any
  route_api: any;
  loading_date: any;
  description: any;
  driver_id: any;

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.getDrivers();
  }

  getDrivers(){
    //console.log(this.api_token);
    this.drivers_api = this.api.get_drivers(this.api_token, 3);
    this.drivers_api.subscribe(data => {
      this.drivers = data.data;
      //console.log(this.drivers);
    }, err => {
      console.log(err);
    });
  }

  dialogClose(refresh){  //0 - no refresh data; 1-refresh data
    this.dialogRef.close({refresh: refresh});
  }

  addRoute(){
    let loading_date: any = moment(this.loading_date).format('YYYY-MM-DD');
    this.route_api = this.api.add_route(this.api_token, loading_date, this.driver_id, this.description);
    this.route_api.subscribe(data => {
      //console.log('ADD ROUTE');
      //console.log(data);
      this.dialogClose(1);
    }, err => {
      console.log(err);
    });
  }

}

@Component({
  selector: 'dialog-edit-route',
  template: `
    <h1 mat-dialog-title style="color: #000761;">Edit route</h1>
    <div mat-dialog-content>

      <div>
        <mat-form-field appearance="fill" style="width: 300px;" required>
          <mat-label>Select driver</mat-label>
          <mat-select [(ngModel)]="driver_id">
            <mat-option *ngFor="let driver of drivers" [value]="driver.id">
              {{driver.name}} {{driver.surname}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="fill" style="width: 300px;" required>
          <mat-label>Loading date</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="loading_date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="fill" style="width: 300px;">
         <mat-label>Description</mat-label>
         <textarea matInput  [(ngModel)]="description"></textarea>
       </mat-form-field>
      </div>

    </div>
    <div mat-dialog-actions>
      <button mat-button cdkFocusInitial (click)="dialogClose(0)">Cancel</button>
      <button mat-raised-button color="primary" (click)="editRoute()">Save</button>
    </div>
  `,
})
export class DialogEditRoute {

  constructor(public dialogRef: MatDialogRef<DialogEditRoute>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data){}

  api_token: any;
  route_id: any;
  drivers_api: any;
  drivers: any
  route_api: any;
  loading_date: any;
  description: any;
  driver_id: any;

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.route_id = this.data.route.id;
    this.driver_id = this.data.route.driver_id;
    this.description = this.data.route.description;
    this.loading_date = moment(this.data.route.loading_date, 'DD/MM/YYYY').format();
    this.getDrivers();
  }

  getDrivers(){
    //console.log(this.api_token);
    this.drivers_api = this.api.get_drivers(this.api_token, 3);
    this.drivers_api.subscribe(data => {
      this.drivers = data.data;
      //console.log(this.drivers);
    }, err => {
      console.log(err);
    });
  }

  dialogClose(refresh){  //0 - no refresh data; 1-refresh data
    this.dialogRef.close({refresh: refresh});
  }

  editRoute(){
    let loading_date: any = moment(this.loading_date).format('YYYY-MM-DD');
    this.route_api = this.api.edit_route(this.api_token, this.route_id, loading_date, this.driver_id, this.description);
    this.route_api.subscribe(data => {
      //console.log('ADD ROUTE');
      //console.log(data);
      this.dialogClose(1);
    }, err => {
      console.log(err);
    });
  }

}



// <div class="dates">
//   Deadline:<br />
//   <span *ngIf="order.route_point_estimate_time != null">{{order.route_point_estimate_time}}</span>
// </div>

@Component({
  selector: 'dialog-change-statuses',
  template: `
    <h1 mat-dialog-title style="color: #000761;">Change statuses</h1>
    <div mat-dialog-content>
      <div class="orders_list">
        <div *ngFor="let order of orders" class="order">
          <div class="flag">
            <img src="assets/imgs/flag_uk.png" *ngIf="order.order_from == 'Lunex'"/>
            <img src="assets/imgs/flag_de.png" *ngIf="order.order_from == 'Lauftech'"/>
            <img src="assets/imgs/flag_pl.png" *ngIf="order.order_from == 'Veleco'"/>
            <img src="assets/imgs/flag_es.png" *ngIf="order.order_from == 'Tricovelo'"/>
          </div>
          <div class="postcode">{{order.postcode}}</div>
          <div class="linn_id">#{{order.linn_id}}</div>
          <div class="statuses">
            <span *ngIf="order.status == 0 || order.status == 1">From:</span>
              <span *ngIf="order.status == 0" style="color: #b61c1c;">Ordered</span>
              <span *ngIf="order.status == 1" style="color: gray;">In progress</span>
              <span *ngIf="order.status == 2" style="color: #40a1df;">Ready to send</span>
              <span *ngIf="order.status == 3" style="color: #b48200;">Sent</span>
              <span *ngIf="order.status == 4" style="color: #139a21;">Delivered</span>
            <br />
            <span *ngIf="order.status == 0 || order.status == 1">To:</span>
              <span *ngIf="order.status == 0 || order.status == 1" style="color: #40a1df;">Ready to send</span>
              <span *ngIf="order.status != 0 && order.status != 1" style="color: #139a21;"><i>Unchanged state</i></span>
            <br />
            <span>{{order.route_point_estimate_time}}</span>
          </div>
        </div>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Loading place</mat-label>
          <mat-select [(ngModel)]="loading_place">
            <mat-option *ngFor="let point of points" [value]="point.id">{{point.city}} ({{point.name}})</mat-option>
          </mat-select>


        </mat-form-field>
        <input matInput [(ngModel)]="shipping_cost" placeholder="Shipping cost" type="number">
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-button cdkFocusInitial (click)="dialogClose(0)">Cancel</button>
      <button mat-raised-button color="primary" (click)="publish()" [disabled]="shipping_cost === null || loading_place === null">CONFIRM CHANGES</button>
    </div>
  `,
  styleUrls: ['./organize.component.scss']
})
export class DialogChangeStatuses {

  constructor(public dialogRef: MatDialogRef<DialogChangeStatuses>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data){}

  api_token: any;
  orders: any;
  orders_api: any;
  driver: any;
  loading_date: any;
  send_email: any;

  points_api: any;
  points: any;

  shipping_cost: any = null;
  loading_place: any = null;

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.orders = this.data.orders;
    console.log('dialog input data.orders', this.data.orders);
    this.driver = this.data.driver;
    this.loading_date = this.data.loading_date;
    this.send_email = this.data.send_email;
    // console.log(this.orders);
    // console.log(this.driver);
    // console.log(this.loading_date);
    this.getCollectionPoints();
  }

  getCollectionPoints(){
    this.points_api = this.api.getCollectPoints(this.api_token);
    this.points_api.subscribe(data => {
      this.points = data.data;
    }, err => {
      console.log(err);
    });
  }



  publish(){
    let loading_date: any = moment(this.loading_date, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm');
    let index = 1;
    for(let order of this.orders){
      let send_email;
      console.log('Otrzymana data', order.route_point_estimate_time);
      let date_from: any = moment(order.route_point_estimate_time, 'DD-MM-YYYY HH:mm').toDate();
      date_from = moment(date_from).format('YYYY-MM-DD'); //c
      let date_to: any = moment(order.route_point_estimate_time, 'DD-MM-YYYY HH:mm').toDate();
      date_to = moment(date_to).add(2, 'days').format('YYYY-MM-DD'); //d
      console.log('Sformatowana data', date_from);

      console.log(order);

      if(order.status == 0 || order.status == 1){
        send_email = true;
        let loading_place = this.loading_place;
        this.orders_api = this.api.ready_to_send_step(this.api_token, order.linn_id, this.driver, loading_date, date_from, date_to, send_email, loading_place);
        this.orders_api.subscribe(data => {

        }, err => {
          console.log(err);
        });
      }else{
        let expected_date_from: any = moment(order.expected_delivery_date_from, 'DD/MM/YYYY').toDate();
        expected_date_from = moment(expected_date_from).format('YYYY-MM-DD'); //a
        let expected_date_to: any = moment(order.expected_delivery_date_to, 'DD/MM/YYYY').toDate();
        expected_date_to = moment(expected_date_to).format('YYYY-MM-DD'); //b

        // ( c > a && c < b ) - NO MAIL

        let diff_1: any = moment(date_from, 'YYYY-MM-DD HH:mm').diff(moment(expected_date_from, 'YYYY-MM-DD HH:mm'), 'days');
        let diff_2: any = moment(date_from, 'YYYY-MM-DD HH:mm').diff(moment(expected_date_to, 'YYYY-MM-DD HH:mm'), 'days');

        if(this.send_email == true){
          if(diff_1 >= 0 && diff_2 <= 0){
            //console.log('NO EMAIL');
            send_email = false;
          }else{
            //console.log('EMAIL');
            send_email = true;
          }
        }else{
          send_email = false;
        }

        let unit_shipping_cost;

        if(this.shipping_cost != 0){
          unit_shipping_cost = this.shipping_cost / this.orders.length;
        }else{
          unit_shipping_cost = 0;
        }
        let loading_place = this.loading_place;

        this.orders_api = this.api.update_ready_to_send_step(this.api_token, order.linn_id, this.driver.id, loading_date, date_from, date_to, send_email, unit_shipping_cost, loading_place);
        this.orders_api.subscribe(data => {

        }, err => {
          console.log(err);
        });
      }

      if(index == this.orders.length){
        this.dialogClose(1);
      }

      index++;
    }
  }

  dialogClose(refresh){ //0 - no refresh data; 1-refresh data
    this.dialogRef.close({refresh: refresh});
  }

}




@Component({
  selector: 'dialog-add-point',
  template: `
    <h1 mat-dialog-title style="color: #000761;">Add point</h1>
    <div mat-dialog-content>
      <div>
        <mat-form-field>
          <mat-label>Postcode</mat-label>
          <input matInput [(ngModel)]="postcode">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>City</mat-label>
          <input matInput [(ngModel)]="city">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-label>Country</mat-label>
          <mat-select [(ngModel)]="country">
            <mat-option value="0" disabled="">-- POPULAR --</mat-option>
            <mat-option value="France">France</mat-option>
            <mat-option value="Germany">Germany</mat-option>
            <mat-option value="Italy">Italy</mat-option>
            <mat-option value="Spain">Spain</mat-option>
            <mat-option value="United Kingdom">United Kingdom</mat-option>
            <mat-option value="United States">United States</mat-option>
            <mat-option value="0" disabled="">-- ALL --</mat-option>
            <mat-option value="Afghanistan">Afghanistan</mat-option>
            <mat-option value="Åland Islands">Åland Islands</mat-option>
            <mat-option value="Albania">Albania</mat-option>
            <mat-option value="Algeria">Algeria</mat-option>
            <mat-option value="American Samoa">American Samoa</mat-option>
            <mat-option value="Andorra">Andorra</mat-option>
            <mat-option value="Angola">Angola</mat-option>
            <mat-option value="Anguilla">Anguilla</mat-option>
            <mat-option value="Antarctica">Antarctica</mat-option>
            <mat-option value="Antigua and Barbuda">Antigua and Barbuda</mat-option>
            <mat-option value="Argentina">Argentina</mat-option>
            <mat-option value="Armenia">Armenia</mat-option>
            <mat-option value="Aruba">Aruba</mat-option>
            <mat-option value="Australia">Australia</mat-option>
            <mat-option value="Austria">Austria</mat-option>
            <mat-option value="Azerbaijan">Azerbaijan</mat-option>
            <mat-option value="Bahamas">Bahamas</mat-option>
            <mat-option value="Bahrain">Bahrain</mat-option>
            <mat-option value="Bangladesh">Bangladesh</mat-option>
            <mat-option value="Barbados">Barbados</mat-option>
            <mat-option value="Belarus">Belarus</mat-option>
            <mat-option value="Belgium">Belgium</mat-option>
            <mat-option value="Belize">Belize</mat-option>
            <mat-option value="Benin">Benin</mat-option>
            <mat-option value="Bermuda">Bermuda</mat-option>
            <mat-option value="Bhutan">Bhutan</mat-option>
            <mat-option value="Bolivia">Bolivia</mat-option>
            <mat-option value="Bosnia and Herzegovina">Bosnia and Herzegovina</mat-option>
            <mat-option value="Botswana">Botswana</mat-option>
            <mat-option value="Bouvet Island">Bouvet Island</mat-option>
            <mat-option value="Brazil">Brazil</mat-option>
            <mat-option value="British Indian Ocean Territory">British Indian Ocean Territory</mat-option>
            <mat-option value="Brunei Darussalam">Brunei Darussalam</mat-option>
            <mat-option value="Bulgaria">Bulgaria</mat-option>
            <mat-option value="Burkina Faso">Burkina Faso</mat-option>
            <mat-option value="Burundi">Burundi</mat-option>
            <mat-option value="Cambodia">Cambodia</mat-option>
            <mat-option value="Cameroon">Cameroon</mat-option>
            <mat-option value="Canada">Canada</mat-option>
            <mat-option value="Cape Verde">Cape Verde</mat-option>
            <mat-option value="Cayman Islands">Cayman Islands</mat-option>
            <mat-option value="Central African Republic">Central African Republic</mat-option>
            <mat-option value="Chad">Chad</mat-option>
            <mat-option value="Chile">Chile</mat-option>
            <mat-option value="China">China</mat-option>
            <mat-option value="Christmas Island">Christmas Island</mat-option>
            <mat-option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</mat-option>
            <mat-option value="Colombia">Colombia</mat-option>
            <mat-option value="Comoros">Comoros</mat-option>
            <mat-option value="Congo">Congo</mat-option>
            <mat-option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</mat-option>
            <mat-option value="Cook Islands">Cook Islands</mat-option>
            <mat-option value="Costa Rica">Costa Rica</mat-option>
            <mat-option value="Cote D'ivoire">Cote D'ivoire</mat-option>
            <mat-option value="Croatia">Croatia</mat-option>
            <mat-option value="Cuba">Cuba</mat-option>
            <mat-option value="Cyprus">Cyprus</mat-option>
            <mat-option value="Czech Republic">Czech Republic</mat-option>
            <mat-option value="Denmark">Denmark</mat-option>
            <mat-option value="Djibouti">Djibouti</mat-option>
            <mat-option value="Dominica">Dominica</mat-option>
            <mat-option value="Dominican Republic">Dominican Republic</mat-option>
            <mat-option value="Ecuador">Ecuador</mat-option>
            <mat-option value="Egypt">Egypt</mat-option>
            <mat-option value="El Salvador">El Salvador</mat-option>
            <mat-option value="Equatorial Guinea">Equatorial Guinea</mat-option>
            <mat-option value="Eritrea">Eritrea</mat-option>
            <mat-option value="Estonia">Estonia</mat-option>
            <mat-option value="Ethiopia">Ethiopia</mat-option>
            <mat-option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</mat-option>
            <mat-option value="Faroe Islands">Faroe Islands</mat-option>
            <mat-option value="Fiji">Fiji</mat-option>
            <mat-option value="Finland">Finland</mat-option>
            <mat-option value="France">France</mat-option>
            <mat-option value="French Guiana">French Guiana</mat-option>
            <mat-option value="French Polynesia">French Polynesia</mat-option>
            <mat-option value="French Southern Territories">French Southern Territories</mat-option>
            <mat-option value="Gabon">Gabon</mat-option>
            <mat-option value="Gambia">Gambia</mat-option>
            <mat-option value="Georgia">Georgia</mat-option>
            <mat-option value="Germany">Germany</mat-option>
            <mat-option value="Ghana">Ghana</mat-option>
            <mat-option value="Gibraltar">Gibraltar</mat-option>
            <mat-option value="Greece">Greece</mat-option>
            <mat-option value="Greenland">Greenland</mat-option>
            <mat-option value="Grenada">Grenada</mat-option>
            <mat-option value="Guadeloupe">Guadeloupe</mat-option>
            <mat-option value="Guam">Guam</mat-option>
            <mat-option value="Guatemala">Guatemala</mat-option>
            <mat-option value="Guernsey">Guernsey</mat-option>
            <mat-option value="Guinea">Guinea</mat-option>
            <mat-option value="Guinea-bissau">Guinea-bissau</mat-option>
            <mat-option value="Guyana">Guyana</mat-option>
            <mat-option value="Haiti">Haiti</mat-option>
            <mat-option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</mat-option>
            <mat-option value="Holy See (Vatican City State)">Holy See (Vatican City State)</mat-option>
            <mat-option value="Honduras">Honduras</mat-option>
            <mat-option value="Hong Kong">Hong Kong</mat-option>
            <mat-option value="Hungary">Hungary</mat-option>
            <mat-option value="Iceland">Iceland</mat-option>
            <mat-option value="India">India</mat-option>
            <mat-option value="Indonesia">Indonesia</mat-option>
            <mat-option value="Iran, Islamic Republic of">Iran, Islamic Republic of</mat-option>
            <mat-option value="Iraq">Iraq</mat-option>
            <mat-option value="Ireland">Ireland</mat-option>
            <mat-option value="Isle of Man">Isle of Man</mat-option>
            <mat-option value="Israel">Israel</mat-option>
            <mat-option value="Italy">Italy</mat-option>
            <mat-option value="Jamaica">Jamaica</mat-option>
            <mat-option value="Japan">Japan</mat-option>
            <mat-option value="Jersey">Jersey</mat-option>
            <mat-option value="Jordan">Jordan</mat-option>
            <mat-option value="Kazakhstan">Kazakhstan</mat-option>
            <mat-option value="Kenya">Kenya</mat-option>
            <mat-option value="Kiribati">Kiribati</mat-option>
            <mat-option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</mat-option>
            <mat-option value="Korea, Republic of">Korea, Republic of</mat-option>
            <mat-option value="Kuwait">Kuwait</mat-option>
            <mat-option value="Kyrgyzstan">Kyrgyzstan</mat-option>
            <mat-option value="Lao People's Democratic Republic">Lao People's Democratic Republic</mat-option>
            <mat-option value="Latvia">Latvia</mat-option>
            <mat-option value="Lebanon">Lebanon</mat-option>
            <mat-option value="Lesotho">Lesotho</mat-option>
            <mat-option value="Liberia">Liberia</mat-option>
            <mat-option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</mat-option>
            <mat-option value="Liechtenstein">Liechtenstein</mat-option>
            <mat-option value="Lithuania">Lithuania</mat-option>
            <mat-option value="Luxembourg">Luxembourg</mat-option>
            <mat-option value="Macao">Macao</mat-option>
            <mat-option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</mat-option>
            <mat-option value="Madagascar">Madagascar</mat-option>
            <mat-option value="Malawi">Malawi</mat-option>
            <mat-option value="Malaysia">Malaysia</mat-option>
            <mat-option value="Maldives">Maldives</mat-option>
            <mat-option value="Mali">Mali</mat-option>
            <mat-option value="Malta">Malta</mat-option>
            <mat-option value="Marshall Islands">Marshall Islands</mat-option>
            <mat-option value="Martinique">Martinique</mat-option>
            <mat-option value="Mauritania">Mauritania</mat-option>
            <mat-option value="Mauritius">Mauritius</mat-option>
            <mat-option value="Mayotte">Mayotte</mat-option>
            <mat-option value="Mexico">Mexico</mat-option>
            <mat-option value="Micronesia, Federated States of">Micronesia, Federated States of</mat-option>
            <mat-option value="Moldova, Republic of">Moldova, Republic of</mat-option>
            <mat-option value="Monaco">Monaco</mat-option>
            <mat-option value="Mongolia">Mongolia</mat-option>
            <mat-option value="Montenegro">Montenegro</mat-option>
            <mat-option value="Montserrat">Montserrat</mat-option>
            <mat-option value="Morocco">Morocco</mat-option>
            <mat-option value="Mozambique">Mozambique</mat-option>
            <mat-option value="Myanmar">Myanmar</mat-option>
            <mat-option value="Namibia">Namibia</mat-option>
            <mat-option value="Nauru">Nauru</mat-option>
            <mat-option value="Nepal">Nepal</mat-option>
            <mat-option value="Netherlands">Netherlands</mat-option>
            <mat-option value="Netherlands Antilles">Netherlands Antilles</mat-option>
            <mat-option value="New Caledonia">New Caledonia</mat-option>
            <mat-option value="New Zealand">New Zealand</mat-option>
            <mat-option value="Nicaragua">Nicaragua</mat-option>
            <mat-option value="Niger">Niger</mat-option>
            <mat-option value="Nigeria">Nigeria</mat-option>
            <mat-option value="Niue">Niue</mat-option>
            <mat-option value="Norfolk Island">Norfolk Island</mat-option>
            <mat-option value="Northern Mariana Islands">Northern Mariana Islands</mat-option>
            <mat-option value="Norway">Norway</mat-option>
            <mat-option value="Oman">Oman</mat-option>
            <mat-option value="Pakistan">Pakistan</mat-option>
            <mat-option value="Palau">Palau</mat-option>
            <mat-option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</mat-option>
            <mat-option value="Panama">Panama</mat-option>
            <mat-option value="Papua New Guinea">Papua New Guinea</mat-option>
            <mat-option value="Paraguay">Paraguay</mat-option>
            <mat-option value="Peru">Peru</mat-option>
            <mat-option value="Philippines">Philippines</mat-option>
            <mat-option value="Pitcairn">Pitcairn</mat-option>
            <mat-option value="Poland">Poland</mat-option>
            <mat-option value="Portugal">Portugal</mat-option>
            <mat-option value="Puerto Rico">Puerto Rico</mat-option>
            <mat-option value="Qatar">Qatar</mat-option>
            <mat-option value="Reunion">Reunion</mat-option>
            <mat-option value="Romania">Romania</mat-option>
            <mat-option value="Russian Federation">Russian Federation</mat-option>
            <mat-option value="Rwanda">Rwanda</mat-option>
            <mat-option value="Saint Helena">Saint Helena</mat-option>
            <mat-option value="Saint Kitts and Nevis">Saint Kitts and Nevis</mat-option>
            <mat-option value="Saint Lucia">Saint Lucia</mat-option>
            <mat-option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</mat-option>
            <mat-option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</mat-option>
            <mat-option value="Samoa">Samoa</mat-option>
            <mat-option value="San Marino">San Marino</mat-option>
            <mat-option value="Sao Tome and Principe">Sao Tome and Principe</mat-option>
            <mat-option value="Saudi Arabia">Saudi Arabia</mat-option>
            <mat-option value="Senegal">Senegal</mat-option>
            <mat-option value="Serbia">Serbia</mat-option>
            <mat-option value="Seychelles">Seychelles</mat-option>
            <mat-option value="Sierra Leone">Sierra Leone</mat-option>
            <mat-option value="Singapore">Singapore</mat-option>
            <mat-option value="Slovakia">Slovakia</mat-option>
            <mat-option value="Slovenia">Slovenia</mat-option>
            <mat-option value="Solomon Islands">Solomon Islands</mat-option>
            <mat-option value="Somalia">Somalia</mat-option>
            <mat-option value="South Africa">South Africa</mat-option>
            <mat-option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</mat-option>
            <mat-option value="Spain">Spain</mat-option>
            <mat-option value="Sri Lanka">Sri Lanka</mat-option>
            <mat-option value="Sudan">Sudan</mat-option>
            <mat-option value="Suriname">Suriname</mat-option>
            <mat-option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</mat-option>
            <mat-option value="Swaziland">Swaziland</mat-option>
            <mat-option value="Sweden">Sweden</mat-option>
            <mat-option value="Switzerland">Switzerland</mat-option>
            <mat-option value="Syrian Arab Republic">Syrian Arab Republic</mat-option>
            <mat-option value="Taiwan, Province of China">Taiwan, Province of China</mat-option>
            <mat-option value="Tajikistan">Tajikistan</mat-option>
            <mat-option value="Tanzania, United Republic of">Tanzania, United Republic of</mat-option>
            <mat-option value="Thailand">Thailand</mat-option>
            <mat-option value="Timor-leste">Timor-leste</mat-option>
            <mat-option value="Togo">Togo</mat-option>
            <mat-option value="Tokelau">Tokelau</mat-option>
            <mat-option value="Tonga">Tonga</mat-option>
            <mat-option value="Trinidad and Tobago">Trinidad and Tobago</mat-option>
            <mat-option value="Tunisia">Tunisia</mat-option>
            <mat-option value="Turkey">Turkey</mat-option>
            <mat-option value="Turkmenistan">Turkmenistan</mat-option>
            <mat-option value="Turks and Caicos Islands">Turks and Caicos Islands</mat-option>
            <mat-option value="Tuvalu">Tuvalu</mat-option>
            <mat-option value="Uganda">Uganda</mat-option>
            <mat-option value="Ukraine">Ukraine</mat-option>
            <mat-option value="United Arab Emirates">United Arab Emirates</mat-option>
            <mat-option value="United Kingdom">United Kingdom</mat-option>
            <mat-option value="United States">United States</mat-option>
            <mat-option value="United States Minor Outlying Islands">United States Minor Outlying Islands</mat-option>
            <mat-option value="Uruguay">Uruguay</mat-option>
            <mat-option value="Uzbekistan">Uzbekistan</mat-option>
            <mat-option value="Vanuatu">Vanuatu</mat-option>
            <mat-option value="Venezuela">Venezuela</mat-option>
            <mat-option value="Viet Nam">Viet Nam</mat-option>
            <mat-option value="Virgin Islands, British">Virgin Islands, British</mat-option>
            <mat-option value="Virgin Islands, U.S.">Virgin Islands, U.S.</mat-option>
            <mat-option value="Wallis and Futuna">Wallis and Futuna</mat-option>
            <mat-option value="Western Sahara">Western Sahara</mat-option>
            <mat-option value="Yemen">Yemen</mat-option>
            <mat-option value="Zambia">Zambia</mat-option>
            <mat-option value="Zimbabwe">Zimbabwe</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-button cdkFocusInitial (click)="dialogClose(0)">Cancel</button>
      <button mat-raised-button color="primary" (click)="addPoint()" [disabled]="postcode == null || postcode == '' || city == null || city == '' || country == null || country == ''">ADD POINT</button>
    </div>
  `,
  styleUrls: ['./organize.component.scss']
})
export class DialogAddPoint {

  constructor(public dialogRef: MatDialogRef<DialogAddPoint>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data){}

  api_token: any;
  orders_api: any;

  postcode: any;
  city: any;
  country: any;
  route_id: any;

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.route_id = this.data.route_id;
  }

  addPoint(){
    this.orders_api = this.api.addPoint(this.api_token, this.postcode, this.city, this.country, this.route_id);
    this.orders_api.subscribe(data => {
      this.dialogClose(1);
    }, err => {
      console.log(err);
    });
  }

  dialogClose(refresh){ //0 - no refresh data; 1-refresh data
    this.dialogRef.close({refresh: refresh});
  }

}


export interface PeriodicElement {
  index: number;
  order_from: string;
  order_date: any;
  b2b: number;
  client: string;
  client_phone: string;
  client_email: string;
  linn_id: string;
  parent_linn_id: string;
  delivery_address_line_1: string;
  delivery_address_line_2: string;
  city: string;
  postcode: string;
  flag: string;
  country: string;
  source_number: number;
  source: string;
  model: string;
  color: string;
  route_point_distance: string,
  route_point_timespan: string,
  route_point_estimate_time: string
  all_order_info: any;
}

const ELEMENT_DATA: PeriodicElement[] = [

];

@Component({
  selector: 'dialog-generate-order',
  templateUrl: './more_informations.html',
  styleUrls: ['./organize.component.scss']
})
export class DialogGenerateOrder {

  displayedColumns: string[] = ['index', 'date', 'linn_id', 'postcode', 'country', 'source', 'model', 'address', 'calculations', 'actions'];
  dataSource = ELEMENT_DATA;

  constructor(public dialogRef: MatDialogRef<DialogGenerateOrder>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data, private snackBar: MatSnackBar, private _bottomSheet: MatBottomSheet){}

  api_token: any;
  orders: any;
  orders_api: any;
  driver: any;
  loading_date: any;
  send_email: any;

  shipping_cost: any = '';
  loading_place: any = null;

  ngOnInit() {
    this.dataSource = [];
    this.api_token = localStorage.getItem('api_token');
    this.orders = this.data.orders;
    // console.log(this.orders);
    // console.log(this.driver);
    this.driver = this.data.driver;
    this.loading_date = this.data.loading_date;
    this.send_email = this.data.send_email;

    this.ordersToTable();
  }

  dialogClose(){
    this.dialogRef.close();
  }

  ordersToTable(){
    let i = 1;
    for(let order of this.orders){
      this.dataSource.push({
        index: i,
        order_from: order.order_from,
        order_date: order.date,
        b2b: order.b2b,
        client: order.client,
        client_phone: order.client_phone,
        client_email: order.client_email,
        linn_id: order.linn_id,
        parent_linn_id: order.parent_linn_id,
        delivery_address_line_1: order.delivery_address_line_1,
        delivery_address_line_2: order.delivery_address_line_2,
        city: order.city,
        postcode: order.postcode,
        flag: order.flag,
        country: order.country,
        source_number: order.source.number,
        source: order.source.string,
        model: order.model.name+' '+order.model.color,
        color: order.color,
        route_point_distance: order.route_point_distance,
        route_point_timespan: order.route_point_timespan,
        route_point_estimate_time: order.route_point_estimate_time,
        all_order_info: order.all_order_info
      });
      i++;
    }
    // console.log(this.orders);
    // console.log(this.dataSource);
  }

  copyTable(){
    this.snackBar.open('Copied to clipboard', 'OK', {
      duration: 2000,
    });
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    let value = '';
    let i = 1;

    for(let order of this.orders){
      value += i+'. DATE: '+order.date+', ID: '+order.linn_id+', MODEL: '+order.model.name+' '+order.model.color+'\r\n';
      value += 'ADDRESS: \r\n';
      value += order.client+' \r\n';
      value += order.delivery_address_line_1+' \r\n';
      if(order.delivery_address_line_2){
        value += order.delivery_address_line_2+' \r\n';
      }
      value += order.postcode+' '+order.city+'\r\n';
      value += order.country+' \r\n';
      value += order.client_phone+' \r\n';
      if(order.all_order_info.cash == 1){
        value += '!!! CASH ON DELIVERY !!! \r\n \r\n';
      }else if(order.all_order_info.test == 1){
        value += '!!! TEST DRIVE / CASH ON DELIVERY !!! \r\n \r\n';
      }else if(order.all_order_info.rent == 1){
        value += '!!! RENTAL !!! \r\n \r\n';
      }else{
        value += '\r\n';
      }

      i++;
    }

    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  printDocument(type, order){
    this._bottomSheet.open(BottomSheetCollectPoint, {
      data: {
        type: type,
        order: order.all_order_info
      }
    });
  }

}
