import { Component, OnInit } from '@angular/core';
import { ApiCommunicationService } from '../api-communication.service';


@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})

export class StatisticsComponent implements OnInit {
  stats_api: any;
  stats: any;
  notify_api: any;
  notify: any;
  lauftech_orders: any;
  lunex_orders: any;
  api_token: any;

  loading_first: any = 0;
  loading_second: any = 0;

  constructor(public api: ApiCommunicationService) { }

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels = ['Nowe', 'Przyjęte', 'Gotowe', 'Wysłane', 'Dostarczone'];
  public barChartType = 'pie';
  public barChartLegend = true;
  public barChartData: any = [
    {data: [], label: 'Orders', backgroundColor: ['#ff6384', '#ff6384', '#ffb859', '#ffb859', '#74d572']}
  ];

  public barChartOptions_second = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels_second = ['Załadowane', 'Opóźnione załadunki', 'Dostarczone', 'Opóźnione dostawy', 'Otwarte bez opóźnienia', 'Otwarte i opóźnione'];
  public barChartType_second = 'pie';
  public barChartLegend_second = true;
  public barChartData_second: any = [
    {data: [], label: 'Orders', backgroundColor: ['#ffb859', '#de7f00', '#74d572', '#11870e', '#ff6384', '#da264c']}
  ];

  // test: any = [];

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.get_stats();
  }

  get_stats(){
    this.stats_api = this.api.get_stats(this.api_token);
    this.stats_api.subscribe(data => {
      this.stats = data.data;
      this.lauftech_orders = data.lauftech_orders;
      this.lunex_orders = data.lunex_orders;
      console.log(data);
      // for(let model of this.models){
      //   this.filter_model.push(model.model);
      // }
      this.barChartData[0]['data'].push(this.stats.ordered);
      this.barChartData[0]['data'].push(this.stats.in_progress);
      this.barChartData[0]['data'].push(this.stats.ready_to_send);
      this.barChartData[0]['data'].push(this.stats.sent);
      this.barChartData[0]['data'].push(this.stats.delivered);
      this.loading_first = 1;
      this.getNotify();
      // this.test();
    }, err => {
      console.log(err);
    });
  }

  getNotify(){
    this.notify_api = this.api.get_defects_for_notification(this.api_token);
    this.notify_api.subscribe(data => {
      this.notify=data;
      this.barChartData_second[0]['data'].push(this.stats.ready_to_send - data.delayed_loads_count);
      this.barChartData_second[0]['data'].push(data.delayed_loads_count);
      this.barChartData_second[0]['data'].push(this.stats.delivered - data.delayed_deliveries_count);
      this.barChartData_second[0]['data'].push(data.delayed_deliveries_count);
      this.barChartData_second[0]['data'].push(this.stats.open - data.unplanned_orders_count);
      this.barChartData_second[0]['data'].push(data.unplanned_orders_count);
      this.loading_second = 1;
    }, err => {
      console.log(err);
    });
  }

  test(){
    // for(let [key, value] of Object.entries(this.lauftech_orders)){
    //   console.log(key);
    // }
  }

}
