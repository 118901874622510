import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatBadgeModule} from '@angular/material/badge';
import {MatStepperModule} from '@angular/material/stepper';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { QRCodeModule } from 'angular2-qrcode';
import { SignaturePadModule } from 'angular2-signaturepad';
import { WebcamModule } from 'ngx-webcam';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HereMapsModule } from 'ng2-heremaps';
import { ApiCommunicationService } from './api-communication.service';
import { ChartsModule } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrdersComponent } from './orders/orders.component';
import { TrackingComponent } from './tracking/tracking.component';
import { LoginComponent } from './login/login.component';
import { DetailsComponent, DialogPaymentMethod } from './details/details.component';
import { MapdialogComponent } from './mapdialog/mapdialog.component';
import { BottomSheetOverviewExampleSheet } from './mapdialog/mapdialog.component';
import { BottomSheetCollectPoint } from './bottom-sheet-collect-point/bottom-sheet-collect-point.component';
import { BottomSheetConfirmPaid } from './orders/orders.component';
import { BottomSheetConfirmPaidLunexInvoice } from './orders/orders.component';
import { BottomSheetConfirmRefund } from './orders/orders.component';
import { BottomSheetInspectionStatus } from './orders/orders.component';
import { OrganizeComponent } from './organize/organize.component';
import { DialogAddRoute } from './organize/organize.component';
import { DialogEditRoute } from './organize/organize.component';
import { DialogChangeStatuses } from './organize/organize.component';
import { DialogAddPoint } from './organize/organize.component';
import { DialogGenerateOrder } from './organize/organize.component';
//import { BottomSheetCollectPointOrganize } from './organize/organize.component';
import { DetailsDialogStatus } from './details/details.component';
import { AddressHistory } from './details/details.component';
import { DialogBackOrderStatus } from './details/details.component';
import { DialogReportService } from './details/details.component';
import { DialogReportCollection } from './details/details.component';
import { DialogCustomEmail } from './details/details.component';
import { DocumentsComponent } from './documents/documents.component';
import { DocsNote } from './documents/documents.component';
import { DocsCmr } from './documents/documents.component';
import { DocsOrder } from './documents/documents.component';
import { DocsSheet } from './documents/documents.component';
import { DocsReturn } from './documents/documents.component';
import { DocsWarranty } from './documents/documents.component';
import { DocsVat } from './documents/documents.component';
import { DocsDvlaPack } from './documents/documents.component';
import { DocsDeclaration } from './documents/documents.component';
import { DocsApplication } from './documents/documents.component';
import { DocsDatasheet } from './documents/documents.component';
import { DocsQrcode } from './documents/documents.component';
import { DocsThanks } from './documents/documents.component';
import { DocsLauftechDeclaration } from './documents/documents.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { TrackOrderComponent } from './track-order/track-order.component';
import { DriversComponent } from './drivers/drivers.component';
import { EditDriver } from './drivers/drivers.component';
import { WarehouseComponent } from './warehouse/warehouse.component';
import { DialogSuccessAssembled } from './warehouse/warehouse.component';
import { DialogProblemAssembled } from './warehouse/warehouse.component';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { AvatarModule } from 'ngx-avatar';

import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';

const routes: Routes = [
  { path: '', redirectTo: 'track_order', pathMatch: 'full' },
  { path: 'orders', component: OrdersComponent },
  { path: 'details', component: DetailsComponent },
  { path: 'details-payment-method', component: DialogPaymentMethod },
  { path: 'tracking', component: TrackingComponent },
  { path: 'login', component: LoginComponent },
  { path: 'map', component: MapdialogComponent },
  { path: 'mapbottom', component: BottomSheetOverviewExampleSheet },
  { path: 'warehousebottom', component: BottomSheetCollectPoint},
  { path: 'confirmpaid', component: BottomSheetConfirmPaid},
  { path: 'confirmpaidlunexinvoice', component: BottomSheetConfirmPaidLunexInvoice},
  { path: 'confirmrefund', component: BottomSheetConfirmRefund},
  { path: 'inspectionstatus', component: BottomSheetInspectionStatus},
  { path: 'organize', component: OrganizeComponent },
  { path: 'services', component: OrganizeComponent },
  { path: 'dialogaddroute', component: DialogAddRoute },
  { path: 'dialogeditroute', component: DialogEditRoute },
  { path: 'dialogchangestatuses', component: DialogChangeStatuses },
  { path: 'dialogaddpoint', component: DialogAddPoint },
  { path: 'dialoggenerateorder', component: DialogGenerateOrder },
  //{ path: 'bottomsheetcollectpointorganize', component: BottomSheetCollectPointOrganize },
  { path: 'detailsdialogstatus', component: DetailsDialogStatus },
  { path: 'addresshistory', component: AddressHistory },
  { path: 'dialogbackorderstatus', component: DialogBackOrderStatus},
  { path: 'dialogreportservice', component: DialogReportService},
  { path: 'dialogreportcollection', component: DialogReportCollection},
  { path: 'dialogcustomemail', component: DialogCustomEmail},
  { path: 'editdriver', component: EditDriver },
  { path: 'documents', component: DocumentsComponent },
  { path: 'statistics', component: StatisticsComponent },
  { path: 'track_order', component: TrackOrderComponent },
  { path: 'drivers', component: DriversComponent },
  { path: 'warehouse', component: WarehouseComponent },
  { path: 'dialogsuccessassembled', component: DialogSuccessAssembled },
  { path: 'dialogproblemassembled', component: DialogProblemAssembled }
];

@NgModule({
  declarations: [
    AppComponent,
    OrdersComponent,
    TrackingComponent,
    LoginComponent,
    DetailsComponent,
    DialogPaymentMethod,
    MapdialogComponent,
    BottomSheetOverviewExampleSheet,
    BottomSheetCollectPoint,
    BottomSheetConfirmPaid,
    BottomSheetConfirmPaidLunexInvoice,
    BottomSheetConfirmRefund,
    BottomSheetInspectionStatus,
    OrganizeComponent,
    DialogAddRoute,
    DialogEditRoute,
    DialogChangeStatuses,
    DialogAddPoint,
    DialogGenerateOrder,
    //BottomSheetCollectPointOrganize,
    DetailsDialogStatus,
    AddressHistory,
    DialogBackOrderStatus,
    DialogReportService,
    DialogReportCollection,
    DialogCustomEmail,
    DocumentsComponent,
    DocsNote,
    DocsCmr,
    DocsOrder,
    DocsSheet,
    DocsReturn,
    DocsWarranty,
    DocsVat,
    DocsDvlaPack,
    DocsDeclaration,
    DocsDatasheet,
    DocsQrcode,
    DocsThanks,
    DocsLauftechDeclaration,
    StatisticsComponent,
    DocsApplication,
    TrackOrderComponent,
    DriversComponent,
    EditDriver,
    WarehouseComponent,
    DialogSuccessAssembled,
    DialogProblemAssembled
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(
      routes
    ),
    HttpClientModule,
    FormsModule,
    HereMapsModule.forRoot({
      apiKey: 'MH3fwlEgq87O55VMrBhJI09RVarQ0RtMr0f_-_e5ECQ',
      appId: 'dSoxRmY8ln82qJVHVuQk',
      apiVersion: '3.0',
      libraries: ['core', 'service']
    }),
    MatTooltipModule,
    MatToolbarModule,
    MatButtonModule,
    MatTableModule,
    MatListModule,
    MatDividerModule,
    MatCardModule,
    MatExpansionModule,
    MatChipsModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDialogModule,
    DragDropModule,
    MatBottomSheetModule,
    QRCodeModule,
    SignaturePadModule,
    WebcamModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatStepperModule,
    MatIconModule,
    MatMenuModule,
    MatTabsModule,
    MatRadioModule,
    MatProgressBarModule,
    ChartsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    NgxDropzoneModule,
    AvatarModule
  ],
  providers: [
    ApiCommunicationService,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

