import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { ApiCommunicationService } from '../api-communication.service';
import { DocumentsComponent } from '../documents/documents.component';


import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';

// import { DocumentsComponent } from '../documents/documents.component';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
    selector: 'bottom-sheet-collect_point',
    template: `
      <h2>Select collection point:</h2>
      <div *ngIf="loading_collections == 0">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
      <mat-nav-list *ngIf="loading_collections==1">
        <div *ngFor="let point of points">
          <mat-list-item (click)="printDocument(type, order, [point.id], [point])">
            <span mat-line>{{point.city}}</span>
            <span mat-line>{{point.name}}</span>
          </mat-list-item>
        </div>
      </mat-nav-list>
    `,
  })
  export class BottomSheetCollectPoint {
    type: any;
    order: any;
    orders_api: any;
    points_api: any;
    points: any;
    loading_collections: any;
    api_token: any;
    order_api: any;

    constructor(public api: ApiCommunicationService,
                private _bottomSheetRef: MatBottomSheetRef<BottomSheetCollectPoint>,
                public dialog: MatDialog, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private changeDetectorRef: ChangeDetectorRef) {
      this.type = data.type;
      this.order = data.order;
      console.log("ORDER TO PRINT: ");
      console.log(this.order);
      this.api_token = localStorage.getItem('api_token');
      // this.order_api = this.api.getOrderInfo(this.api_token, this.order.linn_id);
      // this.order_api.subscribe(data => {
      //   console.log("ORDER FROM API:");
      //   console.log(data.data);
      //   this.order = data.data;
      // });
    }

    ngOnInit() {

      this.loading_collections = 0;
      this.getCollectionPoints();
    }

    getCollectionPoints(){
      this.points_api = this.api.getCollectPoints(this.api_token);
      this.points_api.subscribe(data => {
        this.points = data.data;
        // console.log(this.points);
        this.loading_collections = 1;
        // console.log(this.loading_collections);
        this.changeDetectorRef.detectChanges();
      }, err => {
        console.log(err);
      });
    }

    printDocument(type, order, collection, point){
      //Order type:
      // 1 - Transport order
      // 2 - Dropshipping

      //Collection:
      // 1 - Lunex Technology LTD.
      // 2 - VELECO Sp. z o.o.
      // 3 - TRICOVELOS S.L.

      // console.log('ORDER:');
      // console.log(order);
      let order_details = order;
      // console.log("No edit:");
      // console.log(order_details);
      order_details.collect_point = point[0];
      order_details.collect_from = collection[0];
      if(order.order_from == 'Lauftech'){
        order_details.type = {number: 1, string: "Transport order"};
      }else{
        if (collection[0] == 3) {
          if (order_details.order_from == 'Tricovelo') {
            order_details.type = {number:1, string: "Transport order"};
          } else {
            order_details.type = {number: 2, string: "Dropshipping"};
          }
        } else {
          if(collection[0] == 1){
            order_details.type = {number: 1, string: "Transport order"};
          }else{
            order_details.type = {number: 2, string: "Dropshipping"};
          }
        }
      }
      this.updateOrder(collection[0], order_details.type.number);
      // console.log("Edit:");
      // console.log(order_details);
      // console.log(point);
      this._bottomSheetRef.dismiss();
      this.dialog.open(DocumentsComponent, {
        data: {
          type: type,
          order: order_details
        }
      });
    }

    updateOrder(collect_point, type){
      console.log('UPDATEORDER');
      console.log(collect_point);
      console.log(type);
      console.log('UPDATEORDER');
      this.orders_api = this.api.update_collection_point(this.api_token, this.order.linn_id, collect_point, type);
      this.orders_api.subscribe(data => {
        console.log('updated');
      }, err => {
        console.log(err);
      });
    }
  }
