import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { ApiCommunicationService } from '../api-communication.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {

  loading: any = 0;
  orders_api: any;
  orders: any;
  api_token: any;
  perm: any;
  time = new Date();
  week_day: any;
  month_day: any;
  current_month: any;
  current_year: any;
  unassembled: any;
  assembled_today: any;

  constructor(public api: ApiCommunicationService, public dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.perm = localStorage.getItem('user_perm');
    this.getOrders(0, 200, 'order_date', 'asc');

    setInterval(() => {
       this.time = new Date();
       this.week_day = this.time.getDay();
       this.month_day = this.time.getDate();
       this.current_month = this.time.getMonth();
       this.current_year = this.time.getFullYear();
    }, 1000);
  }

  getOrders(index, limit, column, direction){
    this.loading=0;
    this.orders_api =
    this.api.getOrders(this.api_token, index, limit, ["0", "1", "2"], column, direction, null, null, null, null, 1, null, null, null, ["0", "2"], null);
    this.orders_api.subscribe(data => {
      this.orders = data.data;
      this.unassembled = data.unassembled;
      this.assembled_today = data.assembled_today;
      this.loading=1;
    }, err => {
      this.loading=1;
      console.log(err);
    });
  }

  setAssembled(status, linn_id){
    let dialog;

    if(status == 1){
      dialog = DialogSuccessAssembled;
    }else if(status == 2){
      dialog = DialogProblemAssembled;
    }

    const dialogRef =  this.dialog.open(dialog, {
      data: {
        linn_id: linn_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getOrders(0, 200, 'order_date', 'asc');
    });
  }
}




@Component({
  selector: 'dialog-success-assembled',
  template: `
    <h1 mat-dialog-title *ngIf="loading == 1">Potwierdź złożenie</h1>
    <h1 mat-dialog-title *ngIf="loading == 0">Proszę czekać...</h1>
    <div mat-dialog-content>
      <mat-spinner diameter="30" *ngIf="loading == 0" style="display: block; margin: 0 auto;"></mat-spinner>
      <div *ngIf="loading == 1">
        <div>
          <mat-form-field style="width: 300px;">
            <mat-label>VIN pojazdu</mat-label>
            <input matInput placeholder="Wpisz numer VIN" [(ngModel)]="vin">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field style="width: 300px;">
            <mat-label>Osoba odpowiedzialna</mat-label>
            <input matInput placeholder="Imię i nazwisko" [(ngModel)]="responsible">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div mat-dialog-actions *ngIf="loading == 1">
      <button mat-button cdkFocusInitial (click)="dialogClose()">Anuluj</button>
      <button mat-raised-button color="primary" (click)="submitAssembled()">Potwierdź</button>
    </div>
  `,
})
export class DialogSuccessAssembled {

  linn_id: any;
  api_token: any;
  orders_api: any;
  loading: any = 1;
  vin: any = '';
  responsible: any = '';

  constructor(public dialogRef: MatDialogRef<DialogSuccessAssembled>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data, private snackBar: MatSnackBar){}

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.linn_id = this.data.linn_id;
  }

  submitAssembled(){
    if(this.vin != '' && this.vin != null && this.responsible != '' && this.responsible != null){
      this.loading = 0;
      this.orders_api = this.api.submitAssembled(this.api_token, this.linn_id, this.vin, this.responsible, 1);
      this.orders_api.subscribe(data => {
        this.loading = 1;
        if(data.status == true){
          this.snackBar.open('Pomyślnie zmieniono status zamówienia na: ZŁOŻONO', 'OK', {
            duration: 2000,
          });
          this.dialogClose();
          console.log('OK');
        }
      }, err => {
        console.log(err);
        this.loading = 1;
        this.snackBar.open('Wystąpił nieznany błąd. Skontaktuj się ze wsparciem technicznym.', 'OK', {
          duration: 2000,
        });
      });
    }else{
      this.snackBar.open('Prosimy uzupełnić wszystkie pola', 'OK', {
        duration: 2000,
      });
    }

  }

  dialogClose(){
    this.dialogRef.close();
  }

}



@Component({
  selector: 'dialog-problem-assembled',
  template: `
    <h1 mat-dialog-title *ngIf="loading == 1">Potwierdź złożenie</h1>
    <h1 mat-dialog-title *ngIf="loading == 0">Proszę czekać...</h1>
    <div mat-dialog-content>
      <mat-spinner diameter="30" *ngIf="loading == 0" style="display: block; margin: 0 auto;"></mat-spinner>
      <div *ngIf="loading == 1">
        Czy potwierdzasz brak tego modelu na stanie magazynowym?
      </div>
    </div>
    <div mat-dialog-actions *ngIf="loading == 1">
      <button mat-button cdkFocusInitial (click)="dialogClose()">Anuluj</button>
      <button mat-raised-button color="primary" (click)="submitAssembled()">Potwierdź</button>
    </div>
  `,
})
export class DialogProblemAssembled {

  linn_id: any;
  api_token: any;
  orders_api: any;
  loading: any = 1;

  constructor(public dialogRef: MatDialogRef<DialogProblemAssembled>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data, private snackBar: MatSnackBar){}

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.linn_id = this.data.linn_id;
  }

  submitAssembled(){
    this.loading = 0;
    this.orders_api = this.api.submitAssembled(this.api_token, this.linn_id, '', '', 2);
    this.orders_api.subscribe(data => {
      this.loading = 1;
      if(data.status == true){
        this.snackBar.open('Pomyślnie zmieniono status zamówienia na: BRAK NA STANIE', 'OK', {
          duration: 2000,
        });
        this.dialogClose();
        console.log('OK');
      }
    }, err => {
      console.log(err);
      this.loading = 1;
      this.snackBar.open('Wystąpił nieznany błąd. Skontaktuj się ze wsparciem technicznym.', 'OK', {
        duration: 2000,
      });
    });
  }

  dialogClose(){
    this.dialogRef.close();
  }

}
