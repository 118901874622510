import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Inject, HostListener, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ApiCommunicationService } from '../api-communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import * as moment from 'moment';
import {Observable, Subject} from 'rxjs';
import { WebcamModule, WebcamImage, WebcamInitError } from 'ngx-webcam';
import { BottomSheetCollectPoint } from '../bottom-sheet-collect-point/bottom-sheet-collect-point.component';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';

declare var H: any;

export interface PeriodicElement {
  date: string;
  responsible: string;
  event: string;
  description: string;
}

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',

  styleUrls: ['./details.component.scss']
})

export class DetailsComponent implements OnInit, AfterViewInit {

  @ViewChild(SignaturePad, {static: false}) signaturePad: SignaturePad;
  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 0.5,
    'maxWidth': 2.5,
    'canvasWidth': 350,
    'canvasHeight': 350,
    'backgroundColor': 'rgb(255, 255, 255)'
  };

  displayedColumns: string[] = ['date', 'responsible', 'event'];
  dataSource: any;
  tracking_id: any;
  qr_code: any;
  update_api: any;
  orders_api: any;
  order_api: any;
  order: any;
  loading: any = 0;
  filter_status: any;
  ui: any;
  color_class: any;
  progress_bar_class: any;
  geo_api: any;
  details_edit: any = 0;
  address_edit: any = 0;
  show_report: any = 0;

  paymentMethod: any;

  services: any;

  api_token: any;
  perm: any;
  user_email: any;

  editdata: any = {
    order_date: '',
    order_from: '',
    collect_from: '',
    linn_id: '',
    source: '',
    channel_reference: '',
    price: '',
    currency: '',
    scooter: '',
    type: '',
    warehouse: '',
    public_comment: '',
    ecommerce_comment: '',
    warehouse_comment: '',
    important: 0,
    linn_note: '',
    this_risk_of_receiving_negative_feedback: 0,
  };

  editaddress: any = {
    client: '',
    delivery_address_line_1: '',
    delivery_address_line_2: '',
    delivery_postcode: '',
    delivery_city: '',
    delivery_country: '',
    client_email: '',
    client_phone: ''
  }

  delivery_step: any = 1;
  deliveryStep: any = {
    responsible: '',
    driver_phone: ''
  }
  deliveryStepLoading: any;
  deliverySignature: any;
  webcamImage: any;
  webcamImageSecond: any;

  service_note: any;
  collection_note: any;
  collection_service_note: any;

  deliveryStepLoadingUpload: any;

  attempt: any = [{
    description: '',
    photo: '',
    signature: ''
  }];

  edit_dates: any = [{
    inprogress: '',
    readytosend: '',
    sent: '',
    delivery: ''
  }];

  send_email: any = {
    qr_code: true,
    cancel: true
  };

  send_email_status: any = true;

  camera_error: any = 0;

  points_api: any;
  points: any;

  @ViewChild("map", { static: false }) public mapElement: ElementRef;
  @ViewChild("webcam", { static: false }) public camElement: any;
  @ViewChild("webcamsecond", { static: false }) public camElementSecond: any;
  @ViewChild("webcamdriver", { static: false }) public camElementDriver: any;
  @ViewChild("upload1", { static: false }) public fileInput1: ElementRef;
  @ViewChild("upload2", { static: false }) public fileInput2: ElementRef;

  public lat: any = '50.82471';
  public lng: any = '19.094158';

  public width: any = '1000px';
  public height: any = '600px';

  private platform: any;
  private map: any;

  private _appId: string = 'dSoxRmY8ln82qJVHVuQk';
  private _appCode: string = 'MH3fwlEgq87O55VMrBhJI09RVarQ0RtMr0f_-_e5ECQ';

  private trigger: Subject<void> = new Subject<void>();

  location_host='';

  constructor(private route: ActivatedRoute, private snackBar: MatSnackBar, public dialog: MatDialog, public api: ApiCommunicationService, private changeDetectorRef: ChangeDetectorRef, private _bottomSheet: MatBottomSheet) {
    this.platform = new H.service.Platform({
        "app_id": "dSoxRmY8ln82qJVHVuQk",
        "app_code": "MH3fwlEgq87O55VMrBhJI09RVarQ0RtMr0f_-_e5ECQ",
        useHTTPS: true
    });
    this.location_host=document.location.host;
  }

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.perm = localStorage.getItem('user_perm');
    this.user_email = localStorage.getItem('user_email');

    this.route.queryParams.subscribe(params => {
      this.tracking_id = params['tracking_id'];
      this.qr_code = params['qr_code'];
      if(params['send_email'] != null && params['send_email'] != ''){
        this.send_email_status = params['send_email'];
      }
      if(this.tracking_id != null){
        this.getOrderInfo(this.tracking_id, 1);
      }else if(this.qr_code != null){
        this.getOrderLinn();
        this.signaturePad.set('minWidth', 5);
        this.signaturePad.clear();
      }
    });
    this.platform = new H.service.Platform({
      "app_id": this._appId,
      "app_code": this._appCode,
      useHTTPS: true
    });

    this.getCollectionPoints();
  }

  getCollectionPoints(){
    this.points_api = this.api.getCollectPoints(this.api_token);
    this.points_api.subscribe(data => {
      this.points = data.data;
      this.changeDetectorRef.detectChanges();
    }, err => {
      console.log(err);
    });
  }

  ngAfterViewInit(){
    this.onResize();
  }

  sendEmail(email){
    if(email == 'qr_code'){
      this.send_email.qr_code = !this.send_email.qr_code;
    }else if(email == 'cancel'){
      this.send_email.cancel = !this.send_email.cancel;
    }
  }

  @HostListener('window:resize', ['$event'])
   onResize(event?: Event) {
     const win = !!event ? (event.target as Window) : window;
     if(this.delivery_step == 3){
       this.camElement.width = win.innerWidth;
     }else if(this.delivery_step == 6){
       this.camElementSecond.width = win.innerWidth;
       console.log(this.camElementSecond);
     }else if(this.delivery_step == 12){
       this.camElementDriver.width = win.innerWidth;
       console.log(this.camElementDriver);
     }
   }

  getOrderLinn(){
    let api_token = 'NONE';
    this.order_api = this.api.getOrderLinn(api_token, this.qr_code);
    this.order_api.subscribe(data => {
      this.order = data.data;
      this.loading = 1;
    }, err => {
      console.log(err);
      this.loading = 1;
    });
  }

  nextDeliveryStep(){
    this.delivery_step++;
    if(this.delivery_step == 3 || this.delivery_step == 6 || this.delivery_step == 12){
      this.camera_error = 0;
      setTimeout(()=>{
        this.onResize();
      }, 1000);
    }
  }

  prevDeliveryStep(){
    this.delivery_step--;
    if(this.delivery_step == 3 || this.delivery_step == 6 || this.delivery_step == 12){
      this.camera_error = 0;
      setTimeout(()=>{
        this.onResize();
      }, 1000);
    }
  }

  setStep(step){
    this.delivery_step = step;
    if(this.delivery_step == 3 || this.delivery_step == 6 || this.delivery_step == 12){
      this.camera_error = 0;
      setTimeout(()=>{
        this.onResize();
      }, 1000);
    }
  }

  getBase64(event, el) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if(el == 1){
        this.webcamImage = {imageAsDataUrl: reader.result};
        console.log('1');
        console.log(reader.result);
        this.delivery_step++;
      }else if(el == 2){
        this.webcamImageSecond = {imageAsDataUrl: reader.result};
        console.log('2');
        console.log(reader.result);
        this.delivery_step++;
      }
    };
  }

  clickUpload(el){
    if(el == 1){
      this.fileInput1.nativeElement.click();
    }else if(el == 2){
      this.fileInput2.nativeElement.click()
    }
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.log('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
  }

  public handleImageSecond(webcamImage: WebcamImage): void {
    console.log('received webcam image', webcamImage);
    this.webcamImageSecond = webcamImage;
  }

  public handleImageDriver(webcamImage: WebcamImage): void {
    console.log('received webcam image', webcamImage);
    this.attempt.photo = webcamImage;
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      this.camera_error = 1;
      console.warn("Camera access was not allowed by user!");
    }
  }

  public triggerSnapshot(): void {
    this.trigger.next();
    this.delivery_step++;
  }

  public get triggerObservable(): Observable<void> {
   return this.trigger.asObservable();
 }

  drawComplete() {
    this.deliverySignature = this.signaturePad.toDataURL();
  }

  drawCompleteDriver() {
    this.attempt.signature = this.signaturePad.toDataURL();
  }

  drawStart() {
    console.log('begin drawing');
  }

  clear(){
    this.signaturePad.clear();
  }

  sendStep(step){
    if(step == 1){
      if(this.deliveryStep.responsible != null && this.deliveryStep.responsible != ''){
        if( (this.order.driver_id == 0 && (this.deliveryStep.driver_phone != null && this.deliveryStep.driver_phone != '') ) || this.order.driver_id != 0 ){
          this.nextDeliveryStep();
          this.deliveryStepLoading = 0;
          let api_token = 'NONE';
          this.order_api = this.api.send_step(api_token, this.qr_code, this.deliveryStep.responsible, this.send_email_status);
          this.order_api.subscribe(data => {
            this.deliveryStepLoading = 1;
          }, err => {
            console.log(err);
          });
        }else{
          this.snackBar.open('Podaj numer telefonu kierowcy', 'OK', {
            duration: 2000,
          });
        }
      }else{
        this.snackBar.open('Uzupełnij pole "Imię i nawisko"', 'OK', {
          duration: 2000,
        });
      }
    }else if(step == 2){

    }
  }

  changeOrderStatusDelivered(){
    this.deliveryStepLoadingUpload = 0;
    let api_token = 'NONE';
    this.order_api = this.api.delivered_step(api_token, this.deliverySignature, this.webcamImage.imageAsDataUrl, this.webcamImageSecond.imageAsDataUrl, this.qr_code, this.send_email_status);
    this.order_api.subscribe(data => {
      this.deliveryStepLoadingUpload = 1;
      this.nextDeliveryStep();
    }, err => {
      console.log(err);
    });
  }

  sendAttempt(){
    this.deliveryStepLoadingUpload = 0;
    let api_token = 'NONE';
    this.order_api = this.api.add_attempt(api_token, this.attempt.description, this.attempt.signature, this.attempt.photo.imageAsDataUrl, this.qr_code);
    this.order_api.subscribe(data => {
      this.deliveryStepLoadingUpload = 1;
      this.nextDeliveryStep();
    }, err => {
      console.log(err);
    });
  }


  getOrderInfo(tracking_id, map_status){
    console.log('GET ORDER INFO');
    if(this.mapElement != null){
      this.mapElement.nativeElement.innerHTML = '';
    }
    console.log('clear map!');
    console.log(this.api_token);
    const ELEMENT_DATA: PeriodicElement[] = [];
    this.order_api = this.api.getOrderInfo(this.api_token, tracking_id);
    this.order_api.subscribe(data => {
      this.order = data.data;
      console.log("ORDER:");
      console.log(data.data);
      let scooter_color = data.data.scooter_model.color;
      let color_class = '';
      if(scooter_color == 'Black'){color_class = 'scooter_black';}
      else if(scooter_color == 'Silver'){color_class = 'scooter_silver';}
      else if(scooter_color == 'White'){color_class = 'scooter_white';}
      else if(scooter_color == 'Red'){color_class = 'scooter_red';}
      else if(scooter_color == 'Blue'){color_class = 'scooter_blue';}
      else if(scooter_color == 'Gray'){color_class = 'scooter_gray';}
        else{color_class = 'scooter_color_unknow';}
      this.color_class = color_class;
      this.order.color_class = color_class;

      if(this.order.driver != null){
        this.order.driver.avatar = this.api.api_url+"../storage/app/avatars/"+this.order.driver.avatar;
      }


      // ORDER STATUS PROGRESS BAR start
      // status 0 percents:
      //   5%, 10%, 15%, 20%
      // status 1 percents:
      //   30%, 35%, 40%
      // status 2 percents:
      //   50%, 55%, 60%
      // status 3 percents:
      //   70%, 75%, 80%, 85%, 90%, 95%
      // status 4 percents:
      //   100%

        let from  = moment().format('DD/MM/YYYY H:i:s');
        let to = this.order.order_date.date+' '+this.order.order_date.hour;
        let ms = moment(from,"DD/MM/YYYY H:i:s").diff(moment(to,"DD/MM/YYYY H:i:s"));
        let diff_h = ms/1000/60/60;
        console.log(diff_h);
        if(this.order.trackpack_status == 0){ //Ordered (24h / 1d)
          if(diff_h < 6)
            this.progress_bar_class = 'progress_0_5';
          else if(diff_h < 12)
            this.progress_bar_class = 'progress_0_10';
          else if(diff_h < 18)
            this.progress_bar_class = 'progress_0_15';
          else
            this.progress_bar_class = 'progress_0_20';
        }else if(this.order.trackpack_status == 1){ //In progress (120h / 5d)

          //W KOLEJNYCH STATUSACH OBLICZYĆ RÓŻNICĘ MIĘDZY DATĄ ZMIANY POPRZEDNIEGO STATUSU, A OBECNĄ CHWILĄ

          this.progress_bar_class = 'progress_1_30';
          // this.progress_bar_class = 'progress_1_35';
          // this.progress_bar_class = 'progress_1_40';
        }else if(this.order.trackpack_status == 2){ //Ready to send (24h / 1d)
          // this.progress_bar_class = 'progress_2_50';
          this.progress_bar_class = 'progress_2_55';
          // this.progress_bar_class = 'progress_2_60';
        }else if(this.order.trackpack_status == 3){ //Sent (96h / 4d)
          // this.progress_bar_class = 'progress_3_70';
          // this.progress_bar_class = 'progress_3_75';
          // this.progress_bar_class = 'progress_3_80';
          this.progress_bar_class = 'progress_3_85';
          // this.progress_bar_class = 'progress_3_90';
          // this.progress_bar_class = 'progress_3_95';
        }else if(this.order.trackpack_status == 4){ //Delivered
          this.progress_bar_class = 'progress_4_100';
        }
      // ORDER STATUS PROGRESS BAR end

      if(this.order.in_progress_date != null){
        this.edit_dates.inprogress = moment(this.order.in_progress_date.date, 'DD/MM/YYYY').format();
      }
      if(this.order.ready_to_send_date != null){
        this.edit_dates.readytosend = moment(this.order.ready_to_send_date.date, 'DD/MM/YYYY').format();
      }
      if(this.order.date_of_shipment != null){
        this.edit_dates.sent = moment(this.order.date_of_shipment.date, 'DD/MM/YYYY').format();
      }
      if(this.order.delivery_date != null){
        this.edit_dates.delivery = moment(this.order.delivery_date.date, 'DD/MM/YYYY').format();
      }

      //HISTORY
      ELEMENT_DATA.push(
        {date: this.order.order_date, responsible: this.order.client, event: 'Ordered', description: ''}
      );

      console.log(this.order.order_date);

      for(let el of this.order.history_events){
        ELEMENT_DATA.push(
          {date: el.date, responsible: el.responsible_user.name+' '+el.responsible_user.surname, event: el.event, description: el.description}
        );
      }

      this.dataSource = ELEMENT_DATA;

      if(map_status == 1){
        this.getGeolocalization(this.order.delivery_address_line_1, this.order.delivery_postcode, this.order.delivery_city, this.order.delivery_country);
      }
      this.getOrderServices();
      this.loading=1;
    }, err => {
      this.loading=1;
      console.log(err);
    });
  }

  getGeolocalization(street, postcode, city, country){
    console.log(street);
    console.log(postcode+' postcode | city '+city+' | ctr: '+country);
    console.log("POBIERANIE LOKALIZACJI");
    this.geo_api = this.api.getGeolocalization(postcode+', '+city, street, postcode, city, country);
    // this.geo_api = this.api.getGeolocalization(postcode);
    this.geo_api.subscribe(data => {
      let lat = data['items'][0]['position']['lat'];
      let lng = data['items'][0]['position']['lng'];
      if(lat != null && lng != null){
        this.lat = lat;
        this.lng = lng;

        console.log('LAT: '+lat+'; LNG: '+lng);

        let pixelRatio = window.devicePixelRatio || 1;
        let defaultLayers = this.platform.createDefaultLayers({
          tileSize: pixelRatio === 1 ? 256 : 512,
          ppi: pixelRatio === 1 ? undefined : 320
        });

        this.map = new H.Map(this.mapElement.nativeElement,
          defaultLayers.normal.map, { pixelRatio: pixelRatio });

        var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
        var ui = H.ui.UI.createDefault(this.map, defaultLayers);

        this.map.setCenter({ lat: this.lat, lng: this.lng });
        this.map.setZoom(5);

        this.dropMarker({"lat": this.lat, "lng": this.lng}, {"title": "Testowy Marker", "vicinity": "Test"});
      }
      console.log(data);
    }, err => {
      console.log(err);
    });
  }

  private dropMarker(coordinates: any, data: any) {
    console.log(coordinates);
    let marker = new H.map.Marker(coordinates);
    marker.setData("<p>" + data.title + "<br>" + data.vicinity + "</p>");
    marker.addEventListener('tap', event => {
      let bubble = new H.ui.InfoBubble(event.target.getPosition(), {
        content: event.target.getData()
      });
      this.ui.addBubble(bubble);
    }, false);
    this.map.addObject(marker);
  }

  copyValue(value){
    this.snackBar.open('Copied to clipboard', 'OK', {
      duration: 2000,
    });
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  changeStatus() {
    const dialogRef =  this.dialog.open(DetailsDialogStatus, {
      data: {
        linn_id: this.order.linn_id,
        status: this.order.trackpack_status,
        loading_date: this.order.loading_date,
        expected_delivery_date_from: this.order.expected_delivery_date_from,
        expected_delivery_date_to: this.order.expected_delivery_date_to,
        driver: this.order.driver_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getOrderInfo(this.tracking_id, 0);
    });
  }

  detailsEdit(status){
    this.details_edit = status;
    if(status == 1){
      this.editdata.order_date = moment(this.order.order_date.date, 'DD/MM/YYYY').format();
      if(this.order.order_from == 'Lunex'){
        this.editdata.order_from = '1';
      }else if(this.order.order_from == 'Lauftech'){
        this.editdata.order_from = '2';
      }
      this.editdata.collect_from = this.order.collect_from;
      this.editdata.linn_id = this.order.linn_id;
      this.editdata.source = ''+this.order.source.number+'';
      this.editdata.channel_reference = this.order.channel_reference;
      this.editdata.price = this.order.price;
      this.editdata.currency = ''+this.order.currency+'';
      this.editdata.scooter = this.order.scooter_model.value;
      this.editdata.type = ''+this.order.type.number+'';
      this.editdata.important = this.order.important;
      this.editdata.this_risk_of_receiving_negative_feedback = this.order.this_risk_of_receiving_negative_feedback;

      console.log(this.order.scooter_model);


      if(this.order.cash == 1){
        this.paymentMethod = 'cash';
      }else if(this.order.rent == 1){
        this.paymentMethod = 'rent';
      }else if(this.order.test == 1){
        this.paymentMethod = 'test';
      }else{
        this.paymentMethod = 'card';
      }

      let public_comment;
      if(this.order.public_comment == null){
        public_comment = '';
      }else{
        public_comment = this.order.public_comment;
      }
      this.editdata.public_comment = public_comment;

      let ecommerce_comment;
      if(this.order.ecommerce_comment == null){
        ecommerce_comment = '';
      }else{
        ecommerce_comment = this.order.ecommerce_comment;
      }
      this.editdata.ecommerce_comment = ecommerce_comment;

      let warehouse_comment;
      if(this.order.warehouse_comment == null){
        warehouse_comment = '';
      }else{
        warehouse_comment = this.order.warehouse_comment;
      }
      this.editdata.warehouse_comment = warehouse_comment;

      let linn_note;
      if(this.order.linn_note == null){
        linn_note = '';
      }else{
        linn_note = this.order.linn_note;
      }

      this.editdata.linn_note = linn_note;
    }

    this.changeDetectorRef.detectChanges();
  }


  detailsUpdate(){
    if (this.editdata.linn_note!=this.order.linn_note) {
      let dialogRef = this.dialog.open(DialogPaymentMethod, {
        data: {
          linn_id: this.order.linn_id,
          paymentMethod: this.paymentMethod
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        this.paymentMethod = result;
        this.setDetailsUpdate();
      });
    } else {
      this.setDetailsUpdate();
    }

  }

  setDetailsUpdate() {
    let cash = 0;
    let rent = 0;
    let test = 0;

    if(this.paymentMethod == 'cash'){
      cash = 1;
    }else if(this.paymentMethod == 'rent'){
      rent = 1;
    }else if(this.paymentMethod == 'test'){
      test = 1;
    }


    this.update_api = this.api.update_order_details(this.api_token,
                                                    this.order.id,
                                                    this.editdata.linn_id,
                                                    moment(this.editdata.order_date).format('YYYY-MM-DD'),
                                                    this.editdata.order_from,
                                                    this.editdata.collect_from,
                                                    this.editdata.source,
                                                    this.editdata.channel_reference,
                                                    this.editdata.price,
                                                    this.editdata.currency,
                                                    this.editdata.scooter,
                                                    this.editdata.type,
                                                    this.editdata.warehouse,
                                                    this.editdata.public_comment,
                                                    this.editdata.ecommerce_comment,
                                                    this.editdata.warehouse_comment,
                                                    this.editdata.linn_note,
                                                    test,
                                                    cash,
                                                    rent,
                                                    this.editdata.important,
                                                    this.editdata.this_risk_of_receiving_negative_feedback);
    this.update_api.subscribe(data => {
      if(data.status == true){
        this.details_edit = 0;
        this.getOrderInfo(this.editdata.linn_id, 0);
        this.snackBar.open('Order details has been updated', 'OK', {
          duration: 2000,
        });
      }
    }, err => {
      console.log(err);
      this.snackBar.open('Unknown error, please contact with technical support', 'OK', {
        duration: 2000,
      });
    });
  }

  addressEdit(status){
    this.address_edit = status;
    if(status == 1){
      this.editaddress.client = this.order.client,
      this.editaddress.delivery_address_line_1 = this.order.delivery_address_line_1,
      this.editaddress.delivery_address_line_2 = this.order.delivery_address_line_2,
      this.editaddress.delivery_postcode = this.order.delivery_postcode,
      this.editaddress.delivery_city = this.order.delivery_city,
      this.editaddress.delivery_country = this.order.delivery_country,
      this.editaddress.client_email = this.order.client_email,
      this.editaddress.client_phone = this.order.client_phone,
      this.editaddress.linn_id = this.order.linn_id;
    }
  }


  addressUpdate(){
    this.update_api = this.api.update_order_address(this.api_token, this.order.id, this.editaddress.client, this.editaddress.delivery_address_line_1, this.editaddress.delivery_address_line_2, this.editaddress.delivery_postcode, this.editaddress.delivery_city, this.editaddress.delivery_country, this.editaddress.client_email, this.editaddress.client_phone, this.editaddress.linn_id, this.order.public_comment, this.order.ecommerce_comment, this.order.warehouse_comment);
    this.update_api.subscribe(data => {
      if(data.status == true){
        this.address_edit = 0;
        this.getOrderInfo(this.editaddress.linn_id, 0);
        this.snackBar.open('Delivery details has been updated', 'OK', {
          duration: 2000,
        });
      }
    }, err => {
      console.log(err);
      this.snackBar.open('Unknown error, please contact with technical support', 'OK', {
        duration: 2000,
      });
    });
  }

  disabled(is_active){
    this.update_api = this.api.change_order_activity_status(this.api_token, this.order.linn_id, is_active, this.send_email.cancel);
    this.update_api.subscribe(data => {
      console.log(this.send_email.cancel);
      if(data.status == true){
        this.address_edit = 0;
        if(is_active == 0){
          this.snackBar.open('You canceled the order', 'OK', {
            duration: 2000,
          });
        }else if(is_active == 1){
          this.snackBar.open('You have restored the order', 'OK', {
            duration: 2000,
          });
        }
        this.getOrderInfo(this.tracking_id, 0);
      }
    }, err => {
      console.log(err);
      this.snackBar.open('Unknown error, please contact with technical support', 'OK', {
        duration: 2000,
      });
    });
  }

  showReport(status){
    this.show_report = status;
  }

  scrollToElement($element): void {
    setTimeout(function(){
      console.log($element);
      $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, 500);
  }

  printDocument(type){
    let print_order = JSON.parse(JSON.stringify(this.order));
    print_order.order_date = this.order.order_date.date;
    let scooter_color = this.order.scooter_model.color;
    let color_class = '';
    if(scooter_color == 'Black'){color_class = 'scooter_black';}
    else if(scooter_color == 'Silver'){color_class = 'scooter_silver';}
    else if(scooter_color == 'White'){color_class = 'scooter_white';}
    else if(scooter_color == 'Red'){color_class = 'scooter_red';}
    else if(scooter_color == 'Blue'){color_class = 'scooter_blue';}
    else if(scooter_color == 'Gray'){color_class = 'scooter_gray';}
      else{color_class = 'scooter_color_unknow';}
    this.color_class = color_class;
    this.order.color_class = color_class;

    this._bottomSheet.open(BottomSheetCollectPoint, {
      data: {
        type: type,
        order: print_order
      }
    });
  }

  report_service(){
    const dialogRef =  this.dialog.open(DialogReportService, {
      data: {
        linn_id: this.order.linn_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getOrderServices();
    });
  }

  addNote(linn_id, note){
    if(note != null && note != ''){
      this.update_api = this.api.add_order_message(this.api_token, linn_id, note);
      this.update_api.subscribe(data => {
        this.snackBar.open('Pomyślnie dodano notatkę', 'OK', {
          duration: 2000,
        });
        this.service_note = '';
        this.collection_note = '';
        this.collection_service_note = '';
        this.getOrderServices();
      }, err => {
        console.log(err);
      });
    }else{
      this.snackBar.open('Notatka nie może być pusta!', 'OK', {
        duration: 2000,
      });
    }
  }

  report_collection(){
    const dialogRef =  this.dialog.open(DialogReportCollection, {
      data: {
        linn_id: this.order.linn_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getOrderServices();
    });
  }

  custom_email(){
    const dialogRef =  this.dialog.open(DialogCustomEmail, {
      data: {
        email: this.order.client_email,
        id: this.order.id,
        linn_id: this.order.linn_id,
        order_from: this.order.order_from,
        source: this.order.source
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getOrderServices();
    });
  }

  getOrderServices(){
    this.update_api = this.api.getOrderServices(this.api_token, this.order.linn_id);
    this.update_api.subscribe(data => {
      if(data.status == true){
        this.services = data;
        console.log(this.services);
      }
    }, err => {
      console.log(err);
    });
  }

  serviceStatus(linn_id, type){
    if(type == 1){
      if(this.service_note != '' && this.service_note != null){
        this.serviceStatusConfirm(linn_id);
        this.addNote(linn_id, this.service_note);
      }else{
        this.snackBar.open('Uzupełnij pole notatki przed podjęciem działania', 'OK', {
          duration: 2000,
        });
      }
    }else if(type == 2){
      if(this.collection_note != '' && this.collection_note != null){
        this.serviceStatusConfirm(linn_id);
        this.addNote(linn_id, this.collection_note);
      }else{
        this.snackBar.open('Uzupełnij pole notatki przed podjęciem działania', 'OK', {
          duration: 2000,
        });
      }
    }else if(type == 3){
      if(this.collection_service_note != '' && this.collection_service_note != null){
        this.serviceStatusConfirm(linn_id);
        this.addNote(linn_id, this.collection_service_note);
      }else{
        this.snackBar.open('Uzupełnij pole notatki przed podjęciem działania', 'OK', {
          duration: 2000,
        });
      }
    }
  }

  serviceStatusConfirm(linn_id){
    this.update_api = this.api.update_order_service_status(this.api_token, linn_id);
    this.update_api.subscribe(data => {
      this.snackBar.open('Pomyślnie zamknięto serwis', 'OK', {
        duration: 2000,
      });
      this.service_note = '';
      this.collection_note = '';
      this.collection_service_note = '';
      this.getOrderServices();
    }, err => {
      console.log(err);
    });
  }

  backStatus() {
    const dialogRef = this.dialog.open(DialogBackOrderStatus, {
      data: {
        linn_id: this.order.linn_id,
        status: this.order.trackpack_status
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getOrderInfo(this.tracking_id, 0);
    });
  }

  changeDate(type){
    let date = '';
    if(type == 1){
      date = moment(this.edit_dates.inprogress, 'DD/MM/YYYY').format();
    }else if(type == 2){
      date = moment(this.edit_dates.readytosend, 'DD/MM/YYYY').format();
    }else if(type == 3){
      date = moment(this.edit_dates.sent, 'DD/MM/YYYY').format();
    }else if(type == 4){
      date = moment(this.edit_dates.delivery, 'DD/MM/YYYY').format();
    }
    this.update_api = this.api.update_trackpack_date(this.api_token, this.order.linn_id, type, date);
    this.update_api.subscribe(data => {
      this.getOrderInfo(this.tracking_id, 0);
      this.snackBar.open('Date changed successfully', 'OK', {
        duration: 2000,
      });
    }, err => {

    });
  }

  showAddressHistory() {
    const dialogRef =  this.dialog.open(AddressHistory, {
      data: this.order.address_history
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}

@Component({
  selector: 'address-history',
  template: `
    <h1 mat-dialog-title>Address History</h1>
    <mat-table [dataSource]="history" class="mat-elevation-z8">
      <ng-container matColumnDef="client">
          <mat-header-cell *matHeaderCellDef>Name&Surname</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>{{element.client}}</span>
          </mat-cell>
      </ng-container>
      <ng-container matColumnDef="client_email">
        <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{element.client_email}}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="client_phone">
        <mat-header-cell *matHeaderCellDef>Phone</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{element.client_phone}}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="delivery_country">
        <mat-header-cell *matHeaderCellDef>Country</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{element.delivery_country}}</span><br>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="delivery_city">
        <mat-header-cell *matHeaderCellDef>City</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{element.delivery_city}}</span><br>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="delivery_address_line_1">
        <mat-header-cell *matHeaderCellDef>Address</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{element.delivery_address_line_1}}</span><br>
          <span>{{element.delivery_address_line_2}}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="delivery_postcode">
        <mat-header-cell *matHeaderCellDef>Postcode</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{element.delivery_postcode}}</span><br>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="updated_at">
        <mat-header-cell *matHeaderCellDef>Updated at</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{element.updated_at}}</span><br>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  `,
  styleUrls: ['./details.component.scss']
})

export class AddressHistory {
  history:any = [];
  displayedColumns: string[] = ['client','client_email','client_phone','delivery_address_line_1','delivery_city','delivery_country','delivery_postcode','updated_at'];
  constructor(public dialogRef: MatDialogRef<AddressHistory>, @Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar, public api: ApiCommunicationService) {
    console.log(data);
    this.history = data;
    for(let i in this.history) {
      let date = Date.parse(this.history[i].updated_at)
      console.log(date);
      let date2 = moment(date).format('YYYY/MM/DD HH:mm');
      console.log(date2);
      this.history[i].updated_at = moment(Date.parse(this.history[i].updated_at)).format('YYYY/MM/DD HH:mm');
    }
    this.history.reverse();
  }
}

@Component({
  selector: 'details-dialog-status',
  template: `
    <h1 mat-dialog-title>Trackpack status</h1>

      <div *ngIf="loading == 1">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
      <div mat-dialog-content *ngIf="status == 0 && loading != 1">
        <p style="margin: 0;">Current status: <b>Ordered</b>.</p>
        <p style="margin: 0;">Next step: <b>In progress</b>.</p>
        <p style="font-size: 1.25reg;"><b><i>Do you want change a status?</i></b> <br /> <i>The customer will receive an email notification.</i></p>
        <mat-slide-toggle (click)="sendEmail('status')" checked>Send email to customer</mat-slide-toggle>
      </div>
      <div mat-dialog-actions *ngIf="status == 0 && loading != 1">
        <button mat-button cdkFocusInitial (click)="dialogClose()">Cancel</button>
        <button mat-raised-button color="primary" (click)="in_progress_step()">Yes, change status</button>
      </div>

      <div mat-dialog-content *ngIf="status == 1 && loading != 1">
        <p style="margin: 0;">Current status: <b>In progress</b>.</p>
        <p style="margin: 0;">Next step: <b>Ready to send</b>.</p>
        <p style="margin: 20px 0 0 0;">
          <mat-form-field appearance="fill">
            <mat-label>Loading date</mat-label>
            <input matInput [matDatepicker]="loading_date_picker" [(ngModel)]="loading_date" [min]="today_date" disabled>
            <mat-datepicker-toggle matSuffix [for]="loading_date_picker"></mat-datepicker-toggle>
            <mat-datepicker #loading_date_picker disabled="false"></mat-datepicker>
          </mat-form-field>
        </p>
        <p style="margin: 20px 0 0 0;" *ngIf="loading_date != null">
          <mat-form-field appearance="fill">
            <mat-label>Expected date (from)</mat-label>
            <input matInput [matDatepicker]="date_from_picker" [(ngModel)]="date_from" [min]="loading_date" disabled>
            <mat-datepicker-toggle matSuffix [for]="date_from_picker"></mat-datepicker-toggle>
            <mat-datepicker #date_from_picker disabled="false"></mat-datepicker>
          </mat-form-field>
        </p>
        <p style="margin: 0;" *ngIf="date_from != null">
          <mat-form-field appearance="fill">
            <mat-label>Expected date (to)</mat-label>
            <input matInput [matDatepicker]="date_to_picker" [(ngModel)]="date_to" [min]="date_from" disabled>
            <mat-datepicker-toggle matSuffix [for]="date_to_picker"></mat-datepicker-toggle>
            <mat-datepicker #date_to_picker disabled="false"></mat-datepicker>
          </mat-form-field>
        </p>
        <p style="margin: 0;" *ngIf="date_to != null">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Driver</mat-label>
            <mat-select [(ngModel)]="driver">
              <div *ngFor="let driver of drivers">
                <mat-option [value]="driver.id" *ngIf="driver.active == 1">{{driver.name}} {{driver.surname}}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </p>
        <p style="margin: 0;" *ngIf="driver != null">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Loading place</mat-label>
            <mat-select [(ngModel)]="loading_place">
              <mat-option value="0">Częstochowa</mat-option>
              <mat-option value="1">Shefford</mat-option>
            </mat-select>
          </mat-form-field>
        </p>
        <p style="margin: 0;" *ngIf="loading_place != null">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Shipping cost</mat-label>
            <input matInput [(ngModel)]="shipping_cost" placeholder="Shipping cost" type="number">
          </mat-form-field>
        </p>
        <p style="color: red;" *ngIf="complete_field_status == 1">Please complete all fields</p>
        <mat-slide-toggle (click)="sendEmail('status')" checked>Send email to customer</mat-slide-toggle>
      </div>
      <div mat-dialog-actions *ngIf="status == 1 && loading != 1">
        <button mat-button cdkFocusInitial (click)="dialogClose()">Cancel</button>
        <button mat-raised-button color="primary" (click)="ready_to_send_step()">Update status</button>
      </div>

      <div mat-dialog-content *ngIf="(status == 2 || status == 3 ) && loading != 1">
        <p style="margin: 0;" *ngIf="(status == 2)">Current status: <b>Ready to send</b>.</p>
        <p style="margin: 0;" *ngIf="(status == 3)">Current status: <b>Sent</b>.</p>
        <p style="margin: 0;">You can update expected dates and driver.</p>
        <p style="margin: 20px 0 0 0;">
          <mat-form-field appearance="fill">
            <mat-label>Loading date</mat-label>
            <input matInput [matDatepicker]="edit_loading_date_picker" [(ngModel)]="edit_loading_date" [min]="today_date" disabled>
            <mat-datepicker-toggle matSuffix [for]="edit_loading_date_picker"></mat-datepicker-toggle>
            <mat-datepicker #edit_loading_date_picker disabled="false"></mat-datepicker>
          </mat-form-field>
        </p>
        <p style="margin: 20px 0 0 0;">
          <mat-form-field appearance="fill" *ngIf="edit_loading_date != null">
            <mat-label>Expected date (from)</mat-label>
            <input matInput [matDatepicker]="edit_date_from_picker" [(ngModel)]="edit_date_from" [min]="loading_date" disabled>
            <mat-datepicker-toggle matSuffix [for]="edit_date_from_picker"></mat-datepicker-toggle>
            <mat-datepicker #edit_date_from_picker disabled="false"></mat-datepicker>
          </mat-form-field>
        </p>
        <p style="margin: 0;" *ngIf="edit_date_from != null">
          <mat-form-field appearance="fill">
            <mat-label>Expected date (to)</mat-label>
            <input matInput [matDatepicker]="edit_date_to_picker" [(ngModel)]="edit_date_to" [min]="date_from" disabled>
            <mat-datepicker-toggle matSuffix [for]="edit_date_to_picker"></mat-datepicker-toggle>
            <mat-datepicker #edit_date_to_picker disabled="false"></mat-datepicker>
          </mat-form-field>
        </p>
        <p style="margin: 0;" *ngIf="edit_date_to != null">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Driver</mat-label>
            <mat-select [(ngModel)]="edit_driver">
              <mat-option *ngFor="let driver of drivers" [value]="driver.id">{{driver.name}} {{driver.surname}}</mat-option>
            </mat-select>
          </mat-form-field>
        </p>
        <p style="color: red;" *ngIf="complete_field_status == 1">Please complete all fields</p>
        <mat-slide-toggle (click)="sendEmail('status')" checked>Send email to customer</mat-slide-toggle>
      </div>
      <div mat-dialog-actions *ngIf="(status == 2 || status == 3 ) && loading != 1">
        <button mat-button cdkFocusInitial (click)="dialogClose()">Cancel</button>
        <button mat-raised-button color="primary" (click)="update_ready_to_send_step()">Update status</button>
      </div>
  `,
  styleUrls: ['./details.component.scss']
})

  // <div style="margin-top: 10px;">
  //   <mat-form-field appearance="fill">
  //     <mat-label>Input disabled</mat-label>
  //     <input matInput [matDatepicker]="dp3" disabled>
  //     <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
  //     <mat-datepicker #dp3 disabled="false"></mat-datepicker>
  //   </mat-form-field>
  // </div>
export class DetailsDialogStatus {

  orders_api: any;
  status: any;

  loading_date: any;
  date_from: any;
  date_to: any;
  edit_loading_date: any;
  edit_date_from: any;
  edit_date_to: any;
  driver: any;
  edit_driver: any;
  loading: any = 0;
  complete_field_status: any = 0;
  today_date = moment().format('YYYY-MM-DD');

  api_token: any;

  drivers_api: any;
  drivers: any;

  loading_place: any;
  shipping_cost: any;

  send_email: any = {
    status: true
  };

  constructor(public dialogRef: MatDialogRef<DetailsDialogStatus>, @Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar, public api: ApiCommunicationService) {
    this.api_token = localStorage.getItem('api_token');
    this.status = this.data.status;
    console.log('DATA:');
    console.log(data);
    if(this.status == 2 || this.status == 3){
      if(this.data.loading_date.date == null){
        console.log('1');
        this.edit_loading_date = '';
      }else{
        console.log('2');
        this.edit_loading_date = moment(this.data.loading_date.date, 'DD/MM/YYYY').format();
      }
      this.edit_date_from = moment(this.data.expected_delivery_date_from.date, 'DD/MM/YYYY').format();
      this.edit_date_to = moment(this.data.expected_delivery_date_to.date, 'DD/MM/YYYY').format();
      this.edit_driver = this.data.driver;
    }
    this.getDrivers();
  }

  sendEmail(email){
    if(email == 'status'){
      this.send_email.status = !this.send_email.status;
    }
  }

  dialogClose(){
    this.dialogRef.close();
  }

  getDrivers(){
  console.log(this.api_token);
    this.drivers_api = this.api.get_drivers(this.api_token, 3);
    this.drivers_api.subscribe(data => {
      this.drivers = data.data;
      console.log(this.drivers);
    }, err => {
      console.log(err);
    });
  }

  in_progress_step(){
    //linn_id
    this.loading = 1;
    this.orders_api = this.api.in_progress_step(this.api_token, this.data.linn_id, this.send_email.status);
    this.orders_api.subscribe(data => {
      this.dialogRef.close();
      this.snackBar.open('Status changed successfully', 'OK', {
        duration: 2000,
      });
      this.loading = 0;
    }, err => {
      console.log(err);
      this.loading = 0;
    });
  }

  ready_to_send_step(){
    // linn_id, driver_id, expected_delivery_date_from, expected_delivery_date_to
    let loading_date = moment(this.loading_date).format('YYYY-MM-DD');
    let date_from = moment(this.date_from).format('YYYY-MM-DD');
    let date_to = moment(this.date_to).format('YYYY-MM-DD');
    this.loading = 1;
    if(this.driver != null && date_from != null && date_to != null){
      this.complete_field_status = 0;
      this.orders_api = this.api.ready_to_send_step(this.api_token, this.data.linn_id, this.driver, loading_date, date_from, date_to, this.send_email.status, this.loading_place, this.shipping_cost);
      this.orders_api.subscribe(data => {
        this.dialogRef.close();
        this.snackBar.open('Status changed successfully', 'OK', {
          duration: 2000,
        });
        this.loading = 0;
      }, err => {
        console.log(err);
        this.loading = 0;
      });
    }else{
      this.loading = 0;
      this.complete_field_status = 1;
    }
  }

  update_ready_to_send_step(){
    let loading_date = moment(this.edit_loading_date).format('YYYY-MM-DD');
    let date_from = moment(this.edit_date_from).format('YYYY-MM-DD');
    let date_to = moment(this.edit_date_to).format('YYYY-MM-DD');
    this.loading = 1;
    if(this.edit_driver != null && date_from != null && date_to != null){
      this.complete_field_status = 0;
      this.orders_api = this.api.update_ready_to_send_step(this.api_token, this.data.linn_id, this.edit_driver, loading_date, date_from, date_to, this.send_email.status);
      this.orders_api.subscribe(data => {
        this.dialogRef.close();
        this.snackBar.open('Status changed successfully', 'OK', {
          duration: 2000,
        });
        this.loading = 0;
      }, err => {
        console.log(err);
        this.loading = 0;
      });
    }else{
      this.loading = 0;
      this.complete_field_status = 1;
    }
  }

  // send_step(){
  //   //qr_code
  // }
  //
  // delivered_step(){
  //   //qr_code, signature, first_photo, second_photo, longitude, latitude
  // }

}

@Component({
  selector: 'dialog-back-order-status',
  template: `
    <div *ngIf="undo_step == 0">
      <h1 mat-dialog-title>WARNING!</h1>
      <div mat-dialog-content>
        <p style="margin: 0;">You are trying to undo the delivery status of this order. Is this a reasonable decision?</p>
        <p></p>
      </div>
      <div mat-dialog-actions>
        <button mat-button cdkFocusInitial (click)="dialogClose()">Cancel</button>
        <button mat-raised-button color="warn"(click)="changeStep(1)">Yes, undo status</button>
      </div>
    </div>

    <div *ngIf="undo_step == 1">
      <h1 mat-dialog-title>Undo step</h1>
      <div mat-dialog-content>
        <p style="margin: 0;">
          <mat-form-field appearance="fill" style="width: 400px;">
            <mat-label>Reason</mat-label>
            <textarea matInput [(ngModel)]="reason"></textarea>
          </mat-form-field><br />
          <mat-form-field *ngIf="data.status == 3">
            <mat-label>Undo to status</mat-label>
            <mat-select [(ngModel)]="status">
              <mat-option value="2">Ready to send</mat-option>
              <mat-option value="1">In progress</mat-option>
            </mat-select>
          </mat-form-field>
          <span *ngIf="data.status == 2">Undo to status: <b>In Progress</b></span>
        </p>
        <p></p>
      </div>
      <div mat-dialog-actions>
        <button mat-button cdkFocusInitial (click)="dialogClose()">Cancel</button>
        <button mat-raised-button color="primary"(click)="undoStatus()">Confirm</button>
      </div>
    </div>

    <div *ngIf="undo_step == 2">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  `,
})
export class DialogBackOrderStatus {

  undo_step: any = 0;
  reason: any = '';
  status: any;
  api_token: any;
  order_api: any;

  constructor(public dialogRef: MatDialogRef<DialogBackOrderStatus>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data, private snackBar: MatSnackBar){}

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    if(this.data.status == 3){
      this.status = '2';
    }else{
      this.status = '1';
    }
  }


  undoStatus(){
   this.undo_step = 2;
   this.order_api = this.api.undo_status(this.api_token, this.status, this.reason, this.data.linn_id);
   this.order_api.subscribe(data => {
     console.log(data);
     this.dialogRef.close();
     this.snackBar.open('Order status undo successfully', 'OK', {
       duration: 2000,
     });
   }, err => {
     console.log(err);
   });
  }

  dialogClose(){
    this.dialogRef.close();
  }

  changeStep(step){
    this.undo_step = step;
  }

}


@Component({
  selector: 'dialog-payment-method',
  template: `
    <h1 mat-dialog-title>Select the payment method for save changes</h1>
    <div mat-dialog-content style="height: 50px">
        <mat-list-item>
          <span class="colorBlue">Current payment method: </span>
          <span *ngIf="paymentMethod == 'card'"  class="copyValue">CARD PAYMENT</span>
          <span *ngIf="paymentMethod == 'cash'"  class="copyValue">CASH ON DELIVERY</span>
          <span *ngIf="paymentMethod == 'test'"  class="copyValue">TEST DRIVE</span>
          <span *ngIf="paymentMethod == 'rent'"  class="copyValue">RENTAL</span>
        </mat-list-item>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="primary" cdkFocusInitial (click)="selectPaymentMethod('card')">CARD PAYMENT</button>
      <button mat-raised-button color="basic" cdkFocusInitial (click)="selectPaymentMethod('cash')">CASH ON DELIVERY</button>
      <button mat-raised-button color="accent" cdkFocusInitial (click)="selectPaymentMethod('test')">TEST DRIVE</button>
    </div>
  `,
  styleUrls: ['./details.component.scss']
})


export class DialogPaymentMethod {

  linn_id: any;
  api_token: any;
  orders_api: any;
  reason: any = '';
  paymentMethod: any;

  constructor(public dialogRef: MatDialogRef<DialogPaymentMethod>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data, private snackBar: MatSnackBar){}

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.linn_id = this.data.linn_id;
    this.paymentMethod = this.data.paymentMethod;
  }

  selectPaymentMethod(payment_met) {
      console.log(payment_met)
      this.dialogRef.close(payment_met);
  }
};

@Component({
  selector: 'dialog-report-service',
  template: `
    <h1 mat-dialog-title>Enter the reason for the service:</h1>
    <div mat-dialog-content>
      <mat-form-field appearance="fill" style="width: 400px;">
        <mat-label>Reason</mat-label>
        <textarea matInput [(ngModel)]="reason"></textarea>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button cdkFocusInitial (click)="dialogClose()">Cancel</button>
      <button mat-raised-button color="primary" (click)="submitService()">Confirm</button>
    </div>
  `,
})

export class DialogReportService {

  linn_id: any;
  api_token: any;
  orders_api: any;
  reason: any = '';

  constructor(public dialogRef: MatDialogRef<DialogReportService>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data, private snackBar: MatSnackBar){}

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.linn_id = this.data.linn_id;
  }

  submitService(){
    this.orders_api = this.api.add_order_service(this.api_token, this.linn_id, this.reason, 4);
    this.orders_api.subscribe(data => {
      if(data.status == true){
        this.snackBar.open('Successfully added service to the order.', 'OK', {
          duration: 2000,
        });
        this.dialogClose();
        console.log('OK');
      }
    }, err => {
      console.log(err);
      this.snackBar.open('Unknown error, please contact with technical support', 'OK', {
        duration: 2000,
      });
    });
  }

  dialogClose(){
    this.dialogRef.close();
  }

}




@Component({
  selector: 'dialog-report-collection',
  template: `
    <h1 mat-dialog-title>Enter the reason for the collection:</h1>
    <div mat-dialog-content>
      <mat-form-field appearance="fill" style="width: 400px;">
        <mat-label>Reason</mat-label>
        <textarea matInput [(ngModel)]="reason"></textarea>
      </mat-form-field>
      <div>
        <mat-radio-group aria-label="Collection type" [(ngModel)]="collection_type">
          <mat-radio-button value="3">Collection</mat-radio-button>
          <mat-radio-button value="5" style="margin-left: 10px;">Collection service</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-button cdkFocusInitial (click)="dialogClose()">Cancel</button>
      <button mat-raised-button color="primary" (click)="submitService()">Confirm</button>
    </div>
  `,
})
export class DialogReportCollection {

  linn_id: any;
  api_token: any;
  orders_api: any;
  reason: any = '';
  collection_type: any = '3';

  constructor(public dialogRef: MatDialogRef<DialogReportCollection>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data, private snackBar: MatSnackBar){}

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.linn_id = this.data.linn_id;
  }

  submitService(){
    this.orders_api = this.api.add_order_service(this.api_token, this.linn_id, this.reason, this.collection_type);
    this.orders_api.subscribe(data => {
      if(data.status == true){
        this.snackBar.open('Successfully added service to the order.', 'OK', {
          duration: 2000,
        });
        this.dialogClose();
        console.log('OK');
      }
    }, err => {
      console.log(err);
      this.snackBar.open('Unknown error, please contact with technical support', 'OK', {
        duration: 2000,
      });
    });
  }

  dialogClose(){
    this.dialogRef.close();
  }

}



@Component({
  selector: 'dialog-custom-mail',
  template: `
    <h1 mat-dialog-title>Send custom email to customer:</h1>
    <div mat-dialog-content>
      <div>
        <mat-form-field appearance="fill" style="width: 400px;">
          <mat-label>Topic</mat-label>
          <input matInput [(ngModel)]="topic" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" style="width: 400px;">
          <mat-label>Message</mat-label>
          <textarea matInput [(ngModel)]="message"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-button cdkFocusInitial (click)="dialogClose()">Cancel</button>
      <button mat-raised-button color="primary" (click)="submitMessage()">Send email</button>
    </div>
  `,
})
export class DialogCustomEmail {

  email: any;
  api_token: any;
  orders_api: any;
  id: any;
  linn_id: any;
  topic: any;
  message: any;
  order_from: any;
  source: any;

  constructor(public dialogRef: MatDialogRef<DialogCustomEmail>, public api: ApiCommunicationService, @Inject(MAT_DIALOG_DATA) public data, private snackBar: MatSnackBar){}

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.id = this.data.id;
    this.email = this.data.email;
    this.linn_id = this.data.linn_id;
    this.topic = 'Order number #'+this.linn_id+' | ';
    this.order_from = this.data.order_from;
  }

  submitMessage(){
    this.orders_api = this.api.send_custom_mail(this.api_token, this.id, this.linn_id, this.email, this.topic, this.message, this.order_from, this.source);
    this.orders_api.subscribe(data => {
      if(data.status == true){
        this.snackBar.open('Custom email has been sent successfully.', 'OK', {
          duration: 2000,
        });
        this.dialogClose();
        console.log('OK');
      }
    }, err => {
      console.log(err);
      this.snackBar.open('Unknown error, please contact with technical support', 'OK', {
        duration: 2000,
      });
    });
  }

  dialogClose(){
    this.dialogRef.close();
  }

}
