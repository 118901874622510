import { Component, OnInit } from '@angular/core';
import { ApiCommunicationService } from '../api-communication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.scss']
})
export class TrackOrderComponent implements OnInit {

  tracking_id: any;
  order_api: any;
  no_exist: any = 0;

  constructor(public api: ApiCommunicationService, private router: Router) { }

  ngOnInit() {
    document.body.classList.add('bg-img');
  }

  search(){
    this.order_api = this.api.order_exist(this.tracking_id);
    this.order_api.subscribe(data => {
      console.log(data);
      if(data.status == true){
        document.body.classList.remove('bg-img');
        this.no_exist = 0;
        this.router.navigate(['/tracking'], { queryParams: { linn_id: this.tracking_id },  queryParamsHandling: "merge" });
      }else{
        this.no_exist = 1;
      }
    }, err => {

    });
  }

}
