import { Component, Output, EventEmitter, Inject, OnInit, ElementRef, ViewChild, Input} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApiCommunicationService } from '../api-communication.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  doc_type: any;
  order: any;
  orders_api: any;
  api_token: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public api: ApiCommunicationService) { }
  @ViewChild('documents', {static: false}) documents: ElementRef;

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    this.doc_type = this.data.type;
    this.order = this.data.order;
    console.log(this.order);
  }

  checkScooterModel(document_name) {
    console.log(this.order);
    console.log(document_name);
    if (document_name==='instructions-remote-controller') {
      let scooter_models = ['16_sil','16_red','16_bla','cri_bla','cri_whi','cri_sil'];
      if (scooter_models.indexOf(this.order.scooter_model.value) === -1) {
        console.log("Print instruction");
        return true;
      }
      console.log("No print instruction");
      return false;
    }
    return true;
  }

  printDocuments(){
    const printContent = this.documents.nativeElement;
    console.log(printContent);
    const WindowPrt = window.open('', '', 'left=0,top=0,width=718,height=1123,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML+
      `<style>
      .print_size{
        position: relative;
        webkit-print-color-adjust: exact;
        width: 718px;
        height: 1067px;
        background: #fff;
        position: relative;
      }

      .print_img_size {
        position: relative;
        webkit-print-color-adjust: exact;
        background: #fff;
      }
      
      .print_flex{
        display: flex;
        align-items: center;
        justify-content:
        space-between;
        width: 100%;
        padding-top: 10px;
      }

      .print_logo img{
        width: 350px;
      }

      .delivery_details{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 40px;
      }

      .detail_wrapper{
        width: calc(50% - 20px);
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      .detail_label{
        width: 45%;
        font-weight: bold;
        font-size: 16px;
      }

      .detail_value{
        width: 55%;
        padding-bottom: 5px;
        border-bottom: 1px solid black;
        min-height: 26px;
      }

      .delivery_receiverd .title{
        padding: 10px;
        background: #dcdcdc;
      }

      .list_wrap{
        line-height: 32px;
        width: calc(100% - 200px);
        border: 1px solid #dcdcdc;
      }

      .list_wrap .title{
        padding: 2px 10px;
        background: #dcdcdc;
      }

      .signatures{
        display: flex;
        justify-content: space-between;
        padding: 20px 10px;
        border: 1px solid #dcdcdc;
      }

      .signature_wrapper{
        width: calc(33.33% - 10px);
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      .signature_label{
        width: 35%;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
      }

      .signature_value{
        width: 65%;
        padding: 5px;
        border-bottom: 1px solid black;
        min-height: 26px;
        background: yellow;
      }

      .qr_code{
        text-align: center;
        margin-bottom: 20px;
      }

      .qr_code img{
        width: 160px;
        height: 160px;
        display: block;
      }

      .qr_achtung{
        position: absolute;
        right: 0;
        text-align: center;
        margin-bottom: 20px;
      }

      .print_small_title{
        width: 100%;
        font-size: 14px;
        padding-bottom: 5px;
        border-bottom: 1px solid black;
        font-family: Quicksand-Bold;
        margin: 10px 0;
      }

      .print_desc_text{
        font-size: 10px;
      }

      .print_seller{
        width: 50%;
      }

      .print_buyer{
        width: 50%;
        text-align: right;
      }

      .yellow_bg{
        background: yellow;
      }

      .vat_table td{
        color: black;
        border: 1px solid black;
        padding: 5px;
      }

      .check_wrap{
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }

      .check{
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: 1px solid black;
        margin-right: 5px;
      }

      .check_text{
        width: calc(100% - 25px);
      }

      b{
        font-family: Quicksand-Bold;
      }

      .print_red .print_details{
        border-left: 5px solid red;
      }

      .print_pink .print_details{
        border-left: 5px solid pink;
      }

      .print_green .print_details{
        border-left: 5px solid #2880cc;
      }

      .print_flex{
        display: flex;
        align-items: center;
        justify-content:
        space-between;
        width: 100%;
        padding-top: 5px;
      }
      .print_date_el{
        display: flex;
        justify-content: space-between;
      }
      .print_date_el_big{
        font-size: 18px;
        font-weight: bold;
      }
      .print_date_el_small{
        font-size: 16px;
        text-align: right;
        margin-left: 20px;
        padding-top: 2px;
      }
      .print_details{
        margin-top: 12px;
      }
      .print_title{
        width: 100%;
        padding: 5px 20px;
        background: #fff;
        border: 1px solid #1f2f45;
        border-left: none;
        font-size: 18px;
        color: #1f2f45;
        font-weight: bold;
      }
      .print_graybox{
        width: 100%;
        padding: 20px 20px;
        background: #f9f9f9;
      }
      .print_comments{
        padding: 10px 20px;
      }
      .print_when{
        padding: 10px 5px;
        background: #eaeaea;
        margin-top: 10px;
      }
      .print_price_wrapp{
        width: 100%;
        font-size: 18px;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        text-align: right;
      }
      .print_details{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
      .print_details .print_title, .print_details .print_graybox{
        width: 100%;
      }
      .print_price_val{
        font-size: 28px;
      }
      .details_price{
        border-left: none !important;
      }
      .bar_1{
        flex-grow: 1;
        height: 32px;
        color: #fff;
      }

      .bar_1_lunex{
        background: #f26c0f;
      }

      .bar_1_lauftech{
        background: #e8ce46;
      }

      .bar_2{
        width: 80px;
        height: 32px;
        color: #fff;
      }

      .bar_2_lunex{
        background: #f26c0f;
      }

      .bar_2_lauftech{
        background: #d2302d;
      }
       </style>`);
    WindowPrt.focus();
    setTimeout(()=>{
      WindowPrt.print();
      WindowPrt.document.close();
      WindowPrt.close();
      let doc_type = '';
      if(this.doc_type == 'documents'){
        doc_type = 'documents_status';
      }else if(this.doc_type == 'cmr'){
        doc_type = 'cmr_status';
      }else if(this.doc_type == 'order'){
        doc_type = 'order_status';
      }
      console.log(this.order.linn_id);
      console.log(doc_type);
      this.change_print_status(this.order.linn_id, doc_type);
    }, 1000);
  }

  isOnlyUKPart(){
    let scooter_models = [
      'Frost',
      //'Advena',
      'Itango',
      'ZT25',
     // 'Sagitta',
      'Itango HO',
      'Itango Classic'
    ];

    if(scooter_models.indexOf(this.order.scooter_model.name) == -1) {
      return true;
    } 
    return false;
  }

  change_print_status(linn_id, doc_type){
    this.orders_api = this.api.change_print_status(this.api_token, linn_id, doc_type);
    this.orders_api.subscribe(data => {
      console.log(data);
    }, err => {
      console.log(err);
    });
  }

}

@Component({
  selector: 'app-note',
  templateUrl: './docs/note.html',
  styleUrls: ['./docs/style.scss']
})

export class DocsNote {

  @Input() order: any;

  lang_str: any;
  lang: any = {
    title: {
      en: 'DELIVERY NOTE',
      de: 'LIEFERSCHEIN',
      es: 'NOTA DE ENTREGA',
      fr: 'BON DE LIVRAISON',
      it: 'BOLLA D\'ACCOMPAGNAMENTO'
    },
    to: {
      en: 'To',
      de: 'Zu',
      es: 'A',
      fr: 'À',
      it: 'Per'
    },
    tracking_id: {
      en: 'Tracking ID',
      de: 'Tracking ID',
      es: 'ID de rastreo',
      fr: 'ID de suivi',
      it: 'ID di monitoraggio'
    },
    address: {
      en: 'Address',
      de: 'Adresse',
      es: 'Habla a',
      fr: 'Adresse',
      it: 'Indirizzo'
    },
    date: {
      en: 'Date',
      de: 'Datum',
      es: 'Fecha',
      fr: 'Date',
      it: 'Data'
    },
    postcode: {
      en: 'Post Code',
      de: 'Postleitzahl',
      es: 'Código postal',
      fr: 'Code postal',
      it: 'Codice postale'
    },
    country: {
      en: 'Country',
      de: 'Land',
      es: 'País',
      fr: 'Pays',
      it: 'Nazione'
    },
    price: {
      en: 'Price',
      de: 'Preis',
      es: 'Precio',
      fr: 'Prix',
      it: 'Prezzo'
    },
    phone: {
      en: 'Phone',
      de: 'Telefon',
      es: 'Teléfono',
      fr: 'Téléphone',
      it: 'Telefono'
    },
    contactus: {
      en: 'Contact us',
      de: 'Kontaktiere uns',
      es: 'Contáctenos',
      fr: 'Nous contacter',
      it: 'Contattaci'
    },
    order: {
      en: 'Order',
      de: 'Bestellung',
      es: 'Orden',
      fr: 'Commande',
      it: 'Ordine'
    },
    list: {
      en: 'List',
      de: 'Aufführen',
      es: 'Lista',
      fr: 'Liste',
      it: 'Elenco'
    },
    goods: {
      en: 'Goods received in good order',
      de: 'Ware in gutem Zustand erhalten',
      es: 'Bienes recibidos en buen estado',
      fr: 'Marchandises reçues en bon état',
      it: 'Merce ricevuta in buon ordine'
    },
    name: {
      en: 'Name',
      de: 'Name',
      es: 'Nombre',
      fr: 'Nom',
      it: 'Nome'
    },
    signature: {
      en: 'Signature',
      de: 'Unterschrift',
      es: 'Firma',
      fr: 'Signature',
      it: 'Firma'
    }
  };

  ngOnInit(){
    if(this.order.delivery_country == 'United Kingdom'){
      this.lang_str = 'en';
    }else if(this.order.delivery_country == 'Germany' || this.order.delivery_country == 'Austria' || this.order.delivery_country == 'Liechtenstein' || this.order.delivery_country == 'Switzerland' || this.order.delivery_country == 'Luxembourg'){
      this.lang_str = 'de';
    }else if(this.order.delivery_country == 'Spain'){
      this.lang_str = 'es';
    }else if(this.order.delivery_country == 'France' || this.order.delivery_country == 'Belgium'){
      this.lang_str = 'fr';
    }else if(this.order.delivery_country == 'Italy'){
      this.lang_str = 'it';
    }else{
      this.lang_str = 'en';
    }
  }

}

@Component({
  selector: 'app-cmr',
  templateUrl: './docs/cmr.html',
  styleUrls: ['./docs/style.scss']
})

export class DocsCmr {

  @Input() order: any;
  @Input() index: any;
  lang_str: any;

  lang: any = {
    cmr_attention: {
      en: 'ATTENTION!! Check the goods in driver presence, take photos of all damages and make a note about it on reverse of this document!!!',
      de: 'AUFMERKSAMKEIT!! Überprüfen Sie die Ware in Anwesenheit des Fahrers, machen Sie Fotos von allen Schäden und vermerken Sie dies auf der Rückseite dieses Dokuments!!!',
      es: '¡¡ATENCIÓN!! ¡Compruebe la mercancía en presencia del conductor, tome fotos de todos los daños y anótelos en el reverso de este documento!',
      fr: 'ATTENTION!! Vérifiez la marchandise en présence du chauffeur, prenez des photos de tous les dommages et notez-les au verso de ce document !!!',
      it: 'ATTENZIONE!! Controllare la merce in presenza del conducente, fotografare tutti i danni e annotarla sul retro di questo documento!!!'
    }
  }

  ngOnInit(){
    console.log(this.order);
    if(this.order.delivery_country == 'United Kingdom'){
      this.lang_str = 'en';
    }else if(this.order.delivery_country == 'Germany'){
      this.lang_str = 'de';
    }else if(this.order.delivery_country == 'Spain'){
      this.lang_str = 'es';
    }else if(this.order.delivery_country == 'France'){
      this.lang_str = 'fr';
    }else if(this.order.delivery_country == 'Italy'){
      this.lang_str = 'it';
    }else{
      this.lang_str = 'en';
    }
  }

}

@Component({
  selector: 'app-order',
  templateUrl: './docs/order.html',
  styleUrls: ['./docs/style.scss']
})

export class DocsOrder {

  @Input() order: any;
  lang_str: any;

  lang: any = {
    order: {
      en: 'TRANSPORT ORDER',
      de: 'TRANSPORT ORDER',
      es: 'TRANSPORT ORDER',
      fr: 'TRANSPORT ORDER',
      it: 'TRANSPORT ORDER'
    },
    dropshipping: {
      en: 'DROPSHIPPING',
      de: 'DROPSHIPPING',
      es: 'DROPSHIPPING',
      fr: 'DROPSHIPPING',
      it: 'DROPSHIPPING'
    },
    to: {
      en: 'Order to',
      de: 'Bestellen zu',
      es: 'Ordenar a',
      fr: 'Ordre à',
      it: 'Per'
    },
    date: {
      en: 'Date',
      de: 'Datum',
      es: 'Fecha',
      fr: 'Date',
      it: 'Data'
    },
    order_number: {
      en: 'Order number',
      de: 'Bestellnummer',
      es: 'Número de orden',
      fr: 'Numéro de commande',
      it: 'Numero d\'ordine'
    },
    tracking_id: {
      en: 'Tracking ID',
      de: 'Tracking ID',
      es: 'ID de rastreo',
      fr: 'ID de suivi',
      it: 'ID di monitoraggio'
    },
    goods_description:{
      en: 'Goods description',
      de: 'Warenbeschreibung',
      es: 'Descripción de bienes',
      fr: 'Description des marchandises',
      it: 'Buona descrizione'
    },
    collect_from: {
      en: 'Collect from',
      de: 'Raccogliere da',
      es: 'Recoger de',
      fr: 'Recueillis depuis',
      it: 'Raccogliere da'
    },
    deliver_to_recipient: {
      en: 'Deliver to recipient',
      de: 'An Empfänger liefern',
      es: 'Entregar al destinatario',
      fr: 'Livrer au destinataire',
      it: 'Consegnare al destinatario'
    },
    when:{
      en: 'When',
      de: 'Wann',
      es: 'Cuando',
      fr: 'Quand',
      it: 'Quando'
    },
    up_to_three_weeks: {
      en: 'Up to 3 weeks',
      de: 'Bis zu 3 Wochen',
      es: 'Hasta 3 semanas',
      fr: 'Jusqu\'à 3 semaines',
      it: 'Fino a 3 settimane'
    },
    other_comments: {
      en: 'Other comments',
      de: 'Andere Kommentare',
      es: 'Otros comentarios',
      fr: 'Autres commentaires',
      it: 'Altri commenti'
    },
    value: {
      en: 'Value',
      de: 'Wert',
      es: 'Valor',
      fr: 'Valeur',
      it: 'Valore'
    }
  }

  ngOnInit(){
    if(this.order.delivery_country == 'United Kingdom'){
      this.lang_str = 'en';
    }else if(this.order.delivery_country == 'Germany'){
      this.lang_str = 'de';
    }else if(this.order.delivery_country == 'Spain'){
      this.lang_str = 'es';
    }else if(this.order.delivery_country == 'France'){
      this.lang_str = 'fr';
    }else if(this.order.delivery_country == 'Italy'){
      this.lang_str = 'it';
    }else{
      this.lang_str = 'en';
    }
  }

}


@Component({
  selector: 'app-sheet',
  templateUrl: './docs/sheet.html',
  styleUrls: ['./docs/style.scss']
})

export class DocsSheet {

  @Input() order: any;
  lang_str: any;
  company_name: any;

  lang: any = {
    certificate_content: null,
    first_title: {
      en: 'Mobility / recreation Scooter Delivery - Inspection Check Sheet & Sales Agreement',
      de: 'Mobilität / Erholung Scooter-Lieferung - Inspektionsprüfblatt und Kaufvertrag',
      es: 'Entrega de scooter de movilidad / recreación: hoja de verificación de inspección y acuerdo de venta',
      fr: 'Livraison de scooter de mobilité / de loisirs - Feuille de contrôle d\'inspection et contrat de vente',
      it: 'Consegna di scooter per mobilità / ricreazione - Foglio di controllo per l\'ispezione e contratto di vendita'
    },
    amount: {
      en: 'Amount',
      de: 'Summe',
      es: 'Cantidad',
      fr: 'Montante',
      it: 'Quantità'
    },
    seller: {
      en: 'SELLER',
      de: 'VERKÄUFER',
      es: 'VENDEDOR',
      fr: 'VENDEUR',
      it: 'VENDITORE'
    },
    buyer: {
      en: 'BUYER',
      de: 'KÄUFER',
      es: 'COMPRADOR',
      fr: 'ACHETEUR',
      it: 'ACQUIRENTE'
    },
    second_title:{
      en: 'SCOOTER SALES AGREEMENT',
      de: 'SCOOTER-VERKAUFSVEREINBARUNG',
      es: 'ACUERDO DE VENTA DE SCOOTER',
      fr: 'CONTRAT DE VENTE DE SCOOTER',
      it: 'CONTRATTO DI VENDITA DELLO SCOOTER'
    },
    certificate:{
      en: 'CERTIFICATE OF PURCHASE',
      de: 'KAUFBESCHEINIGUNG',
      es: 'CERTIFICADO DE COMPRA',
      fr: 'CERTIFICAT D\'ACHAT',
      it: 'CERTIFICATO DI ACQUISTO'
    },
    defects: {
      en: 'Defects',
      de: 'Mängel',
      es: 'Defectos',
      fr: 'Les défauts',
      it: 'Difetti'
    },
    scratch: {
      en: 'scratch',
      de: 'gekratzt',
      es: 'rasguño',
      fr: 'rayure',
      it: 'graffiare'
    },
    broken: {
      en: 'broken',
      de: 'gebrochen',
      es: 'roto',
      fr: 'cassé',
      it: 'rotto'
    },
    missing: {
      en: 'missing',
      de: 'fehlt',
      es: 'desaparecido',
      fr: 'manquant',
      it: 'mancante'
    },
    cracked: {
      en: 'cracked',
      de: 'genssen',
      es: 'agrietado',
      fr: 'fissuré',
      it: 'cracked'
    },
    okay: {
      en: 'If all OK marked OK signature on each picture.',
      de: 'Wenn alles in Ordnung ist, wird auf jedem Bild die Signatur OK markiert.',
      es: 'Si todo está bien, marca la firma OK en cada imagen.',
      fr: 'Si tout est OK marqué la signature OK sur chaque image.',
      it: 'Se tutto OK contrassegnato con OK firma su ogni immagine.'
    },
    comments: {
      en: 'COMMENTS',
      de: 'BEMERKUNGEN',
      es: 'COMENTARIOS',
      fr: 'COMMENTAIRES',
      it: 'COMMENTI'
    },
    comments_content: {
      en: 'Inspection completed by buyer',
      de: 'Inspektion durch Käufer abgeschlossen',
      es: 'Inspección completada por el comprador',
      fr: 'Inspection effectuée par l\'acheteur',
      it: 'Ispezione completata dall\'acquirente'
    },
    signature: {
      en: 'Signature',
      de: 'Unterschrift',
      es: 'Firma',
      fr: 'Signature',
      it: 'Firma'
    },
    buyer_id: {
      en: 'Buyer ID Number or Driving License Number',
      de: 'Ausweisnummer des Käufer',
      es: 'Número de identificación del comprador o número de licencia de conducir',
      fr: 'Numéro d\'identification de l\'acheteur ou numéro de permis de conduire',
      it: 'Numero ID acquirente o numero di patente di guida'
    },
    documentation_package: {
      en: 'Documentation Package ( tick )',
      de: 'Dokumentationspaket (Häkchen)',
      es: 'Paquete de documentación (tick)',
      fr: 'Paquet de documentation (cocher)',
      it: 'Pacchetto documentazione (barrare)'
    },
    confirmed: {
      en: 'to be confirmed by the above buyer',
      de: 'vom oben genannten Käufer zu bestätigen',
      es: 'A confirmar por el comprador anterior.(rodzaj męski)',
      fr: 'à confirmer par l\'acheteur ci-dessus',
      it: 'da confermare da parte dell\'acquirente di cui sopra'
    },
    invoice: {
      en: 'Invoice',
      de: 'Rechnung',
      es: 'Factura',
      fr: 'Facture d\'achat',
      it: 'Fattura'
    },
    manual: {
      en: 'Manual & Documentation',
      de: 'Handbuch & Dokumentation',
      es: 'Manual y documentación',
      fr: 'Manuel et documentation',
      it: 'Manuale e documentazione'
    },
    delivery_note: {
      en: 'Delivery note',
      de: 'Lieferschein',
      es: 'Nota de entrega',
      fr: 'Bon de livraison',
      it: 'Bolla d\'accompagnamento'
    },
    copy: {
      en: 'Copy of Check sheet & Copy of Sales Agreement',
      de: 'Kopie des Inspektionspufblatt und Kopie des Kaufvertrags',
      es: 'Copia de la hoja de cheques y copia del acuerdo de venta',
      fr: 'Copie de la feuille de chèque et copie du contrat de vente',
      it: 'Copia del foglio di controllo e copia del contratto di vendita'
    },
    content_str: {
      en: 'I hereby acknowledge receipt of the above vehicle in excellent condition and the following tools supplied with the scooter, for which I accept full responsibility and for which I shall make full payment if they are damaged or missing',
      de: 'Hiermit bestätige ich den Erhalt des oben genannten Fahrzeugs in perfektem Zustand und mit dem Scooter gelieferten Werkzeuge, für die ich die volle Verantwortung übernehme und für die ich die volle Zahlung leisten werde, wenn sie beschädigt sind oder fehlen',
      es: 'Por la presente, reconozco la recepción del vehículo anterior en excelentes condiciones y las siguientes herramientas suministradas con el scooter, por las cuales acepto toda la responsabilidad y por las cuales haré el pago completo si están dañadas o faltan',
      fr: 'J\'accuse par la présente réception du véhicule ci-dessus en excellent état et des outils suivants fournis avec le scooter, pour lesquels j\'accepte l\'entière responsabilité et pour lesquels j\'effectuerai le paiement intégral s\'ils sont endommagés ou manquants',
      it: 'Con la presente dichiaro di aver ricevuto il suddetto veicolo in ottime condizioni e i seguenti attrezzi forniti con lo scooter, per i quali mi assumo la piena responsabilità e per i quali effettuerò il pagamento completo se danneggiati o mancanti'
    },
    print_name: {
      en: 'Name',
      de: 'Name',
      es: 'Nombre',
      fr: 'Nom',
      it: 'Nome'
    },
    sign_date: {
      en: 'Sign & Date',
      de: 'Unterschrift & Datum',
      es: 'Firmar y fecha',
      fr: 'Signer et date',
      it: 'Firma e data'
    },
  }

  ngOnInit(){
    if(this.order.delivery_country == 'United Kingdom'){
      this.lang_str = 'en';
    }else if(this.order.delivery_country == 'Germany'){
      this.lang_str = 'de';
    }else if(this.order.delivery_country == 'Spain'){
      this.lang_str = 'es';
    }else if(this.order.delivery_country == 'France'){
      this.lang_str = 'fr';
    }else if(this.order.delivery_country == 'Italy'){
      this.lang_str = 'it';
    }else{
      this.lang_str = 'en';
    }
    this.company_name = this.order.collect_point.name;
    this.lang.certificate_content = {
      en: 'I am the undersigned buyer of the above vehicle. I have purchased it from the seller named ' + this.company_name + '  above for the amount of cash/PayPal/Bank Transfer also mentioned above (SOLD FOR).  This is the final price agreed. I have paid for this scooter in full and I am in receipt of this scooter and all the relevant documents to it. It is fully understood that this scooter is sold as seen. I the buyer agree that I have checked, inspected, tested and approved this scooter as suitable for sale. I hereby confirm technical and visual inspection of the scooter and any defects are described below:',
      de: 'Ich bin der unterzeichnete Käufer des oben genannten Fahrzeugs. Ich habe es vom Verkäufer ' + this.company_name + ' für den oben genannten Betrag Summe an Bargeld / PayPal / Banküberweisung gekauft. Dies ist der vereinbarte Endpreis. Ich habe diesen Scooter vollständig bezahlt und erhalte diesen Scooter und alle dazugehörigen Unterlagen. Es versteht sich von selbst, dass dieser Scooter wie gesehen verkauft wird. Ich, der Käufer, bin damit einverstanden, dass ich diesen Scooter als zum Verkauf geeignet kontrolliert geprüft, getestet und genehmigt habe. Ich bestätige hiermit die technische und visuelle Inspektion des Scooters und alle Mängel werden nachfolgend beschrieben:',
      es: 'Soy el comprador abajo firmante del vehículo anterior. Lo he comprado al vendedor llamado ' + this.company_name + ' arriba por el monto de efectivo / PayPal / Transferencia bancaria también mencionado anteriormente (VENDIDO). Este es el precio final acordado. He pagado este scooter en su totalidad y recibo este scooter y todos los documentos pertinentes. Se entiende completamente que este scooter se vende como se ve. Yo, el comprador, acepto que he comprobado, inspeccionado, probado y aprobado este scooter como apto para la venta. Por la presente confirmo la inspección técnica y visual del scooter y cualquier defecto se describe a continuación:',
      fr: 'Je suis l\'acheteur soussigné du véhicule ci-dessus. Je l\'ai acheté au vendeur nommé ' + this.company_name + ' ci-dessus pour le montant en espèces / PayPal / virement bancaire également mentionné ci-dessus (VENDU POUR). C\'est le prix final convenu. J\'ai payé l\'intégralité de ce scooter et je suis en possession de ce scooter et de tous les documents pertinents. Il est bien entendu que ce scooter est vendu tel quel. Je l\'acheteur accepte que j\'ai vérifié, inspecté, testé et approuvé ce scooter comme apte à la vente. Je confirme par la présente l\'inspection technique et visuelle du scooter et tout défaut est décrit ci-dessous:',
      it: 'Sono il sottoscritto acquirente del suddetto veicolo. L\'ho acquistato dal venditore denominato ' + this.company_name + ' sopra per l\'importo in contanti / PayPal / Bonifico bancario anche menzionato sopra (VENDUTO PER). Questo è il prezzo finale concordato. Ho pagato per intero questo scooter e ho ricevuto questo scooter e tutti i relativi documenti. Resta perfettamente inteso che questo scooter viene venduto come visto. L\'acquirente accetta di aver controllato, ispezionato, testato e approvato questo scooter come idoneo alla vendita. Con la presente confermo l\'ispezione tecnica e visiva dello scooter e gli eventuali difetti sono descritti di seguito:'
    };
  }

}


@Component({
  selector: 'app-return',
  templateUrl: './docs/return.html',
  styleUrls: ['./docs/style.scss']
})
export class DocsReturn {

  @Input() order: any;
  lang_str: any;

  lang: any = {
    first_title: {
      en: 'ACKNOWLEDGE RECEIPT OF SCOOTER',
      de: 'Bestätigung des Erhalts des Scooters',
      es: 'RECONOCIMIENTO RECIBO DE SCOOTER',
      fr: 'RECONNAÎTRE LA RÉCEPTION DU SCOOTER',
      it: 'RICONOSCERE RICEVUTA DELLO SCOOTER'
    },
    second_title: {
      en: 'RETURN POLICY',
      de: 'Rücknahmebedingungen',
      es: 'POLITICA DE DEVOLUCION',
      fr: 'POLITIQUE DE RETOUR',
      it: 'POLITICA DI RITORNO'
    },
    first_desc: {
      en: 'This undersigned letter acknowledge the delivered receipt and vehicle described on the included invoice for the customer, which further acknowledges that all the received products by the customer are goods and without any defect after thorough inspection.',
      de: 'Dieses unterzeichnete Dokument bestätigt den Beleg und das in der beigefügten Kundenrechnung beschriebene Fahrzeug und bestätigt ferner, dass alle vom Kunden erhaltenen Produkte nach sorgfältiger Prüfung Waren ohne Mängel sind.',
      es: 'Esta carta firmada reconoce el recibo de entrega y el vehículo descrito en la factura incluida para el cliente, que además reconoce que todos los productos recibidos por el cliente son bienes y sin ningún defecto después de una inspección exhaustiva.',
      fr: 'Cette lettre soussignée reconnaît le bon de réception et le véhicule décrits sur la facture jointe pour le client, qui reconnaît en outre que tous les produits reçus par le client sont des marchandises et sans aucun défaut après inspection approfondie.',
      it: 'Questa lettera sottoscritta conferma la ricevuta di consegna e il veicolo descritto nella fattura inclusa per il cliente, che riconosce inoltre che tutti i prodotti ricevuti dal cliente sono merci e senza alcun difetto dopo un\'ispezione approfondita.'
    },
    undersigned: {
      en: 'I the undersigned',
      de: 'Ich, der Unterzeichnete',
      es: 'Yo el abajo firmante',
      fr: 'Je, soussigné',
      it: 'Io, il sottoscritto'
    },
    chassis: {
      en: 'Bearing Chassis No',
      de: 'VIN',
      es: 'VIN',
      fr: 'VIN',
      it: 'VIN'
    },
    colour_model: {
      en: 'Colour and Model',
      de: 'Modell und Farbe',
      es: 'Color y modelo',
      fr: 'Couleur et modèle',
      it: 'Colore e modello'
    },
    second_desc: {
      en: 'I shall be fully legally responsible for its maintenance, accident, also for its misuse of any kind. I have checked up the vehicle thoroughly and I am fully satisfied. The vehicle is bought on as is where is based. I further confirm that I have paid full & final payment to the owner against the purchase of above said vehicle. I shall be fully responsible for any kind of declaration problems in future.',
      de: 'Ich bin rechtlich voll verantwortlich für seine Wartung, Unfall auch für seinen Missbrauch jeglicher Art. Ich habe das Fahrzeug gründlich überprüft und bin voll zufrieden. Das Fahrzeug wurde an seinem derzeitigen Standort gekauft. Ich bestätige weiter, dass ich dem Eigentümer die vollständige und endgültige Zahlung für den Kauf des oben genannten Fahrzeugs geleistet habe. Ich bin verpflichtet, alle in der Zukunft entstehende Probleme zu melden.',
      es: 'Seré plenamente responsable legalmente de su mantenimiento, accidente, también de su mal uso de cualquier tipo. He revisado el vehículo a fondo y estoy completamente satisfecho. El vehículo se compra tal y como está. Confirmo además que he pagado el pago total y final al propietario contra la compra de dicho vehículo. Seré totalmente responsable de cualquier tipo de problema de declaración en el futuro.',
      fr: 'Je serai légalement responsable de son entretien, de son accident, ainsi que de sa mauvaise utilisation de quelque nature que ce soit. J\'ai vérifié minutieusement le véhicule et j\'en suis pleinement satisfait. Le véhicule est acheté tel quel là où il est basé. Je confirme en outre que j\'ai payé le paiement complet et final au propriétaire pour l\'achat dudit véhicule ci-dessus. Je serai entièrement responsable de tout type de problème de déclaration à l\'avenir.',
      it: 'Sarò pienamente legalmente responsabile della sua manutenzione, incidente, anche per il suo uso improprio di qualsiasi tipo. Ho controllato a fondo il veicolo e sono pienamente soddisfatto. Il veicolo viene acquistato com\'è dove si trova. Confermo inoltre di aver pagato il pagamento completo e finale al proprietario contro l\'acquisto del suddetto veicolo. In futuro sarò pienamente responsabile per qualsiasi tipo di problema di dichiarazione.'
    },
    third_desc: {
      en: 'I understand after accepting vehicle , can only be returned in some ‘VERY unusual circumstances’ (please note that does not guarantee that we can take your scooter back, it is up to the company discretion, because vehicle is delivered/collected in person ). If we agree to customer return, there will be two options:',
      de: 'Ich verstehe, dass das Fahrzeug nach der Annahme nur unter "SEHR ungewöhnlichen Umständen" zurückgegeben werden kann. Bitte beachten Sie, dass dies nicht garantiert, dass wir Ihren Scooter zurücknehmen werden. Es liegt im Willen des Unternehmens, da das Fahrzeug persönlich geliefert / abgeholt wird. Wenn wir der Warenrücknahme zustimmen, bestehen zwei Möglichkeiten:',
      es: 'Entiendo que después de aceptar el vehículo, solo se puede devolver en algunas `` circunstancias MUY inusuales (tenga en cuenta que no garantiza que podamos recuperar su scooter, queda a discreción de la empresa, porque el vehículo se entrega / recoge en persona ). Si aceptamos la devolución del cliente, habrá dos opciones:',
      fr: 'Je comprends qu\'après avoir accepté le véhicule, il ne peut être retourné que dans certaines `` circonstances TRÈS inhabituelles (veuillez noter que cela ne garantit pas que nous puissions reprendre votre scooter, c\'est à la discrétion de l\'entreprise, car le véhicule est livré / récupéré en personne ). Si nous acceptons le retour du client, il y aura deux options:',
      it: 'Capisco che dopo aver accettato il veicolo, può essere restituito solo in alcune `` circostanze MOLTO insolite (si prega di notare che non garantisce che possiamo riprendere il tuo scooter, è a discrezione dell\'azienda, perché il veicolo viene consegnato / ritirato di persona ). Se accettiamo il reso del cliente, ci saranno due opzioni:'
    },
    third_desc_first_li: {
      en: 'collect the scooter from customer, and therefore customer will be charged ',
      de: 'holt den Scooter vom Kunden ab. Daher wird dem Kunden eine Abholgebühr von ',
      es: 'recoger el scooter del cliente y, por lo tanto, se le cobrará al cliente',
      fr: 'récupérer le scooter auprès du client, et donc le client sera facturé',
      it: 'ritirare lo scooter dal cliente, e quindi al cliente verrà addebitato'
    },
    third_desc_first_li_second_part: {
      en: 'fee ( for UK Midlands & Wales only, or ',
      de: 'für UK Mittelland und Wales / ',
      es: 'tarifa (solo para Reino Unido Midlands & Wales, o ',
      fr: 'frais (pour le Royaume-Uni Midlands et Pays de Galles uniquement, ou ',
      it: 'tassa (solo per Regno Unito Midlands e Galles, o '
    },
    third_desc_first_li_third_part: {
      en: 'for Scotland and Europe) – cost of collection must be paid prior to collection to our bank account, we do not accept partial refunds, deduction from final refund or PayPal payments',
      de: 'für Schottland und Europa berechnet. Die Kosten für die Abholung müssen vor der Abholung auf unser Bankkonto überwiesen werden. Wir akzeptieren keine Teilerstattung, Abzüge von der endgültigen Rückerstattung oder PayPal-Zahlungen.',
      es: 'para Escocia y Europa): el costo de recolección debe pagarse antes de la recolección en nuestra cuenta bancaria, no aceptamos reembolsos parciales, deducción del reembolso final o pagos de PayPal',
      fr: 'pour l\'Écosse et l\'Europe) - les frais de collecte doivent être payés avant le retrait sur notre compte bancaire, nous n\'acceptons pas les remboursements partiels, la déduction du remboursement final ou les paiements PayPal',
      it: 'per Scozia ed Europa) - il costo del ritiro deve essere pagato prima del ritiro sul nostro conto bancario, non si accettano rimborsi parziali, detrazioni dal rimborso finale o pagamenti PayPal'
    },
    third_desc_second_li: {
      en: 'Customer can arrange return vehicle at his/her own end, but customer will be taking full responsibility for transport insurance and all logistic issues related regarding this return. The vehicle must be returned in the same condition as delivered. In case of any damages, signs of usage,',
      de: 'Der Kunde kann die Rückgabe des Fahrzeugs selbst organisieren. Es muss dem Kunde bewusst sein, dass er die volle Verantwortung für die Transportversicherung und die ganze Logistik übernimmt. Das Fahrzeug muss im gleichen Zustand wie geliefert zurückgegeben werden. Im Falle von Schäden oder Gebrauchsspuren behält sich ',
      es: 'El cliente puede organizar la devolución del vehículo por su cuenta, pero el cliente asumirá toda la responsabilidad del seguro de transporte y de todos los problemas logísticos relacionados con esta devolución. El vehículo debe devolverse en las mismas condiciones en que se entregó. En caso de daños, signos de uso,',
      fr: 'Le client peut organiser le retour du véhicule de son propre chef, mais le client assumera l\'entière responsabilité de l\'assurance transport et de tous les problèmes logistiques liés à ce retour. Le véhicule doit être retourné dans le même état que celui livré. En cas de dommages, signes d\'utilisation,',
      it: 'Il cliente può organizzare la restituzione del veicolo da solo, ma si assumerà la piena responsabilità per l\'assicurazione del trasporto e tutte le questioni logistiche relative a tale restituzione. Il veicolo deve essere restituito nelle stesse condizioni in cui è stato consegnato. In caso di danni, segni di utilizzo,'
    },
    third_desc_second_li_second_part: {
      en: 'reserve rights to refuse return or charge customer for any costs  related to above customer return.',
      de: 'das Recht vor, die Rücksendung abzulehnen oder dem Kunden die mit der oben genannten Rücknahme verbundenen Kosten in Rechnung zu stellen.',
      es: 'se reserva el derecho de rechazar la devolución o cobrarle al cliente cualquier costo relacionado con la devolución del cliente anterior.',
      fr: 'se réserve le droit de refuser le retour ou de facturer au client tous les frais liés au retour du client ci-dessus.',
      it: 'si riserva il diritto di rifiutare la restituzione o addebitare al cliente eventuali costi relativi alla restituzione del cliente di cui sopra.'
    },
  }

  ngOnInit(){
    if(this.order.delivery_country == 'United Kingdom'){
      this.lang_str = 'en';
    }else if(this.order.delivery_country == 'Germany'){
      this.lang_str = 'de';
    }else if(this.order.delivery_country == 'Spain'){
      this.lang_str = 'es';
    }else if(this.order.delivery_country == 'France'){
      this.lang_str = 'fr';
    }else if(this.order.delivery_country == 'Italy'){
      this.lang_str = 'it';
    }else{
      this.lang_str = 'en';
    }
  }

}


@Component({
  selector: 'app-warranty',
  templateUrl: './docs/warranty.html',
  styleUrls: ['./docs/style.scss']
})
export class DocsWarranty {

  @Input() order: any;
  lang_str: any;

  lang: any = {
    warranty: {
      en: 'WARRANTY',
      de: 'GARANTIE',
      es: 'GARANTÍA',
      fr: 'GARANTIE',
      it: 'GARANZIA'
    },
    desc_text: {
      en: 'One year limited warranty – for one year from the date of purchase,',
      de: 'Ein Jahr Garantie – innerhalb eines Jahres ab Kaufdatum von ursprünglichen Käufer wird',
      es: 'Garantía limitada de un año: durante un año a partir de la fecha de compra,',
      fr: 'Garantie limitée d\'un an - pendant un an à compter de la date d\'achat,',
      it: 'Garanzia limitata di un anno - per un anno dalla data di acquisto,'
    },
    desc_text2: {
      en: 'will repair or replace at our option to the original purchaser , free of charge , any of the following parts found defective in material and or workmanship upon examination by an authorised representative by ',
      de: 'die folgenden Teile nach unserer Wahl kostenlos repariert oder ersetzt, nachdem sie von einem Bevollmächtigten von überprüft',
      es: 'repararemos o reemplazaremos a nuestra opción al comprador original, sin cargo, cualquiera de las siguientes piezas que se encuentren defectuosas en el material o en la mano de obra después de que un representante autorizado las examine',
      fr: 'réparera ou remplacera, à notre discrétion, à l\'acheteur d\'origine, gratuitement, l\'une des pièces suivantes jugées défectueuses en matière de matériau et / ou de fabrication après examen par un représentant autorisé par',
      it: 'riparerà o sostituirà a nostra discrezione l\'acquirente originale, gratuitamente, una delle seguenti parti trovate difettose nel materiale e / o nella lavorazione previo esame da parte di un rappresentante autorizzato da parte di'
    },
    desc_text3: {
      en: 'Bearings , Rubber components, Bushings, Plastic components except body',
      de: 'Lager, Gummikomponenten, Buchsen, Kunststoffkomponenten außer Karosserie ',
      es: 'Cojinetes, componentes de caucho, bujes, componentes de plástico excepto cuerpo',
      fr: 'Roulements, composants en caoutchouc, coussinets, composants en plastique à l\'exception du corps',
      it: 'Cuscinetti, componenti in gomma, boccole, componenti in plastica eccetto il corpo'
    },
    desc_text4: {
      en: 'Also , electronic assemblies including',
      de: 'Auch elektronische Baugruppen einschließlich',
      es: 'Además, conjuntos electrónicos que incluyen',
      fr: 'En outre, les assemblages électroniques comprenant',
      it: 'Inoltre, assemblaggi elettronici inclusi'
    },
    desc_text5: {
      en: 'Electronic controllers , Harnesses , Chargers , Any other electrical subassembly',
      de: 'Elektronische Steuerungen, Verkabelung, Ladegeräte, sonstige elektrische Bauteile',
      es: 'Controladores electrónicos, arneses, cargadores, cualquier otro subconjunto eléctrico',
      fr: 'Contrôleurs électroniques, faisceaux, chargeurs, tout autre sous-ensemble électrique',
      it: 'Controller elettronici, cablaggi, caricabatterie, qualsiasi altro sottogruppo elettrico'
    },
    small_title1: {
      en: 'Six month warranty',
      de: 'Sechs Monate Garantie',
      es: 'Garantía de seis meses',
      fr: 'Garantie de six mois',
      it: 'Garanzia di sei mesi'
    },
    small_text1: {
      en: 'The battery is covered by a separate six-months warranty, provided by the battery manufacturer. The batteries are not warranted by ',
      de: 'Für die Batterie gilt eine separate Garantie von 6 Monaten, die vom Batteriehersteller bereitgestellt wird. Die Batterien werden nicht von der Garantie abgedeckt von',
      es: 'La batería está cubierta por una garantía separada de seis meses, proporcionada por el fabricante de la batería. Las baterías no están garantizadas por',
      fr: 'La batterie est couverte par une garantie distincte de six mois, fournie par le fabricant de la batterie. Les piles ne sont pas garanties par',
      it: 'La batteria è coperta da una garanzia separata di sei mesi, fornita dal produttore della batteria. Le batterie non sono garantite da'
    },
    small_title2: {
      en: 'Warranty exclusions',
      de: 'Ausschluss der Garantie',
      es: 'Exclusiones de garantía',
      fr: 'Exclusions de garantie',
      it: 'Esclusioni dalla garanzia'
    },
    small_text2: {
      en: 'This warranty does not extend to those items which may require replacement due to normal wear and tear.',
      de: 'Diese Garantie gilt nicht für Artikel, die aufgrund normaler Abnutzung möglicherweise ausgetauscht werden müssen.',
      es: 'Esta garantía no se extiende a aquellos artículos que puedan requerir reemplazo debido al desgaste normal.',
      fr: 'Cette garantie ne s\'étend pas aux articles qui peuvent nécessiter un remplacement en raison de l\'usure normale.',
      it: 'Questa garanzia non si estende a quegli articoli che potrebbero richiedere la sostituzione a causa della normale usura.'
    },
    li1: {
      en: 'ABS Plastic Shrouds',
      de: 'ABS-Kunststoffabdeckungen',
      es: 'Cubiertas de plástico ABS',
      fr: 'Housses en plastique ABS',
      it: 'Protezioni in plastica ABS'
    },
    li2: {
      en: 'Brake pads',
      de: 'Bremsklötze',
      es: 'Pastillas de freno',
      fr: 'Les plaquettes de frein',
      it: 'Pastiglie dei freni'
    },
    li3: {
      en: 'Motor brushes',
      de: 'Kohlebürsten',
      es: 'Escobillas de motor',
      fr: 'Brosses de moteur',
      it: 'Spazzole motore'
    },
    li4: {
      en: 'Tires and tubes',
      de: 'Reifen und Schläuche',
      es: 'Neumáticos y tubos',
      fr: 'Pneus et chambres à air',
      it: 'Pneumatici e camere d\'aria'
    },
    li5: {
      en: 'Upholstery and seating',
      de: 'Polsterung und Sitz',
      es: 'Tapicería y asientos',
      fr: 'Rembourrage et assise',
      it: 'Tappezzeria e sedute'
    },
    li6: {
      en: 'Fuses and Bulbs',
      de: 'Sicherungen und Lampen',
      es: 'Fusibles y bombillas',
      fr: 'Fusibles et ampoules',
      it: 'Fusibili e lampadine'
    },
    li7: {
      en: 'Repairs and/or modifications made to any part without specifics consent from ',
      de: 'Reparaturen und / oder Änderungen an Teilen ohne ausdrückliche Zustimmung von',
      es: 'Reparaciones y / o modificaciones realizadas a cualquier pieza sin el consentimiento específico de',
      fr: 'Les réparations et / ou modifications apportées à une pièce sans le consentement spécifique de',
      it: 'Riparazioni e / o modifiche apportate a qualsiasi parte senza specifico consenso da parte di'
    },
    li8: {
      en: 'Contamination',
      de: 'Kontamination',
      es: 'Contaminación',
      fr: 'Contamination',
      it: 'Contaminazione'
    },
    li9: {
      en: 'Abuse, misuse, accident or negligence',
      de: 'Missbrauch, Unfall oder Fahrlässigkeit',
      es: 'Abuso, mal uso, accidente o negligencia',
      fr: 'Abus, mauvaise utilisation, accident ou négligence',
      it: 'Abuso, uso improprio, incidente o negligenza'
    },
    li10: {
      en: 'Battery fluid spillage or leaking',
      de: 'Batterieflüssigkeitsausfluss',
      es: 'Derrame o fuga de líquido de la batería',
      fr: 'Déversement ou fuite de liquide de batterie',
      it: 'Fuoriuscita o perdita di liquido dalla batteria'
    },
    li11: {
      en: 'Commercial use, or use other than normal ',
      de: 'Kommerzielle Nutzung oder andere als normale Nutzung ',
      es: 'Uso comercial o uso diferente al normal ',
      fr: 'Utilisation commerciale ou utilisation autre que normale ',
      it: 'Uso commerciale o uso diverso dal normale '
    },
    li12: {
      en: 'Improper operation, Maintenance or storage',
      de: 'Unsachgemäßer Betrieb, Wartung oder Lagerung',
      es: 'Operación, mantenimiento o almacenamiento incorrectos',
      fr: 'Mauvais fonctionnement, entretien ou stockage',
      it: 'Funzionamento, manutenzione o conservazione impropri'
    },
    desc_text6: {
      en: 'Exclusions also include components with damage caused by',
      de: 'Ausgeschlossen sind auch Komponenten mit Schäden durch',
      es: 'Las exclusiones también incluyen componentes con daños causados ​​por',
      fr: 'Les exclusions incluent également les composants avec des dommages causés par',
      it: 'Sono esclusi anche i componenti con danni causati da'
    },
    desc_text7: {
      en: 'Note: Gradual deterioration in performance, because the battery has been left in a discharge state, left in cold conditions for an extended period of time, or worn out through heavy use is not covered. ',
      de: 'Hinweis: Eine allmähliche Verschlechterung der Leistung, da der Akku in einem Entladezustand belassen, über einen Zeitraum kalt gehalten oder durch starke Belastung abgenutzt wurde, ist nicht von der Garantie abgedeckt.',
      es: 'Nota: No se cubre el deterioro gradual del rendimiento debido a que la batería se haya dejado en estado de descarga, en condiciones frías durante un período prolongado de tiempo o desgastada por un uso intensivo.',
      fr: 'Remarque: la détérioration progressive des performances, car la batterie a été laissée dans un état de décharge, laissée dans des conditions froides pendant une période prolongée ou usée par une utilisation intensive n\'est pas couverte.',
      it: 'Nota: il deterioramento graduale delle prestazioni, poiché la batteria è stata lasciata scarica, lasciata al freddo per un lungo periodo di tempo o consumata a causa di un uso intenso, non è coperto.'
    },
    desc_text8: {
      en: 'Service checks and warranty service',
      de: 'Wartung und Garantieservice',
      es: 'Verificaciones de servicio y servicio de garantía',
      fr: 'Contrôles de service et service de garantie',
      it: 'Controlli di servizio e assistenza in garanzia'
    },
    desc_text9: {
      en: 'Warranty service must be performed by an authorised',
      de: 'Der Garantieservice muss von einem autorisierten Mitarbeiter von',
      es: 'El servicio de garantía debe ser realizado por un',
      fr: 'Le service de garantie doit être effectué par un',
      it: 'Il servizio di garanzia deve essere eseguito da un tecnico autorizzato'
    },
    desc_text10: {
      en: 'service. Do not return faulty parts to ',
      de: 'durchgeführt werden. Senden Sie keine fehlerhafte Teile ohne vorherige schriftliche Genehmigung an ',
      es: 'servicio en Dunstable. No devuelva las piezas defectuosas a',
      fr: 'service à Dunstable. Ne renvoyez pas les pièces défectueuses à',
      it: 'servizio a Dunstable. Non restituire parti difettose a'
    },
    desc_text11: {
      en: 'without prior written authorisation. All transportation costs and shipping damage incurred while submitting parts for repair or replacement are the responsibility of the purchaser. Please contact your authorised ',
      de: 'zurück. Der Kunde wird mit aller Transportkosten und Versandschäden belastet, die beim Einreichen der Teile zur Reparatur oder zum Austausch entstanden sind. Bitte wenden Sie sich an',
      es: 'sin autorización previa por escrito. Todos los costos de transporte y los daños de envío incurridos al enviar las piezas para reparación o reemplazo son responsabilidad del comprador. Por favor contacte a su autorizado',
      fr: 'sans autorisation écrite préalable. Tous les frais de transport et les dommages d\'expédition encourus lors de la soumission des pièces pour réparation ou remplacement sont à la charge de l\'acheteur. Veuillez contacter votre',
      it: 'senza previa autorizzazione scritta. Tutti i costi di trasporto e i danni di spedizione sostenuti durante l\'invio di parti per la riparazione o la sostituzione sono a carico dell\'acquirente. Si prega di contattare il proprio autorizzato'
    },
    desc_text12: {
      en: 'provider for information on the current cost associated with a service visit.',
      de: 'um sich über aktuellen Kosten des Services zu informieren.',
      es: 'proveedor para obtener información sobre el costo actual asociado con una visita de servicio.',
      fr: 'fournisseur pour obtenir des informations sur le coût actuel associé à une visite de service.',
      it: 'fornitore di informazioni sul costo corrente associato a una visita di servizio.'
    }
  }

  ngOnInit(){
    if(this.order.delivery_country == 'United Kingdom'){
      this.lang_str = 'en';
    }else if(this.order.delivery_country == 'Germany'){
      this.lang_str = 'de';
    }else if(this.order.delivery_country == 'Spain'){
      this.lang_str = 'es';
    }else if(this.order.delivery_country == 'France'){
      this.lang_str = 'fr';
    }else if(this.order.delivery_country == 'Italy'){
      this.lang_str = 'it';
    }else{
      this.lang_str = 'en';
    }
  }

}


@Component({
  selector: 'app-vat',
  templateUrl: './docs/vat.html',
  styleUrls: ['./docs/style.scss']
})
export class DocsVat {

  @Input() order: any;

  ngOnInit(){
  }

}

@Component({
  selector: 'app-dvla',
  templateUrl: './docs/dvla.html',
  styleUrls: ['./docs/style.scss']
})
export class DocsDvlaPack {

  @Input() order: any;

  ngOnInit(){
  }

}

@Component({
  selector: 'app-declaration',
  templateUrl: './docs/declaration.html',
  styleUrls: ['./docs/style.scss']
})
export class DocsDeclaration {

  @Input() order: any;

  ngOnInit(){
  }

}


@Component({
  selector: 'app-application',
  templateUrl: './docs/application.html',
  styleUrls: ['./docs/style.scss']
})
export class DocsApplication {

  @Input() order: any;

  ngOnInit(){
  }

}

@Component({
  selector: 'app-datasheet',
  templateUrl: './docs/datasheet.html',
  styleUrls: ['./docs/style.scss']
})
export class DocsDatasheet {

  @Input() order: any;

  ngOnInit(){
  }

}

@Component({
  selector: 'app-qrcode',
  templateUrl: './docs/qrcode.html',
  styleUrls: ['./docs/style.scss']
})
export class DocsQrcode {

  @Input() order: any;

  ngOnInit(){
  }

}

@Component({
  selector: 'app-thanks',
  templateUrl: './docs/thanks.html',
  styleUrls: ['./docs/style.scss']
})
export class DocsThanks {

  @Input() order: any;

  ngOnInit(){
  }

}

@Component({
  selector: 'app-lauftechdeclaration',
  templateUrl: './docs/lauftechdeclaration.html',
  styleUrls: ['./docs/style.scss']
})
export class DocsLauftechDeclaration {

  @Input() order: any;

  ngOnInit(){
  }

}
